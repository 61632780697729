import { Form, Col, Row } from "react-bootstrap";
import FormLabel from "../FormElements/FormLabel";
import FormText from "../FormElements/FormText";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setTreatmentGoals } from "../../redux/TreatmentGoals/TreatmentGoalsSlice";
import FormCheck from "../FormElements/FormCheck";

interface Props {
  validated: boolean;
}

function TreatmentGoals(props: Props) {
  const dispatch = useAppDispatch();
  const reduxTreatmentGoals = useAppSelector((state) => state.treatmentGoals);

  function handleChange(event: any) {
    const { name, value, checked } = event.target;

    const checkboxFields = [
      "painReduction",
      "increaseInRom",
      "increaseInStrength",
      "biomechanicalRestoration",
      "returnToNormalLiving",
      "expectedReturnToNormalLiving",
      "expectedReturnToHomemaking",
      "returnToPreaccidentActivities",
      "expectedReturnToPreaccidentActivities",
      "returnToModifiedActivities",
      "expectedReturnToModifiedActivities",
    ];
    const saveValue = checkboxFields.includes(name) ? checked : value;

    var otherPropUpdates = {};
    if (saveValue && checked) {
      switch (name) {
        case "returnToNormalLiving":
          otherPropUpdates = {
            expectedReturnToNormalLiving: false,
            expectedReturnToHomemaking: false,
          };
          break;
        case "expectedReturnToNormalLiving":
          otherPropUpdates = {
            returnToNormalLiving: false,
          };
          break;
        case "expectedReturnToHomemaking":
          otherPropUpdates = {
            returnToNormalLiving: false,
          };
          break;
        case "returnToPreaccidentActivities":
          otherPropUpdates = {
            expectedReturnToPreaccidentActivities: false,
          };
          break;
        case "expectedReturnToPreaccidentActivities":
          otherPropUpdates = {
            returnToPreaccidentActivities: false,
          };
          break;
        case "returnToModifiedActivities":
          otherPropUpdates = {
            expectedReturnToModifiedActivities: false,
          };
          break;
        case "expectedReturnToModifiedActivities":
          otherPropUpdates = {
            returnToModifiedActivities: false,
          };
          break;
        default:
          break;
      }
    }

    dispatch(
      setTreatmentGoals({
        ...reduxTreatmentGoals,
        [name]: saveValue,
        ...otherPropUpdates,
      })
    );
  }

  const isTreatmentGoalRequirementSatisfied = () =>
    reduxTreatmentGoals["painReduction"] ||
    reduxTreatmentGoals["increaseInRom"] ||
    reduxTreatmentGoals["increaseInStrength"] ||
    reduxTreatmentGoals["biomechanicalRestoration"] ||
    reduxTreatmentGoals.otherDescription !== "";

  const isTreatmentFunctionalGoalRequirementSatisfied = () =>
    reduxTreatmentGoals["returnToNormalLiving"] ||
    reduxTreatmentGoals["returnToPreaccidentActivities"] ||
    reduxTreatmentGoals["returnToModifiedActivities"] ||
    reduxTreatmentGoals["expectedReturnToNormalLiving"] ||
    reduxTreatmentGoals["expectedReturnToHomemaking"] ||
    reduxTreatmentGoals["expectedReturnToPreaccidentActivities"] ||
    reduxTreatmentGoals["expectedReturnToModifiedActivities"] ||
    reduxTreatmentGoals.otherFunctionalDescription !== "";

  return (
    <Form.Group id="formTreatmentGoals">
      <Row>
        <Col>
          <div className="formSectionHeader">
            <h6>Treatment Goals</h6>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormLabel
            testId="formTreatmentGoal-label"
            text="Identify the goal(s) in relation to the client’s impairment(s), symptoms, or pathology that the management plan seeks to achieve:"
          />
        </Col>
      </Row>
      <Row xs={1} sm={2} md={3} lg="auto">
        {[
          ["Pain Reduction", "PainReduction"],
          ["Increase in ROM", "IncreaseInRom"],
          ["Increase in Strength", "IncreaseInStrength"],
          ["Biomechanical Restoration", "BiomechanicalRestoration"],
        ].map((type, idx) => (
          <Col key={idx}>
            <FormCheck
              required={!isTreatmentGoalRequirementSatisfied()}
              key={idx}
              label={type[0]}
              testId={`form${type[1]}`}
              field={`${type[1].charAt(0).toLowerCase() + type[1].slice(1)}`}
              checked={
                type[0] === "Pain Reduction"
                  ? reduxTreatmentGoals["painReduction"]
                  : type[0] === "Increase in ROM"
                  ? reduxTreatmentGoals["increaseInRom"]
                  : type[0] === "Increase in Strength"
                  ? reduxTreatmentGoals["increaseInStrength"]
                  : type[0] === "Biomechanical Restoration"
                  ? reduxTreatmentGoals["biomechanicalRestoration"]
                  : false
              }
              onChange={handleChange}
            />
          </Col>
        ))}
      </Row>
      <Row>
        <Col>
          <FormText
            required={!isTreatmentGoalRequirementSatisfied()}
            type="text"
            testId="formOtherDescription"
            field="otherDescription"
            label="Other (specify)"
            feedbackLabel=""
            value={reduxTreatmentGoals.otherDescription}
            onChange={handleChange}
          />
        </Col>
      </Row>
      {props.validated && !isTreatmentGoalRequirementSatisfied() && (
        <Row>
          <Col data-testid="formTreatmentGoalsQ1Invalid" className="invalid">
            At least 1 treatment goal selection is required
          </Col>
        </Row>
      )}
      <Row className="mt-3">
        <Col>
          <FormLabel
            testId="formFunctionalGoal-label"
            text="Select the functional goal(s) that the management plan seeks to achieve:"
          />
        </Col>
      </Row>
      <Row className="mt-1">
        <Col className="maxWidth400">
          <FormCheck
            required={!isTreatmentFunctionalGoalRequirementSatisfied()}
            id="formReturnToNormalLiving"
            label="Return to activities of normal living"
            testId={`formReturnToNormalLiving`}
            field={`returnToNormalLiving`}
            checked={reduxTreatmentGoals["returnToNormalLiving"]}
            onChange={handleChange}
          />
        </Col>
        <Col className="maxWidth400">
          <FormCheck
            required={!isTreatmentFunctionalGoalRequirementSatisfied()}
            id="formExpectedReturnToNormalLiving"
            label="N/A - Expected return to activities date:"
            testId="formExpectedReturnToNormalLiving"
            field="expectedReturnToNormalLiving"
            checked={reduxTreatmentGoals["expectedReturnToNormalLiving"]}
            onChange={handleChange}
          />
        </Col>
        <Col className="maxWidth200">
          <FormText
            required={
              !isTreatmentFunctionalGoalRequirementSatisfied() ||
              reduxTreatmentGoals["expectedReturnToNormalLiving"]
            }
            type="text"
            testId="formExpectedReturnToNormalLivingDate"
            field="expectedReturnToNormalLivingDate"
            label=""
            value={reduxTreatmentGoals["expectedReturnToNormalLivingDate"]}
            onChange={handleChange}
            className="maxWidth200"
          />
        </Col>
      </Row>
      <Row className="mt-1">
        <Col className="maxWidth400">
          <div />
        </Col>
        <Col className="maxWidth400">
          <FormCheck
            required={!isTreatmentFunctionalGoalRequirementSatisfied()}
            id="formExpectedReturnToHomemaking"
            label="N/A - Expected return to homemaking date:"
            testId="formExpectedReturnToHomemaking"
            field="expectedReturnToHomemaking"
            checked={reduxTreatmentGoals["expectedReturnToHomemaking"]}
            onChange={handleChange}
          />
        </Col>
        <Col className="maxWidth200">
          <FormText
            required={
              !isTreatmentFunctionalGoalRequirementSatisfied() ||
              reduxTreatmentGoals["expectedReturnToHomemaking"]
            }
            type="text"
            testId={`formExpectedReturnToHomemakingDate`}
            field={`expectedReturnToHomemakingDate`}
            label=""
            value={reduxTreatmentGoals["expectedReturnToHomemakingDate"]}
            onChange={handleChange}
            className="maxWidth200"
          />
        </Col>
      </Row>
      <Row className="mt-1">
        <Col className="maxWidth400">
          <FormCheck
            required={!isTreatmentFunctionalGoalRequirementSatisfied()}
            label="Return to pre-accident work activities"
            testId={`formReturnToPreaccidentActivities`}
            field={`returnToPreaccidentActivities`}
            checked={reduxTreatmentGoals["returnToPreaccidentActivities"]}
            onChange={handleChange}
          />
        </Col>
        <Col className="maxWidth400">
          <FormCheck
            required={!isTreatmentFunctionalGoalRequirementSatisfied()}
            label="N/A - Expected return to work date:"
            testId={`formExpectedReturnToPreaccidentActivities`}
            field={`expectedReturnToPreaccidentActivities`}
            checked={
              reduxTreatmentGoals["expectedReturnToPreaccidentActivities"]
            }
            onChange={handleChange}
          />
        </Col>
        <Col className="maxWidth200">
          <FormText
            required={
              !isTreatmentFunctionalGoalRequirementSatisfied() ||
              reduxTreatmentGoals["expectedReturnToPreaccidentActivities"]
            }
            type="text"
            testId={`formExpectedReturnToPreaccidentActivitiesDate`}
            field={`expectedReturnToPreaccidentActivitiesDate`}
            label=""
            value={
              reduxTreatmentGoals["expectedReturnToPreaccidentActivitiesDate"]
            }
            onChange={handleChange}
            className="maxWidth200"
          />
        </Col>
      </Row>
      <Row className="mt-1">
        <Col className="maxWidth400">
          <FormCheck
            required={!isTreatmentFunctionalGoalRequirementSatisfied()}
            label="Return to modified work activities"
            testId={`formReturnToModifiedActivities`}
            field={`returnToModifiedActivities`}
            checked={reduxTreatmentGoals["returnToModifiedActivities"]}
            onChange={handleChange}
          />
        </Col>
        <Col className="maxWidth400">
          <FormCheck
            required={!isTreatmentFunctionalGoalRequirementSatisfied()}
            label="N/A - Expected return to modified work activities date: "
            testId={`formExpectedReturnToModifiedActivities`}
            field={`expectedReturnToModifiedActivities`}
            checked={reduxTreatmentGoals["expectedReturnToModifiedActivities"]}
            onChange={handleChange}
          />
        </Col>
        <Col className="maxWidth200">
          <FormText
            required={
              !isTreatmentFunctionalGoalRequirementSatisfied() ||
              reduxTreatmentGoals["expectedReturnToModifiedActivities"]
            }
            type="text"
            testId={`formExpectedReturnToModifiedActivitiesDate`}
            field={`expectedReturnToModifiedActivitiesDate`}
            label=""
            value={
              reduxTreatmentGoals["expectedReturnToModifiedActivitiesDate"]
            }
            onChange={handleChange}
            className="maxWidth200"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormText
            required={!isTreatmentFunctionalGoalRequirementSatisfied()}
            type="text"
            testId="formOtherFunctionalDescription"
            field="otherFunctionalDescription"
            label="Other (specify)"
            feedbackLabel=""
            value={reduxTreatmentGoals.otherFunctionalDescription}
            onChange={handleChange}
          />
        </Col>
      </Row>
      {props.validated && !isTreatmentFunctionalGoalRequirementSatisfied() && (
        <Row>
          <Col data-testid="formTreatmentGoalsQ2Invalid" className="invalid">
            At least 1 functional goal selection is required
          </Col>
        </Row>
      )}
    </Form.Group>
  );
}

export default TreatmentGoals;
