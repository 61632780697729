import { Form, Col, Row } from "react-bootstrap";
import FormTextArea from "../FormElements/FormTextArea";
import FormSelect from "../FormElements/FormSelect";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setOutcomeCode } from "../../redux/OutcomeCode/OutcomeCodeSlice";
import { ListType, OutcomeCodes } from "../../../types/ListTypes";

interface Props {}

function OutcomeCode(props: Props) {
  const dispatch = useAppDispatch();
  const reduxOutcomeCode = useAppSelector((state) => state.outcomeCode);

  function handleChange(event: any) {
    const { name, value } = event.target;
    dispatch(setOutcomeCode({ ...reduxOutcomeCode, [name]: value }));
  }
  return (
    <Form.Group id="formOutcomeCode">
      <Row>
        <Col>
          <div className="formSectionHeader">
            <h6>Outcome Code</h6>
          </div>
        </Col>
      </Row>
      <Row>
        <div>
          <Col className="maxWidth550">
            <FormSelect
              testId="formOutcomeCodeSelect"
              field="code"
              label="Outcome Code"
              value={reduxOutcomeCode.code}
              onChange={handleChange}
              data={OutcomeCodes}
              mapping={(g: ListType) => {
                return { value: g.value, text: g.description };
              }}
              defaultOption={{ value: "", text: "" }}
              required
            />
          </Col>
        </div>
      </Row>
      <Row>
        <Col>
          <FormTextArea
            label="Reason why the program was not completed"
            testId="formReasonNotCompleted"
            field="reasonNotCompleted"
            className="formTextArea"
            value={reduxOutcomeCode.reasonNotCompleted}
            onChange={handleChange}
            rows={2}
            feedbackLabel="Program not completed reason"
            required={reduxOutcomeCode.code === "6"}
          />
        </Col>
      </Row>
    </Form.Group>
  );
}

export default OutcomeCode;
