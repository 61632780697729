import React from "react";
import { Form } from "react-bootstrap";
import FormLabel from "./FormLabel";

interface Props {
  label: string;
  field: string;
  data: any[];
  mapping(item: any): { value: string | number; text: string };
  required?: boolean;
  defaultOption?: {
    value: string | number;
    text: string;
  };
  defaultValue?: string;
  value?: string | number;
  onChange?: any;
  disabled?: boolean;
  invalidMessage?: string;
  testId?: string;
  feedbackLabel?: string;
  sorting?(item1: any, item2: any): number;
}

function FormSelect({
  label,
  field,
  data,
  mapping,
  required = false,
  defaultOption,
  value,
  onChange,
  disabled = false,
  invalidMessage,
  testId,
  defaultValue,
  feedbackLabel = label,
  sorting,
}: Props) {
  if (sorting) {
    data.sort(sorting);
  }

  return (
    <>
      <FormLabel
        testId={testId ? testId + "-label" : undefined}
        text={label}
        required={required}
      />
      <Form.Control
        data-testid={testId}
        as="select"
        className="form-select"
        onChange={(e) => onChange?.(e)}
        name={field}
        id={field}
        value={value ?? defaultValue ?? defaultOption?.value}
        required={required}
        disabled={disabled}
      >
        {defaultOption && (
          <option
            data-testid={testId ? testId + "-default" : undefined}
            disabled={required}
            value={defaultOption.value}
          >
            {defaultOption.text}
          </option>
        )}
        {data.map((item: any, i: number) => {
          let { value, text } = mapping(item);
          return (
            <option
              data-testid={testId ? testId + "-" + i : undefined}
              key={i}
              value={value}
            >
              {text}
            </option>
          );
        })}
      </Form.Control>
      <Form.Control.Feedback
        type="invalid"
        data-testid={testId ? testId + "-error" : undefined}
      >
        {!disabled &&
          (feedbackLabel || invalidMessage) &&
          feedbackLabel +
            (value === defaultOption?.value
              ? " is required"
              : invalidMessage ?? "")}
      </Form.Control.Feedback>
    </>
  );
}

export default FormSelect;
