import { useState, forwardRef } from "react";
import { isValid } from "date-fns";
import { Form } from "react-bootstrap";
import FormLabel from "../FormElements/FormLabel";
import NumberFormat from "react-number-format";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CustomDatePicker.css";
import moment from 'moment';

const getDate = (date: string) : Date | undefined => {
  if(date === undefined)
    return undefined;
    
  let d = moment(date);
  if(!d.isValid)
    return undefined;

  return d.toDate();
}

function CustomDatePicker(props: any) {  
  const [date, setDate] = useState<Date | undefined>(getDate(props.value));

  function handleDateChange(event: any) {
    setDate(event);

    if (event) {
      let formatted = event.toISOString().split("T")[0];
      let customEvent = { target: { name: props.field, value: formatted } };
      props.onChange(customEvent);
    }
  }

  function handleDateChangeRaw(event: any) {
    const dateInput = document.getElementById(props.field) as HTMLInputElement;
    dateInput.setCustomValidity("");
    dateInput.checkValidity();

    if (event?.type === "change") {
      const { value } = event.target;

      try {
        var newDate = new Date(
          value.replace(/(\d{2})-(\d{2})-(\d{4})/, "$3/$2/$1")
        );
        if (
          !isValid(newDate) ||
          newDate.getFullYear() < 1583 ||
          newDate > props.max
        ) {
          event.target.setCustomValidity("  ");
        }
      } catch (e) {
        console.log("Exception", e);
      }
    }
  }

  return (
    <>
      {props.label && (
        <FormLabel
          testId={props.testId ? props.testId + "-label" : undefined}
          text={props.label}
          required={props.required}
        />
      )}
      <DatePicker
        id={props.id}
        dateFormat="dd-MM-yyyy"
        placeholderText="dd-MM-yyyy"
        selected={date}
        onChange={(event) => {
          handleDateChange(event);
        }}
        onChangeRaw={(event) => {
          handleDateChangeRaw(event);
        }}
        maxDate={props.max}
        required={props.required}
        showYearDropdown
        adjustDateOnChange
        customInput={<CustomInput {...props} />}
      />
    </>
  );
}

const CustomInput = forwardRef((props: any, ref: any) => {
  return (
    <>
      <NumberFormat
        data-testid={props.testId}
        className="form-control"
        id={props.field}
        name={props.field}
        value={props.value}
        onClick={props.onClick}
        max={props.max?.toISOString().split("T")[0]}
        required={props.required}
        ref={ref}
        displayType="input"
        format="##-##-####"
        placeholder="dd-MM-yyyy"
        mask={["d", "d", "M", "M", "y", "y", "y", "y"]}
        autoComplete="off"
        onChange={props.onChange}
        onKeyDown={(e: any) => {
          e.key === "Enter" && e.preventDefault();
        }}
      />
      <Form.Control.Feedback
        type="invalid"
        data-testid={props.testId ? props.testId + "-error" : undefined}
      >
        {(props.label || props.invalidMessage) &&
          props.label +
            (props.value === "" && props.required
              ? " is required"
              : props.invalidMessage)}
      </Form.Control.Feedback>
    </>
  );
});

export default CustomDatePicker;
