import { Form, Col, Row, OverlayTrigger, Popover, Card } from "react-bootstrap";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";
import FormTextArea from "../FormElements/FormTextArea";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setCurrentAssessmentFindings } from "../../redux/CurrentAssessmentFindings/CurrentAssessmentFindingsSlice";
import FormSelect from "../FormElements/FormSelect";
import * as messages from "../../../constants/Messages";
import {
  CurrentAssessmentFindingsList,
  ListType,
} from "../../../types/ListTypes";
interface Props {}

function CurrentAssessmentFindings(props: Props) {
  const dispatch = useAppDispatch();
  const reduxCAF = useAppSelector((state) => state.currentAssessmentFindings);

  const popover = (
    <Popover>
      <Popover.Body className="preserveWhitespace">
        <div className="form-text">
          <div>
            <b>Grade I:</b> symptoms, no signs
          </div>
          <div>
            <b>Grade II:</b> symptoms and musculoskeletal signs
            (ex.&nbsp;Decreased ROM, point tenderness)
          </div>
          <div>
            <b>Grade III:</b> symptoms and neurologic signs
          </div>
          <div>
            <b>Grade IV:</b> symptoms and fracture/dislocation
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  function handleChange(event: any) {
    const { name, value } = event.target;

    dispatch(setCurrentAssessmentFindings({ ...reduxCAF, [name]: value }));
  }

  return (
    <Form.Group id="formCustomerInformation">
      <Row>
        <Col>
          <div className="formSectionHeader">
            <h6>Current Assessment Findings</h6>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="maxWidth300">
          <CustomDatePicker
            testId="formCAFDateOfAssessment"
            field="dateOfAssessment"
            label="Date of Assessment"
            value={reduxCAF.dateOfAssessment ?? undefined}
            onChange={handleChange}
            required
            max={new Date()}
            invalidMessage={messages.MUST_BE_VALID_DATE}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormTextArea
            required
            label="Primary Diagnosis"
            testId={"formCAFPrimaryDiagnosis"}
            field="primaryDiagnosis"
            className="formTextArea"
            value={reduxCAF.primaryDiagnosis}
            onChange={handleChange}
            rows={2}
          />
        </Col>
      </Row>
      <Row>
        <Col className="maxWidth400">
          <div
            className="formLabelText"
            data-testid="formCAFSpinalInjuryLevel-label"
          >
            If a spinal injury, indicate injury level:
          </div>
        </Col>
        <OverlayTrigger
          trigger={["click", "hover", "focus"]}
          overlay={popover}
          delay={{ show: 275, hide: 0 }}
        >
          <Col className="maxWidth500 text-center">
            <Card>
              <div className="formLabelText">Definitions</div>
            </Card>
          </Col>
        </OverlayTrigger>
      </Row>
      <Row>
        <Col className="maxWidth300">
          <FormSelect
            testId="spinalInjuryCervical"
            field="spinalInjuryCervical"
            label="Cervical"
            value={reduxCAF.spinalInjuryCervical}
            onChange={handleChange}
            data={CurrentAssessmentFindingsList}
            mapping={(g: ListType) => {
              return { value: g.value, text: g.description };
            }}
          />
        </Col>
        <Col className="maxWidth300">
          <FormSelect
            testId="spinalInjuryThoracic"
            field="spinalInjuryThoracic"
            label="Thoracic"
            value={reduxCAF.spinalInjuryThoracic}
            onChange={handleChange}
            data={CurrentAssessmentFindingsList}
            mapping={(g: ListType) => {
              return { value: g.value, text: g.description };
            }}
          />
        </Col>
        <Col className="maxWidth300">
          <FormSelect
            testId="spinalInjuryLumbosacral"
            field="spinalInjuryLumbosacral"
            label="Lumbosacral"
            value={reduxCAF.spinalInjuryLumbosacral}
            onChange={handleChange}
            data={CurrentAssessmentFindingsList}
            mapping={(g: ListType) => {
              return { value: g.value, text: g.description };
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormTextArea
            label="If peripheral joint, the diagnosis is"
            placeholder="Peripheral Joint Diagnosis"
            testId={"formCAFPeripheralJointDiagnosis"}
            field="peripheralJointDiagnosis"
            feedbackLabel="Peripheral Join Diagnosis"
            value={reduxCAF.peripheralJointDiagnosis}
            onChange={handleChange}
            rows={1}
          />
        </Col>
      </Row>
    </Form.Group>
  );
}

export default CurrentAssessmentFindings;
