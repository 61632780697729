import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Button } from "react-bootstrap";
import "./Header.css";
import logo from "../../../assets/SGI RGB logo 300dpi.png";
import { useHistory } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { HeaderDropDownButton } from "./HeaderDropDownButton";
import { User } from "../../../types/UserTypes";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../utils/auth/authConfig";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { loadingUser, login } from "../../redux/Login/LoginSlice";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
} from "@azure/msal-browser";
import { appInsights } from "../../../AppInsights";
import { InlineSpinner } from "../Spinners/Spinners";
import { updateUserDetails } from "../../../api/UserApi";
import { updateOrganizationDetails } from "../../../api/OrganizationApi";
import * as permissionsApi from "../../../api/PermissionsApi";
import { setUserPermissions } from "../../redux/Permissions/PermissionsSlice";
import { BannerMessage } from "../BannerMessage/BannerMessage";
import { ToastMessages } from "../Toast/ToastMessages";

function isAuthenticated(reduxLogin: any): boolean {
  return reduxLogin.signedIn;
}

export function Header() {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const reduxLogin = useAppSelector((state) => state.login);

  // auth declares
  const { instance } = useMsal();

  const [disableLogin, setDisableLogin] = useState(false);

  // AAD login
  const handleLogin = async () => {
    setDisableLogin(true);
    dispatch(loadingUser({ loading: true }));
    try {
      await instance
        .loginRedirect(loginRequest)
        .catch((e) => {
          console.log("Error with authentication: " + e);
        })
        .then((response) => {
          if (response === undefined) {
            return;
          }
          console.log("Login successful " + response);
        });
    } catch (err) {
      console.error(err);
    }
  };

  async function assignSecurity(payload: any) {
    if (payload) {
      let username =
        payload.account.username === ""
          ? payload.account.name
          : payload.account.username;

      let permissions = payload.idTokenClaims?.permissions as string[];

      let orgPermissions =
        permissions?.filter(
          (permission: string) =>
            permission.startsWith("org:") && !permission.startsWith("org:*")
        ) ?? [];

      let availableOrgs = [
        ...Array.from(new Set(orgPermissions.map((o) => o.slice(4, 40)))),
      ];

      let orgId = availableOrgs.length
        ? availableOrgs[0]
        : "00000000-0000-0000-0000-000000000000";

      const loginPackage: User = {
        signedIn: false,
        loading: false,
        username: username,
        fullName: payload.idTokenClaims.name,
        payeeNumber: "",
        email: payload.idTokenClaims.email,
        companyName: "",
        userId: payload.idTokenClaims.auth_user_id,
        availableOrganizations: availableOrgs,
        organizationId: orgId,
        givenName: payload.idTokenClaims.given_name,
        surName: payload.idTokenClaims.family_name,
      };
      dispatch(login(loginPackage));

      let userPermissions = {};
      await permissionsApi
        .getPermissions(orgId)
        .then((result) => {
          if (result !== undefined) {
            if (result?.data) {
              userPermissions = result.data;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });

      dispatch(setUserPermissions(userPermissions));

      if (Object.values(userPermissions)?.filter((x) => x).length > 0) {
        updateUserDetails(loginPackage);
        updateOrganizationDetails(orgId);
      }
    }

    setDisableLogin(false);
  }

  useEffect(() => {
    const isLoggedIn = instance.getAllAccounts().length > 0;
    if(isLoggedIn) {
      handleLogin();
    } 
    instance.addEventCallback((message: EventMessage) => {
      // Update UI or interact with EventMessage here
      const authResult = message.payload as AuthenticationResult;
      if (
        (message.eventType === EventType.LOGIN_SUCCESS ||
          message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        authResult.account
      ) {
        dispatch(loadingUser({ loading: true }));
        const account = authResult.account;

        instance.setActiveAccount(account);
        if (appInsights !== undefined) {
          appInsights.setAuthenticatedUserContext(account.username);
          appInsights.trackTrace({
            message: `Successful login`,
            severityLevel: SeverityLevel.Information,
          });
        }

        assignSecurity(message.payload);
      }
      const accounts = instance.getAllAccounts();
      if (accounts !== undefined) {
        if (accounts.length > 0) {
          instance.setActiveAccount(accounts[0]);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance]);

  return (
    <>
      <Container id="header-container" className="fixed-top">
        <ToastMessages />
        <div className="header-logo" data-testid="header-logo">
          <img
            src={logo}
            alt="SGI logo"
            onClick={(e) => {
              history.push("/");
            }}
          />
        </div>
        <div className="header-title" onClick={() => history.push("/")}>
          Secure Document Submission
        </div>
        <div className="header-login">
          {!isAuthenticated(reduxLogin) ? (
            <>
              <Button
                className="header-btn"
                variant="outline-primary"
                id="HeaderLoginButton"
                data-testid="HeaderLoginButton"
                onClick={handleLogin}
                disabled={disableLogin}
              >
                {reduxLogin.loading && (
                  <InlineSpinner
                    for="button"
                    id="loginSpinner"
                    data-testid="loginSpinner"
                  />
                )}
                {!reduxLogin.loading && "Login"}
              </Button>
            </>
          ) : (
            <HeaderDropDownButton />
          )}
        </div>
      </Container>
      <div className="HeaderSpacer"></div>
      <BannerMessage />
    </>
  );
}
