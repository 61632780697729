import { configureStore, ThunkAction, Action, EnhancedStore } from '@reduxjs/toolkit';
import CustomerReducer from "./Customer/CustomerSlice"
import MedicalHistoryReducer from "./MedicalHistory/MedicalHistorySlice"
import LoginReducer from "./Login/LoginSlice"
import PractitionerReducer from "./Practitioner/PractitionerSlice"
import PractitionerProfileReducer from "./PractitionerProfile/PractitionerProfileSlice"
import CurrentAssessmentFindingsReducer from './CurrentAssessmentFindings/CurrentAssessmentFindingsSlice';
import SubjectiveAndObjectiveFindingsReducer from './SubjectiveAndObjectiveFindings/SubjectiveAndObjectiveFindingsSlice';
import TreatmentGoalsReducer from './TreatmentGoals/TreatmentGoalsSlice';
import PermanentScarringOrDisfigurementReducer from './PermanentScarringOrDisfigurement/PermanentScarringOrDisfigurementSlice';
import FlagsReducer from "./Flags/FlagsSlice";
import FormHeaderReducer from "./FormHeader/FormHeaderSlice"
import ManagementPlanReducer from "./ManagementPlan/ManagementPlanSlice"
import OutcomeMeasureUsedReducer from "./OutcomeMeasureUsed/OutcomeMeasureUsedSlice"
import OutcomeCodeReducer from "./OutcomeCode/OutcomeCodeSlice"
import ClientReportedCurrentFunctionReducer from "./ClientReportedCurrentFunction/ClientReportedCurrentFunctionSlice"
import PractitionerReportedCurrentFunctionReducer from "./PractitionerReportedCurrentFunction/PractitionerReportedCurrentFunctionSlice"
import ComplianceAndAttendanceReducer from "./ComplianceAndAttendance/ComplianceAndAttendanceSlice";
import RemarksReducer from "./Remarks/RemarksSlice";
import ToastMessagesReducer from "./ToastMessages/ToastMessagesSlice";
import ReasonForDischargeReducer from "./ReasonForDischarge/ReasonForDischargeSlice";
import ProgramResultsReducer from "./ProgramResults/ProgramResultsSlice";
import SpecialRestrictionsReturnToNormalActivityReducer from "./SpecialRestrictionsReturnToNormalActivity/SpecialRestrictionsReturnToNormalActivitySlice";
import IssuesReturnToNormalActivityReducer from "./IssuesReturnToNormalActivity/IssuesReturnToNormalActivitySlice";
import FurtherTreatmentCommentsReducer from "./FurtherTreatmentComments/FurtherTreatmentCommentsSlice";
import PermissionsReducer from "./Permissions/PermissionsSlice";
import FormFooterReducer from "./FormFooter/FormFooterSlice";
import { TypedUseSelectorHook, useSelector } from "react-redux";

const reducers = {
  customer: CustomerReducer,
  login: LoginReducer,
  practitioner: PractitionerReducer,
  practitionerProfile: PractitionerProfileReducer,
  currentAssessmentFindings: CurrentAssessmentFindingsReducer,  
  medicalHistory: MedicalHistoryReducer,
  permanentScarringOrDisfigurement: PermanentScarringOrDisfigurementReducer,
  subjectiveAndObjectiveFindings: SubjectiveAndObjectiveFindingsReducer,
  treatmentGoals: TreatmentGoalsReducer,
  formHeader: FormHeaderReducer,
  managementPlan: ManagementPlanReducer,
  flags: FlagsReducer,
  outcomeMeasureUsed: OutcomeMeasureUsedReducer,
  outcomeCode:OutcomeCodeReducer,
  clientReportedCurrentFunction: ClientReportedCurrentFunctionReducer,
  practitionerReportedCurrentFunction: PractitionerReportedCurrentFunctionReducer,
  complianceAndAttendance:ComplianceAndAttendanceReducer,
  remarks:RemarksReducer,
  toastMessages: ToastMessagesReducer,
  reasonForDischarge: ReasonForDischargeReducer,
  programResults: ProgramResultsReducer,
  specialRestrictionsReturnToNormalActivity: SpecialRestrictionsReturnToNormalActivityReducer,
  issuesReturnToNormalActivity: IssuesReturnToNormalActivityReducer,
  furtherTreatmentComments: FurtherTreatmentCommentsReducer,
  permissions: PermissionsReducer,
  formFooter: FormFooterReducer
};

export const store = configureStore({
  reducer: reducers,
});

export function createMockStore() {
  const mockStore:EnhancedStore = 
  configureStore({
    reducer: reducers,
    });

  return mockStore;
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
