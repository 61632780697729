import { createSlice } from '@reduxjs/toolkit'
import { defaultProgramResults } from "../../../types/ProgramResults";

export const ProgramResultsSlice = createSlice({
    name: "ProgramResults",
    initialState: defaultProgramResults,
    reducers: {
        setProgramResults: (state, action) => {
            return {...state, ...action.payload};
        },
        clearProgramResults: state => {
            return {...defaultProgramResults}
        }
    }
})

export const {setProgramResults, clearProgramResults} = ProgramResultsSlice.actions;

export default ProgramResultsSlice.reducer;