import { createSlice } from '@reduxjs/toolkit'
import { defaultOutcomeMeasureUsed } from "../../../types/OutcomeMeasureUsed"

export const OutcomeMeasureUsedSlice = createSlice({
    name: "OutcomeMeasureUsed",
    initialState: defaultOutcomeMeasureUsed,
    reducers:{
        setOutcomeMeasureUsed: (state, action) => {
            return {...state,...action.payload};
        },
        clearOutcomeMeasureUsed: state => {
            return {...defaultOutcomeMeasureUsed}
        }
    }
})

export const {setOutcomeMeasureUsed, clearOutcomeMeasureUsed} = OutcomeMeasureUsedSlice.actions;

export default OutcomeMeasureUsedSlice.reducer;