import { useAppSelector } from "../../app/redux/hooks";
import { UserPermissions } from "../../types/UserPermissions";

export function isAuthorized(
  permissions: UserPermissions,
  requiredPerms: string[],
  requireAll = true
): boolean {
  if (!requiredPerms || requiredPerms.length === 0) return true;
  if (!permissions) return false;
  let authorized: boolean;

  let permissionsArray = Object.entries(permissions)
    .filter(([key, value]) => {
      return value === true;
    })
    .flat(1)
    .filter((value) => value !== true);

  requireAll
    ? (authorized = requiredPerms.every((permission) =>
        permissionsArray.includes(permission)
      ))
    : (authorized = requiredPerms.some((permission) =>
        permissionsArray.includes(permission)
      ));

  return authorized;
}

export function GetUserPermissions() {
  return useAppSelector((state) => state.permissions) as UserPermissions;
}
