import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "../AppInsights";
import * as Messages from "../constants/Messages";

export async function handleResponse(response: any) {
  if (!(response.status >= 200 && response.status < 300)) {
    if (appInsights !== undefined) {
      appInsights.trackTrace({
        message: Messages.NETWORK_RESPONSE_NOT_OK + ": " + response,
        severityLevel: SeverityLevel.Error,
      });
    }

    console.error(Messages.NETWORK_RESPONSE_NOT_OK);
  }

  return response;
}

export async function handleError(error: any) {
  console.error(Messages.API_CALL_FAILED + error);
  if (error.response) {
    // The request was made and the server responded with a status code
    if (error.response.status === 400 || error.response.status === 409) {
      // So, a server-side validation error occurred.
      // Server side validation returns a string error message, so parse as text instead of json.
      logError(error);
      return error.response.data;
    } else if (error.response.status === 404) {
      logError(error);
      return Messages.NOT_FOUND;
    } else {
      logError(error);
    }
  } else {
    logError(error);
    console.log("Error", error.message);
  }
}

function logError(error: any) {
  if (appInsights !== undefined) {
    appInsights.trackTrace({
      message:
        Messages.API_CALL_FAILED +
        error +
        " at " +
        error.config.method +
        " " +
        error.config.url,
      severityLevel: SeverityLevel.Error,
    });
  }
}

const apiUtils = { handleResponse, handleError };
export default apiUtils;
