import { Form, Col, Row } from "react-bootstrap";
import { setComplianceAndAttendance } from "../../redux/ComplianceAndAttendance/ComplianceAndAttendanceSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import FormTextArea from "../FormElements/FormTextArea";
interface Props {}

function ComplianceAndAttendance(props: Props) {
  const dispatch = useAppDispatch();
  const reduxComplianceAndAttendance = useAppSelector(
    (state) => state.complianceAndAttendance
  );

  function handleChange(event: any) {
    const { name, value } = event.target;
    dispatch(
      setComplianceAndAttendance({
        ...reduxComplianceAndAttendance,
        [name]: value,
      })
    );
  }

  return (
    <Form.Group id="formComplianceAndAttendance">
      <Row>
        <Col>
          <div className="formSectionHeader">
            <h6>Compliance And Attendance</h6>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormTextArea
            label=""
            testId={"formComplianceAndAttendance"}
            field="summaryComplianceAndAttendance"
            className="formTextArea"
            value={reduxComplianceAndAttendance.summaryComplianceAndAttendance}
            onChange={handleChange}
            rows={4}
            feedbackLabel="Compliance and Attendance"
          />
        </Col>
      </Row>
    </Form.Group>
  );
}

export default ComplianceAndAttendance;
