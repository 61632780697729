import { Form, Col, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setPermanentScarringOrDisfigurement } from "../../redux/PermanentScarringOrDisfigurement/PermanentScarringOrDisfigurementSlice";
import FormCheck from "../FormElements/FormCheck";

interface Props {}
function PermanentScarringOrDisfigurement(props: Props) {
	const dispatch = useAppDispatch();
	const reduxPermanentScarringOrDisfigurement = useAppSelector(
		(state) => state.permanentScarringOrDisfigurement
	);

	function handleChange(event: any) {
		const { name, value, checked } = event.target;

		var saveValue =
			name === "isPermanentScarringOrDisfigurementPresent" ? checked : value;

		dispatch(
			setPermanentScarringOrDisfigurement({
				...reduxPermanentScarringOrDisfigurement,
				[name]: saveValue,
			})
		);
	}

	return (
		<Form.Group id="formPermanentScarringOrDisfigurement">
			<Row>
				<Col>
					<div className="formSectionHeader">
						<h6>Presence of Permanent Scarring or Disfigurement</h6>
					</div>
				</Col>
			</Row>
			<Row>
				<Col className="maxWidth500">
					<Form.Label className="formLabelText">
						Presence of Permanent Scarring or Disfigurement
					</Form.Label>
				</Col>
				<Col className="form-check">
					<FormCheck
						testId="formIsPermanentScarringOrDisfigurementPresent"
						field="isPermanentScarringOrDisfigurementPresent"
						checked={
							reduxPermanentScarringOrDisfigurement.isPermanentScarringOrDisfigurementPresent
						}
						onChange={handleChange}
					/>
				</Col>
			</Row>
		</Form.Group>
	);
}

export default PermanentScarringOrDisfigurement;
