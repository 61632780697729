import { Form } from "react-bootstrap";
import { FormCheckType } from "react-bootstrap/esm/FormCheck";

interface Props {
  checked: any;
  className?: string;
  testId?: string;
  feedback?: string;
  field: string;
  id?: string;
  inline?: boolean;
  key?: number;
  label?: string;
  onChange?: any;
  preventSubmitOnEnterKey?: boolean;
  required?: boolean;
  type?: FormCheckType;
  value?: string;
}

function FormCheck({
  checked = false,
  className = "",
  testId,
  feedback = "",
  field,
  id = field,
  inline = false,
  label = "",
  onChange,
  required = false,
  preventSubmitOnEnterKey = true,
  type = "checkbox",
  value,
}: Props) {
  return (
    <>
      <Form.Check
        checked={checked}
        className={className}
        data-testid={testId}
        feedback={feedback}
        feedbackType="invalid"
        id={id}
        inline={inline}
        label={label}
        name={field}
        onChange={onChange}
        required={required}
        type={type}
        onKeyDown={(e) => {
          preventSubmitOnEnterKey && e.key === "Enter" && e.preventDefault();
        }}
        value={value}
      />
    </>
  );
}

export default FormCheck;
