export interface CurrentAssessmentFindings {
  dateOfAssessment?: Date;
  primaryDiagnosis: string;
  spinalInjuryCervical: string;
  spinalInjuryThoracic: string;
  spinalInjuryLumbosacral: string;
  peripheralJointDiagnosis: string;
}

export const defaultCurrentAssessmentFindings: CurrentAssessmentFindings = {
  dateOfAssessment: undefined,
  primaryDiagnosis: "",
  spinalInjuryCervical: "none",
  spinalInjuryThoracic: "none",
  spinalInjuryLumbosacral: "none",
  peripheralJointDiagnosis: "",
};
