import { Form, Col, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import FormTextArea from "../FormElements/FormTextArea";
import { setFurtherTreatmentComments } from "../../redux/FurtherTreatmentComments/FurtherTreatmentCommentsSlice";
import FormSelect from "../FormElements/FormSelect";
import { FurthTreatmentComments, ListType } from "../../../types/ListTypes";

interface Props {}

function FurtherTreatmentComments(props: Props) {
  const dispatch = useAppDispatch();
  const reduxFurtherTreatmentComments = useAppSelector(
    (state) => state.furtherTreatmentComments
  );

  function handleChange(event: any) {
    const { name, value } = event.target;

    let fieldVal = null;
    if (name === "hasFurtherTreatmentComments") {
      fieldVal = value === "true" ? true : false;
    } else {
      fieldVal = value;
    }

    dispatch(
      setFurtherTreatmentComments({
        ...reduxFurtherTreatmentComments,
        [name]: fieldVal,
      })
    );
  }

  return (
    <Form.Group id="formFurtherTreatmentComments">
      <Row>
        <Col>
          <div className="formSectionHeader">
            <h6>Further treatment/management/comments</h6>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={3} sm={3} md={2}>
          <FormSelect
            testId="formFurtherTreatmentCommentsHasComments"
            field="hasFurtherTreatmentComments"
            label="Issues"
            value={reduxFurtherTreatmentComments.hasFurtherTreatmentComments.toString()}
            onChange={handleChange}
            data={FurthTreatmentComments}
            mapping={(g: ListType) => {
              return { value: g.value, text: g.description };
            }}
          />
        </Col>
      </Row>
      <div>&nbsp;</div>
      <Row>
        <Col>
          <FormTextArea
            label=""
            testId="formFurtherTreatmentCommentsHasCommentsDetails"
            field="furtherTreatmentComments"
            className="formTextArea"
            value={reduxFurtherTreatmentComments.furtherTreatmentComments}
            onChange={handleChange}
            rows={4}
            feedbackLabel="Further treatment/management/comments"
            required={
              reduxFurtherTreatmentComments.hasFurtherTreatmentComments === true
            }
          />
        </Col>
      </Row>
    </Form.Group>
  );
}

export default FurtherTreatmentComments;
