import { Form, Col, Row } from "react-bootstrap";
import FormLabel from "../FormElements/FormLabel";
import FormText from "../FormElements/FormText";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setOutcomeMeasureUsed } from "../../redux/OutcomeMeasureUsed/OutcomeMeasureUsedSlice";

interface Props {
  validated: boolean;
}

function OutcomeMeasureUsed(props: Props) {
  const dispatch = useAppDispatch();
  const reduxOutcomeMeasureUsed = useAppSelector(
    (state) => state.outcomeMeasureUsed
  );

  function handleChange(event: any) {
    const { name, value } = event.target;
    dispatch(
      setOutcomeMeasureUsed({
        ...reduxOutcomeMeasureUsed,
        [name]: value.trimStart(),
      })
    );
  }

  const isOutcomeMeasureUsedRequirementSatisfied = () =>
    reduxOutcomeMeasureUsed.rolandMorris.trim() !== "" ||
    reduxOutcomeMeasureUsed.quickDashWorkModule.trim() !== "" ||
    reduxOutcomeMeasureUsed.neckDisabilityIndex.trim() !== "" ||
    reduxOutcomeMeasureUsed.lowerExtremityFunctionalScale.trim() !== "" ||
    reduxOutcomeMeasureUsed.visualAnalogueScale.trim() !== "" ||
    reduxOutcomeMeasureUsed.oswestry.trim() !== "" ||
    reduxOutcomeMeasureUsed.clinicalOutcomeVariablesScale.trim() !== "" ||
    reduxOutcomeMeasureUsed.timedUpAndGoTest.trim() !== "" ||
    reduxOutcomeMeasureUsed.other.trim() !== "";

  return (
    <Form.Group id="formOutcomeMeasureUsed">
      <Row>
        <Col>
          <div className="formSectionHeader">
            <h6>Outcome Measure Used</h6>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="maxWidth800">
          <FormLabel text="Please enter values for this section as the raw scores/measurement ratios." />
        </Col>
      </Row>
      <Row xs={1} md={2}>
        <Col className="maxWidth500">
          <Row>
            <Col xs={8} className="maxWidth400">
              <FormLabel text="Roland Morris" />
            </Col>
            <Col className="maxWidth200">
              <FormText
                required={!isOutcomeMeasureUsedRequirementSatisfied()}
                type="text"
                testId="formOutcomeMeasureUsedRolandMorris"
                className="maxWidth100"
                field="rolandMorris"
                feedbackLabel=""
                placeholder=""
                value={reduxOutcomeMeasureUsed.rolandMorris}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </Col>
        <Col className="maxWidth500">
          <Row>
            <Col xs={8} className="maxWidth400">
              <FormLabel text="QD (Quick Dash) Work Module" />
            </Col>
            <Col className="maxWidth200">
              <FormText
                required={!isOutcomeMeasureUsedRequirementSatisfied()}
                type="text"
                testId="formOutcomeMeasureUsedQuickDashWorkModule"
                className="maxWidth100"
                field="quickDashWorkModule"
                feedbackLabel=""
                placeholder=""
                value={reduxOutcomeMeasureUsed.quickDashWorkModule}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </Col>
        <Col className="maxWidth500">
          <Row>
            <Col xs={8} className="maxWidth400">
              <FormLabel text="NDI (Neck Disability Index)" />
            </Col>
            <Col>
              <FormText
                required={!isOutcomeMeasureUsedRequirementSatisfied()}
                type="text"
                testId="formOutcomeMeasureUsedNeckDisabilityIndex"
                className="maxWidth100"
                field="neckDisabilityIndex"
                feedbackLabel=""
                placeholder=""
                value={reduxOutcomeMeasureUsed.neckDisabilityIndex}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </Col>
        <Col className="maxWidth500">
          <Row>
            <Col xs={8} className="maxWidth400">
              <FormLabel text="LEFS (Lower Extremity Functional Scale)" />
            </Col>
            <Col>
              <FormText
                required={!isOutcomeMeasureUsedRequirementSatisfied()}
                type="text"
                testId="formOutcomeMeasureUsedLowerExtremityFunctionScale"
                className="maxWidth100"
                field="lowerExtremityFunctionalScale"
                feedbackLabel=""
                placeholder=""
                value={reduxOutcomeMeasureUsed.lowerExtremityFunctionalScale}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </Col>
        <Col className="maxWidth500">
          <Row>
            <Col xs={8} className="maxWidth400">
              <FormLabel text="VAS (Visual Analogue Scale)" />
            </Col>
            <Col>
              <FormText
                required={!isOutcomeMeasureUsedRequirementSatisfied()}
                type="text"
                testId="formOutcomeMeasureUsedVisualAnalogueScale"
                className="maxWidth100"
                field="visualAnalogueScale"
                feedbackLabel=""
                placeholder=""
                value={reduxOutcomeMeasureUsed.visualAnalogueScale}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </Col>
        <Col className="maxWidth500">
          <Row>
            <Col xs={8} className="maxWidth400">
              <FormLabel text="Oswestry" />
            </Col>
            <Col>
              <FormText
                required={!isOutcomeMeasureUsedRequirementSatisfied()}
                type="text"
                testId="formOutcomeMeasureUsedOswestry"
                className="maxWidth100"
                field="oswestry"
                feedbackLabel=""
                placeholder=""
                value={reduxOutcomeMeasureUsed.oswestry}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </Col>
        <Col className="maxWidth500">
          <Row>
            <Col xs={8} className="maxWidth400">
              <FormLabel text="COVS (Clinical Outcome Variables Scale)" />
            </Col>
            <Col>
              <FormText
                required={!isOutcomeMeasureUsedRequirementSatisfied()}
                type="text"
                testId="formOutcomeMeasureUsedClinicalOutcomeVariablesScale"
                className="maxWidth100"
                field="clinicalOutcomeVariablesScale"
                feedbackLabel=""
                placeholder=""
                value={reduxOutcomeMeasureUsed.clinicalOutcomeVariablesScale}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </Col>
        <Col className="maxWidth500">
          <Row>
            <Col xs={8} className="maxWidth400">
              <FormLabel text="TUG (Timed up and go test)" />
            </Col>
            <Col>
              <FormText
                required={!isOutcomeMeasureUsedRequirementSatisfied()}
                type="text"
                testId="formOutcomeMeasureUsedTimedUpAndGoTest"
                className="maxWidth100"
                field="timedUpAndGoTest"
                feedbackLabel=""
                placeholder=""
                value={reduxOutcomeMeasureUsed.timedUpAndGoTest}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </Col>
        <Col className="maxWidth500">
          <Row>
            <Col xs={8} className="maxWidth400">
              <FormLabel text="Other  (if unavailable):" />
            </Col>
            <Col>
              <FormText
                required={!isOutcomeMeasureUsedRequirementSatisfied()}
                type="text"
                testId="formOutcomeMeasureUsedOther"
                className="maxWidth100"
                field="other"
                feedbackLabel=""
                placeholder=""
                value={reduxOutcomeMeasureUsed.other}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      {props.validated && !isOutcomeMeasureUsedRequirementSatisfied() && (
        <Row>
          <Col data-testid="formOutcomeMeasureUsedInvalid" className="invalid">
            At least 1 outcome measure is required
          </Col>
        </Row>
      )}
    </Form.Group>
  );
}

export default OutcomeMeasureUsed;
