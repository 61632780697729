import { Form, Col, Row } from "react-bootstrap";
import { setProgramResults } from "../../redux/ProgramResults/ProgramResultsSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import FormTextArea from "../FormElements/FormTextArea";

interface Props {}
function ProgramResults(props: Props) {
  const dispatch = useAppDispatch();
  const reduxProgramResults = useAppSelector((state) => state.programResults);

  function handleChange(event: any) {
    const { name, value } = event.target;
    dispatch(
      setProgramResults({
        ...reduxProgramResults,
        [name]: value,
      })
    );
  }

  return (
    <Form.Group id="formProgramResults">
      <Row>
        <Col>
          <div className="formSectionHeader">
            <h6>
              Program Results (in functional terms and Residual Objective
              Findings)
            </h6>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormTextArea
            label=""
            testId={"formProgramResultsDescription"}
            field="programResultsDescription"
            className="formTextArea"
            value={reduxProgramResults.programResultsDescription}
            onChange={handleChange}
            rows={4}
            feedbackLabel="Program Results"
          />
        </Col>
      </Row>
    </Form.Group>
  );
}

export default ProgramResults;
