import React from "react";
import { Form, Col, Row } from "react-bootstrap";
import FormLabel from "../../components/FormElements/FormLabel";
import "./InformationAccuracyDisclaimer.css";

function InformationAccuracyDisclaimer() {
  return (
    <Form.Group
      className="formInformationAccuracyDisclaimer"
      id="formInformationAccuracyDisclaimer"
    >
      <Row>
        <Col className="maxWidth800">
          <FormLabel text="By submitting this form I am certifying that:" />
        </Col>
      </Row>
      <Row>
        <Col className="maxWidth800">
          <ul>
            <li>
              When submitting a report, all information is complete, and based
              on all available information, treatments, and assessments
              performed by me in my good judgement as a registered chiropractor.
            </li>
            <li>
              When submitting an invoice, the goods and services provided to and
              received by the customer as a result of the accident-related
              injuries were provided by me, and that the information provided
              for the claim payment is accurate and complete.
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col className="maxWidth800">
          <FormLabel text="Personal Information is collected under section 25 of The Freedom of Information and Protection of Privacy Act (SK) and Section 72 of The Automobile Accident Insurance Act for the purposes of obtaining a health care report, managing or invoicing a claim. Questions about the collection of this information may be directed to the Personal Injury Representative on the file." />
        </Col>
      </Row>
    </Form.Group>
  );
}

export default InformationAccuracyDisclaimer;
