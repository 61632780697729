import { Address } from "./Address";

export interface Practitioner {
  id: string;
  sgiPayeeNumber: string;
  firstName: string;
  lastName: string;
  facilityName: string;
  address: Address;
  phoneNumber: string;
}

export const defaultPractitioner: Practitioner = {
  id: "00000000-0000-0000-0000-000000000000",
  sgiPayeeNumber: "",
  firstName: "",
  lastName: "",
  facilityName: "",
  address: {
    street: "",
    city: "",
    postalCode: "",
    province: "SK",
    country: "CA",
  },
  phoneNumber: "",
};
