import { Form, Col, Row, Container } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setMedicalHistoryData } from "../../redux/MedicalHistory/MedicalHistorySlice";
import FormCheck from "../FormElements/FormCheck";
import FormText from "../FormElements/FormText";
import FormLabel from "../../components/FormElements/FormLabel";
import FormTextArea from "../FormElements/FormTextArea";

interface Props {}

function MedicalHistoryInformation(props: Props) {
  const dispatch = useAppDispatch();
  const reduxMedicalHistory = useAppSelector((state) => state.medicalHistory);

  function handleChange(event: any) {
    const { name, value, checked } = event.target;

    let fieldVal = name.includes("CheckBox") ? checked : value;

    dispatch(
      setMedicalHistoryData({ ...reduxMedicalHistory, [name]: fieldVal })
    );
  }

  return (
    <Form.Group id="formMedicalHistory">
      <Row>
        <Col>
          <div className="formSectionHeader">
            <h6>Medical History and Other Medical Conditions</h6>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="maxWidth800">
          <FormLabel text="Are there any factors that may impede recovery?" />
        </Col>
      </Row>
      <Row xs={2} sm={3} lg="auto">
        {[
          ["Addictions", "Addictions"],
          ["Dietary", "Dietary"],
          ["Family", "Family"],
          ["Employment", "Employment"],
          ["Psycho-Social", "PsychoSocial"],
          ["General Fitness", "GeneralFitness"],
        ].map((type, idx) => (
          <Col key={idx}>
            <FormCheck
              checked={
                type[0] === "Addictions"
                  ? reduxMedicalHistory["addictionsCheckBox"]
                  : type[0] === "Dietary"
                  ? reduxMedicalHistory["dietaryCheckBox"]
                  : type[0] === "Employment"
                  ? reduxMedicalHistory["employmentCheckBox"]
                  : type[0] === "Family"
                  ? reduxMedicalHistory["familyCheckBox"]
                  : type[0] === "Psycho-Social"
                  ? reduxMedicalHistory["psychoSocialCheckBox"]
                  : type[0] === "General Fitness"
                  ? reduxMedicalHistory["generalFitnessCheckBox"]
                  : false
              }
              testId={`form${type[1]}CheckBox`}
              field={`${
                type[1].charAt(0).toLowerCase() + type[1].slice(1)
              }CheckBox`}
              key={idx}
              label={type[0]}
              onChange={handleChange}
            />
          </Col>
        ))}
      </Row>
      <Row>
        <Col>
          <FormText
            type="text"
            testId="formOtherFactorDescription"
            field="otherFactoryDescription"
            label="Other (describe)"
            value={reduxMedicalHistory.otherFactoryDescription}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormTextArea
            testId="formPreExistingDescription"
            field="preExistingDescription"
            label="Are there any pre-existing and/or concurrent health conditions? (Describe)"
            rows={4}
            value={reduxMedicalHistory.preExistingDescription}
            onChange={handleChange}
          />
        </Col>
      </Row>
    </Form.Group>
  );
}

export default MedicalHistoryInformation;
