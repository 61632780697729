import { useEffect } from "react";
import { Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./theme.css";
import "./custom-theme.css";
import "./App.css";
import { Footer } from "./components/Footer/Footer";
import { Header } from "./components/Header/Header";
import { IdleTimer } from "./components/IdleTimer/IdleTimerComponent";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useAppDispatch } from "./redux/hooks";
import { setFlags } from "./redux/Flags/FlagsSlice";
import { routeProps } from "../types/RouteProps";
import PrivateRoutes from "./routes/PrivateRoutes";
import PublicRoutes from "./routes/PublicRoutes";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { ScrollUpArrow } from "./components/Common/ScrollUpArrow";
import { PractionerProfileLoader } from "./components/PractitonerProfileLoader/PractionerProfileLoader";
import { appInsights } from "../AppInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

function App() {
  const dispatch = useAppDispatch();
  const flags = useFlags();
  const idlePromptTime = 1000 * 60 * 15;
  const idleTimeoutTime = 1000 * 60 * 15;

  useEffect(() => {
    dispatch(setFlags(flags));
  }, [flags, dispatch]);

  var routePropsVal: routeProps = {};

  useEffect(() => {
    const errorHandler = (event: ErrorEvent) => {

      if (appInsights !== undefined) {
        appInsights.trackTrace({
          message: `Javascript errors: ${event.message}`,
          severityLevel: SeverityLevel.Error,
        },
        {
          message: event.error?.message,
          stack: event.error.stack,
          file: event.filename,
          environmentInfo: window.navigator?.userAgent
        });
      }
    }

    window.addEventListener('error', errorHandler);

    return () => {
      window.removeEventListener('error', errorHandler);
    }
  }, []);

  return (
    <>
      <Header />
      <PractionerProfileLoader />
      <AuthenticatedTemplate>
        <IdleTimer
          timeToPrompt={idlePromptTime}
          timeToIdleTimeout={idleTimeoutTime}
        >
          <Switch>
            {PrivateRoutes(routePropsVal)}
            {PublicRoutes(routePropsVal)}
          </Switch>
        </IdleTimer>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Switch>{PublicRoutes(routePropsVal)}</Switch>
      </UnauthenticatedTemplate>
      <ScrollUpArrow />
      <Footer />
    </>
  );
}

export default App;
