export interface MedicalHistory {
  addictionsCheckBox: boolean;
  dietaryCheckBox: boolean;
  familyCheckBox: boolean;
  employmentCheckBox: boolean;
  psychoSocialCheckBox: boolean;
  generalFitnessCheckBox: boolean;
  otherFactoryDescription: string;
  preExistingDescription: string;
}

export const defaultMedicalHistory: MedicalHistory = {
  addictionsCheckBox: false,
  dietaryCheckBox: false,
  familyCheckBox: false,
  employmentCheckBox: false,
  psychoSocialCheckBox: false,
  generalFitnessCheckBox: false,
  otherFactoryDescription: "",
  preExistingDescription: "",
};
