import { Form, Col, Row } from "react-bootstrap";
import { setFormHeader } from "../../redux/FormHeader/FormHeaderSlice";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import FormText from "../FormElements/FormText";
import * as messages from "../../../constants/Messages";

interface Props {}
function FormHeader(props: Props) {
  const dispatch = useAppDispatch();
  const reduxChiroIntakeFormHeader = useAppSelector(
    (state) => state.formHeader
  );
  function handleChange(event: any) {
    const { name, value } = event.target;
    dispatch(
      setFormHeader({
        ...reduxChiroIntakeFormHeader,
        [name]: value,
      })
    );
  }
  return (
    <Form.Group>
      <Row>
        <Col className="maxWidth400">
          <FormText 
            label="PIR"
            testId={"formIntakeFormPIR"}
            field="PIR"
            pattern="^[a-zA-z0-9 \-']*$"
            invalidMessage={messages.NAME_CHARACTERS_AND_NUMBERS_ONLY}
            value={reduxChiroIntakeFormHeader.PIR}
            onChange={handleChange}
          />
        </Col>
      </Row>
    </Form.Group>
  );
}

export default FormHeader;
