import { useState } from "react";
import { Toast } from "react-bootstrap";
import { ToastMessage } from "../../../types/ToastMessages";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setToastMessages } from "../../redux/ToastMessages/ToastMessagesSlice";
import "./Toast.css";

export function ToastMessages() {
  const reduxToastMessages = useAppSelector((state) => state.toastMessages);

  return (
    <div className="ToastContainer">
      {reduxToastMessages.toastMessages.map(
        (toastMessage: ToastMessage, index: number) => {
          return (
            <ToastItem
              key={toastMessage.toastType + index}
              toastType={toastMessage.toastType}
              message={toastMessage.message}
              title={toastMessage.title ?? toastMessage.toastType}
            />
          );
        }
      )}
    </div>
  );
}

interface ToastItemProps {
  key: string;
  toastType: string;
  message: string;
  title: string;
}

function ToastItem(props: ToastItemProps) {
  const dispatch = useAppDispatch();
  const reduxToastMessages = useAppSelector((state) => state.toastMessages);
  const [showToast, setShowToast] = useState(true);

  const onClose = () => {
    setShowToast(false);
    dispatch(
      setToastMessages([
        reduxToastMessages.toastMessages.filter(
          (m) => m.message != props.message
        ),
      ])
    );
  };

  return (
    <Toast
      show={showToast}
      onClose={onClose}
      delay={10000}
      autohide
      className={"Toast" + props.toastType}
      id={"Toast" + props.key}
      data-testid={"Toast" + props.toastType}
    >
      <Toast.Header id={"Toast" + props.toastType + "Header"}>
        <strong className="mr-auto">{props.toastType}</strong>
        <small>{props.title}</small>
      </Toast.Header>
      <Toast.Body>{props.message}</Toast.Body>
    </Toast>
  );
}
