import { createSlice } from '@reduxjs/toolkit'
import { defaultSpecialRestrictionsReturnToNormalActivity } from "../../../types/SpecialRestrictionsReturnToNormalActivity";

export const SpecialRestrictionsReturnToNormalActivitySlice = createSlice({
    name: "SpecialRestrictionsReturnToNormalActivity",
    initialState: defaultSpecialRestrictionsReturnToNormalActivity,
    reducers: {
        setSpecialRestrictionsReturnToNormalActivity: (state, action) => {
            return {...state, ...action.payload};
        },
        clearSpecialRestrictionsReturnToNormalActivity: state => {
            return {...defaultSpecialRestrictionsReturnToNormalActivity}
        }
    }
})

export const {setSpecialRestrictionsReturnToNormalActivity, clearSpecialRestrictionsReturnToNormalActivity} = SpecialRestrictionsReturnToNormalActivitySlice.actions;

export default SpecialRestrictionsReturnToNormalActivitySlice.reducer;