import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import { getAccessToken } from "../utils/auth/authApiUtils";
import { getBaseApiUrl } from "./apiHelper";

export async function checkAvailability() {
  const baseUrl = getBaseApiUrl() + `v1/securedocui/healthCheck/ping`;
  const token = await getAccessToken();

  return axios
    .get(baseUrl, {
      headers: {
        Accept: "application/json",
        ContentType: "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}
