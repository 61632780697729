import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useIsMounted } from "./IsMounted";
import "./ScrollUpArrow.css";

export function ScrollUpArrow() {
  // The back-to-top button is hidden at the beginning
  const [showButton, setShowButton] = useState(false);
  const mountedRef = useRef(true);
  const isMounted = useIsMounted();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (isMounted()) {
        if (window?.scrollY > 300) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      }
    });
    return () => {
      mountedRef.current = false;
    };
  }, [isMounted]);

  // This function will scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  return (
    <>
      {showButton && (
        <div
          id="scrollUpArrow"
          data-testid="scrollUpArrow"
          onClick={scrollToTop}
        >
          <FontAwesomeIcon
            className="float-right bounce"
            data-testid="chevronUp"
            icon={faChevronUp}
          />
        </div>
      )}
    </>
  );
}
