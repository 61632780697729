import { Form, Col, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setPractitionerReportedCurrentFunction } from "../../redux/PractitionerReportedCurrentFunction/PractitionerReportedCurrentFunctionSlice";
import {
  ListType,
  PractitionerReportedCurrentFunctionList,
} from "../../../types/ListTypes";
import FormTextArea from "../FormElements/FormTextArea";
import FormSelect from "../FormElements/FormSelect";

interface Props {}

function PractitionerReportedCurrentFunction(props: Props) {
  const dispatch = useAppDispatch();
  const reduxPractitionerReportedCurrentFunctionHistory = useAppSelector(
    (state) => state.practitionerReportedCurrentFunction
  );

  function handleChange(event: any) {
    const { name, value } = event.target;

    dispatch(
      setPractitionerReportedCurrentFunction({
        ...reduxPractitionerReportedCurrentFunctionHistory,
        [name]: value,
      })
    );
  }

  return (
    <Form.Group id="formPractitionerReportedCurrentFunction">
      <Row>
        <Col>
          <div className="formSectionHeader">
            <h6>Practitioner Reported Current Function</h6>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormSelect
            testId="practitionerCurrentFunctionWork"
            field="practitionerCurrentFunctionWork"
            label="Practitioner Reported Function"
            value={
              reduxPractitionerReportedCurrentFunctionHistory.practitionerCurrentFunctionWork
            }
            onChange={handleChange}
            defaultOption={{ value: "", text: "Select Appropriate Function" }}
            data={PractitionerReportedCurrentFunctionList}
            mapping={(v: ListType) => {
              return { value: v.value, text: v.description };
            }}
            required={
              reduxPractitionerReportedCurrentFunctionHistory.practitionerCurrentFunctionWork ===
              ""
            }
          />
        </Col>
      </Row>
      <div>&nbsp;</div>
      <Row>
        <Col>
          <FormTextArea
            testId="formWorkDutiesRestrictions"
            field="workDutiesRestrictions"
            label="Note any restriction on work duties:"
            rows={4}
            value={
              reduxPractitionerReportedCurrentFunctionHistory.workDutiesRestrictions
            }
            onChange={handleChange}
            required={
              reduxPractitionerReportedCurrentFunctionHistory.practitionerCurrentFunctionWork ===
                "WorkModifiedDuties" ||
              reduxPractitionerReportedCurrentFunctionHistory.practitionerCurrentFunctionWork ===
                "UnableToWork"
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormTextArea
            testId="formHomeYardSelfCareRestrictions"
            field="homeYardSelfCareRestrictions"
            label="Note any restriction on home, yard, ADL’s and self care:"
            rows={4}
            value={
              reduxPractitionerReportedCurrentFunctionHistory.homeYardSelfCareRestrictions
            }
            onChange={handleChange}
            required={
              reduxPractitionerReportedCurrentFunctionHistory.practitionerCurrentFunctionWork ===
                "WorkModifiedDuties" ||
              reduxPractitionerReportedCurrentFunctionHistory.practitionerCurrentFunctionWork ===
                "UnableToWork" ||
              reduxPractitionerReportedCurrentFunctionHistory.practitionerCurrentFunctionWork ===
                "NonWorker"
            }
          />
        </Col>
      </Row>
    </Form.Group>
  );
}

export default PractitionerReportedCurrentFunction;
