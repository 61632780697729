import {createSlice} from '@reduxjs/toolkit'
import { defaultPractitioner } from '../../../types/Practitioner'

export const PractitionerSlice = createSlice({
    name: "Practitioner",
    initialState: defaultPractitioner,
    reducers:{
        setPractitionerData: (state,action) => {            
            return {...state,...action.payload};
        },
        setPractitionerAddress: (state,action) => {            
            state.address = action.payload
        },
        clearPractitionerData: state => {
            return {...defaultPractitioner}
        }

    }
})

export const {setPractitionerData,setPractitionerAddress,clearPractitionerData} = PractitionerSlice.actions;

export default PractitionerSlice.reducer;