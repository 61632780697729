import {createSlice} from '@reduxjs/toolkit'
import { defaultClientReportedCurrentFunction } from '../../../types/ClientReportedCurrentFunction'

export const ClientReportedCurrentFunctionSlice = createSlice({
    name: "ClientReportedCurrentFunction",
    initialState: defaultClientReportedCurrentFunction,
    reducers:{
        setClientReportedCurrentFunction: (state,action) => {
            return {...state,...action.payload};            
        },
        clearClientReportedCurrentFunction: state => {
            return {...defaultClientReportedCurrentFunction}
        }
    }
})

export const {setClientReportedCurrentFunction,clearClientReportedCurrentFunction} = ClientReportedCurrentFunctionSlice.actions;

export default ClientReportedCurrentFunctionSlice.reducer;