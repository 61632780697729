import React from "react";
import { Spinner } from "react-bootstrap";
import "./PageSpinner.css";

interface PageSpinnerProps {
  id?: string;
  ["data-testid"]?: string;
}

const defaultProps: PageSpinnerProps = {
  id: "pageLoadingSpinner",
  "data-testid": "pageLoadingSpinner",
};

const PageSpinner: React.FC<PageSpinnerProps> = (props) => (
  <Spinner {...props} animation="border" role="status" className="pageSpinner">
    <span className="sr-only">Loading...</span>
  </Spinner>
);

PageSpinner.defaultProps = defaultProps;

export default PageSpinner;
