import { Address } from "./Address";

export interface PractitionerProfile {
  organizationId: string;
  sgiPayeeNumber: string;
  practitionerFirstName: string;
  practitionerLastName: string;
  title: string;
  practiceName: string;
  practiceAddress: Address;
  businessPhoneNumber: string;
  faxNumber: string;
  businessEmailAddress: string;
  practitionerName: string;
  practiceCity: string;
}

export const defaultPractitionerProfile: PractitionerProfile = {
  organizationId: "",
  sgiPayeeNumber: "",
  practitionerFirstName: "",
  practitionerLastName: "",
  title: "",
  practiceName: "",
  practiceAddress: {
    street: "",
    city: "",
    postalCode: "",
    province: "SK",
    country: "CA",
  },
  businessPhoneNumber: "",
  faxNumber: "",
  businessEmailAddress: "",
  practitionerName: "",
  practiceCity: "",
};

export const defaultPractitionerProfileList: PractitionerProfile[] = [];
