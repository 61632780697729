export const maxFileSizeBytes = 10000000;
export const maxFileSizeMB = 10;
export const allowedFileTypes = [
  ".pdf",
  ".doc",
  ".xls",
  ".jpg",
  ".jpeg",
  ".bmp",
  ".gif",
  ".tiff",
  ".rmi",
  ".mid",
  ".wpg",
  ".wpd",
  ".rtf",
  ".ppt",
  ".wav",
  ".txt",
  ".docx",
  ".xlsx",
  ".pptx",
  ".png",
];
