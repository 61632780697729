import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import { getBaseApiUrl } from "./apiHelper";
import { getAccessToken } from "../utils/auth/authApiUtils";

export async function getFormSummary(
  formId: string,
  claimNumber: string,
  organizationId: string
) {
  const baseUrl =
    getBaseApiUrl() +
    `v1/securedocui/formSummary/GetFormSummary?formId=` +
    formId +
    `&claimNumber=` +
    claimNumber +
    `&organizationId=` +
    organizationId;
  
  const token = await getAccessToken();

  return axios
    .get(baseUrl, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/pdf",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}