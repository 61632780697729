import { useState } from 'react'
import { Form, Col, Row } from "react-bootstrap";
import { setCustomerData } from "../../redux/Customer/CustomerSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Gender, GenderList } from "../../../types/Gender";
import {
  convertDateToStringFormat,
  formatHealthCardNumberForStorage,
} from "../../../utils/Formaters/DisplayFormatter";
import { HealthCardInput } from "../../../utils/Formaters/InputMasks";
import FormText from "../FormElements/FormText";
import FormSelect from "../FormElements/FormSelect";
import * as messages from "../../../constants/Messages";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";
interface Props {}

function CustomerInformation(props: Props) {
  const dispatch = useAppDispatch();
  const reduxCustomer = useAppSelector((state) => state.customer);

  function handleChange(event: any) {
    const { name, value } = event.target;
    var storageValue = value;
    if (name === "healthCardNumber") {
      storageValue = formatHealthCardNumberForStorage(value);
    }
    dispatch(setCustomerData({ ...reduxCustomer, [name]: storageValue }));
  }

  return (
    <Form.Group id="formCustomerInformation">
      <Row>
        <Col>
          <div className="formSectionHeader">
            <h6>Customer Information</h6>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="maxWidth300">
          <FormText
            type="text"
            testId="formClaimNumber"
            field="claimNumber"
            label="Claim Number"
            value={reduxCustomer.claimNumber}
            onChange={handleChange}
            required
            pattern="^(?=.*[1-9])[0-9]{1,9}$"
            invalidMessage={
              reduxCustomer.claimNumber.length < 1 ||
              reduxCustomer.claimNumber.length > 9
                ? messages.MUST_BE_BETWEEN_1_TO_9_CHARACTERS
                : messages.NUMBERS_ONLY_NOTZERO
            }
          />
        </Col>
        <Col className="maxWidth300">
          <CustomDatePicker
            testId="formCustomerDateOfAccident"
            field="dateOfAccident"
            label="Date of Accident"
            value={reduxCustomer.dateOfAccident ?? undefined}
            onChange={handleChange}
            required
            max={new Date()}
            invalidMessage={messages.MUST_BE_VALID_DATE}
          />
        </Col>
      </Row>
      <Row>
        <Col className="maxWidth300">
          <FormText
            required
            label="First Name"
            testId={"formCustomerFirstName"}
            field="firstName"
            pattern="^[a-zA-z \-']*$"
            invalidMessage={messages.NAME_CHARACTERS_ONLY}
            value={reduxCustomer.firstName}
            onChange={handleChange}
          />
        </Col>
        <Col className="maxWidth300">
          <FormText
            required
            label="Last Name"
            testId={"formCustomerLastName"}
            field="lastName"
            pattern="^[a-zA-z \-']*$"
            invalidMessage={messages.NAME_CHARACTERS_ONLY}
            value={reduxCustomer.lastName}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col className="maxWidth300">
          <CustomDatePicker
            testId="formCustomerDateOfBirth"
            field="dateOfBirth"
            label="Date of Birth"
            value={reduxCustomer.dateOfBirth ?? undefined}
            onChange={handleChange}
            required
            max={new Date()}
            invalidMessage={messages.MUST_BE_VALID_DATE}
          />
        </Col>

        <Col className="maxWidth300">
          <FormSelect
            testId="formCustomerGender"
            field="gender"
            label="Gender"
            value={reduxCustomer.gender}
            onChange={handleChange}
            data={GenderList}
            mapping={(g: Gender) => {
              return { value: g.value, text: g.description };
            }}
            defaultOption={{ value: "", text: "Select Gender" }}
            required
          />
        </Col>
        <Col className="maxWidth300">
          <HealthCardInput
            type="text"
            testId="formHealthCardNumber"
            id="healthCardNumber"
            name="healthCardNumber"
            label="Health Card Number"
            value={reduxCustomer.healthCardNumber}
            onChange={handleChange}
            pattern="^[0-9]{3} [0-9]{3} [0-9]{3}$"
            invalidMessage={
              reduxCustomer.healthCardNumber.length < 9 ||
              reduxCustomer.healthCardNumber.length > 9
                ? messages.MUST_BE_9_CHARACTERS
                : messages.NUMBERS_ONLY
            }
          />
        </Col>
      </Row>
    </Form.Group>
  );
}

export default CustomerInformation;
