import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { getFormDownloads } from "../../../../api/AuditApi";
import { SortOrder } from "react-bootstrap-table-next";
import { defaultAuditList } from "../../../../types/Audit";
import { auditListTransformer } from "../../../../utils/Transformers/AuditTransformer";
import { DateRangePicker, FocusedInputShape } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { Moment } from "moment";
import { Col, Form, Row } from 'react-bootstrap';
import { Audit } from "../../../../types/Audit";
import "./AuditList.css";

const AuditList = () => {
  const [auditRecords, setAuditRecords] = useState<Audit[]>();

  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null
  );

  const ipAddressFormatter = (cell: string, row: any, rowIndex: any) => {
    if (cell === "::1") return "127.0.0.1";

    let ip = cell.replace("::ffff:", "");

    return ip;
  };

  const claimFormatter = (cell: string, row: any, rowIndex: any) => {
    const params = cell.split(",");
    const claimItem = params.find((x) => x.startsWith("claimNumber="));

    if (claimItem === undefined) return "";

    return claimItem.split("=")[1].replace(/\"/gi, "");
  };

  const timestampFormatter = (cell: string, row: any, rowIndex: any) => {
    const date = new Date(cell);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const columns = [
    {
      dataField: "id",
      text: "Id",
      hidden: true,
      searchable: false,
    },
    {
      dataField: "userEmail",
      text: "User",
      sort: true,
    },
    {
      dataField: "ipAddress",
      text: "IP Address",
      sort: true,
      formatter: ipAddressFormatter,
    },
    {
      dataField: "auditTimestamp",
      text: "Timestamp",
      sort: true,
      formatter: timestampFormatter,
    },
    {
      dataField: "requestBody",
      text: "Claim Number",
      formatter: claimFormatter,
    },
  ];

  const defaultSort: [{ dataField: any; order: SortOrder }] = [
    {
      dataField: "auditTimestamp",
      order: "desc",
    },
  ];

  const onDateFilterChange = async (arg: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    setStartDate(arg.startDate);
    setEndDate(arg.endDate);
    
    getAudit(arg.startDate?.format(), arg.endDate?.format());
  };

  const getAudit = async (startDate: string | undefined, endDate: string | undefined) => {
    const downloads = await getFormDownloads(startDate, endDate);

    debugger;
    const mappedData = auditListTransformer(downloads.data);
    setAuditRecords(mappedData);
  }

  const onFocusChange = (focusedInputChanged: FocusedInputShape | null) => {
    setFocusedInput(focusedInputChanged);
  };

  return (
    <Container className="AuditList">
      <div className="tableHeader">Audit List</div>
      
      <Row style={{marginBottom:"20px", paddingRight: "calc(var(--bs-gutter-x) * .5)",
        paddingLeft: "calc(var(--bs-gutter-x) * .5)" }}>
        <Col xl={6} md={6} className="px-0">      
          <Form.Label htmlFor="customerNumber">Date Range</Form.Label>
          <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                startDateId="startDate"
                endDateId="endDate"
                isOutsideRange={() => false}
                onDatesChange={onDateFilterChange}
                onFocusChange={onFocusChange}
                focusedInput={focusedInput}
              />
        </Col>
      </Row>
      <Row>
        <CustomTable
          noDataIndication="There are no entries to display."
          defaultSorted={defaultSort}
          defaultData={defaultAuditList}
          columns={columns}
          data={auditRecords}
          setData={setAuditRecords}
          dataGetter={getFormDownloads}
          dataMapper={auditListTransformer}
          keyField="id"
        />
      </Row>
    </Container>
  );
};

export default AuditList;
