import { useState, useEffect } from "react";
import { Form, Button, Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import { PageSpinner, InlineSpinner } from "../../components/Spinners/Spinners";
import CustomerInformation from "../../components/CustomerInformation/CustomerInformation";
import FormHeader from "../../components/FormHeader/FormHeader";
import PractitionerInformation from "../../components/PractitionerInformation/PractitionerInformation";
import ReasonForDischarge from "../../components/ReasonForDischarge/ReasonForDischarge";
import OutcomeMeasureUsed from "../../components/OutcomeMeasureUsed/OutcomeMeasureUsed";
import OutcomeCode from "../../components/OutcomeCode/OutcomeCode";
import ProgramResults from "../../components/ProgramResults/ProgramResults";
import SpecialRestrictionsReturnToNormalActivity from "../../components/SpecialRestrictionsReturnToNormalActivity/SpecialRestrictionsReturnToNormalActivity";
import IssuesReturnToNormalActivity from "../../components/IssuesReturnToNormalActivity/IssuesReturnToNormalActivity";
import FurtherTreatmentComments from "../../components/FurtherTreatmentComments/FurtherTreatmentComments";
import InformationAccuracyDisclaimer from "../../components/InformationAccuracyDisclaimer/InformationAccuracyDisclaimer";

import { 
  clearCustomerData,
  setCustomerData
} from "../../redux/Customer/CustomerSlice";
import { 
  clearPractitionerData,
  setPractitionerData
} from "../../redux/Practitioner/PractitionerSlice";
import { 
  clearFormHeader,
  setFormHeader,
} from "../../redux/FormHeader/FormHeaderSlice";
import { 
  clearReasonForDischarge, 
  setReasonForDischarge
} from "../../redux/ReasonForDischarge/ReasonForDischargeSlice";
import { 
  clearOutcomeMeasureUsed,
  setOutcomeMeasureUsed
} from "../../redux/OutcomeMeasureUsed/OutcomeMeasureUsedSlice";
import { 
  clearOutcomeCode,
  setOutcomeCode
} from "../../redux/OutcomeCode/OutcomeCodeSlice";
import { 
  clearProgramResults,
  setProgramResults
} from "../../redux/ProgramResults/ProgramResultsSlice";
import { 
  clearSpecialRestrictionsReturnToNormalActivity,
  setSpecialRestrictionsReturnToNormalActivity
} from "../../redux/SpecialRestrictionsReturnToNormalActivity/SpecialRestrictionsReturnToNormalActivitySlice";
import { 
  clearIssuesReturnToNormalActivity,
  setIssuesReturnToNormalActivity
} from "../../redux/IssuesReturnToNormalActivity/IssuesReturnToNormalActivitySlice";
import { 
  clearFurtherTreatmentComments,
  setFurtherTreatmentComments
} from "../../redux/FurtherTreatmentComments/FurtherTreatmentCommentsSlice";
import { setToastMessages } from "../../redux/ToastMessages/ToastMessagesSlice";
import { 
  saveChiroDischargeForm, 
  submitChiroDischargeForm,
  getDischargeForm
} from "../../../api/ChiroDischargeFormApi";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { appInsights } from "../../../AppInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { ChiroDischarge } from "../../../types/Forms";

import FormCheck from "../../components/FormElements/FormCheck";

import { DownloadFile } from "../../../../src/utils/Download/DownloadFile";
import * as messages from "../../../constants/Messages";
import "./ChiroDischargeForm.css";
import { ToastType } from "../../../types/ToastMessages";
import FloatSaveButton from "../../components/FloatSaveButton/FloatSaveButton";
import FormFooter  from "../../components/FormFooter/FormFooter";
import { setFormFooter, clearFormFooter } from "../../redux/FormFooter/FormFooterSlice";

interface Props {
  organizationId?: string,
	claimNumber?: string,
	formId?: string
}

function ChiroDischargeForm(props: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [validated, setValidated] = useState(false);
  const [downloadAfterSub, setDownloadAfterSub] = useState(false);
  const dispatch = useAppDispatch();
  const reduxToastMessages = useAppSelector((state) => state.toastMessages);
  const reduxLogin = useAppSelector((state) => state.login);
  const history = useHistory();
	const [isSaving, setIsSaving] = useState(false);  
	const [formId, setFormId] = useState<string | undefined>(props.formId);
  const flags = useAppSelector(state => state.flags);

  const dischargeData: ChiroDischarge = {
    formHeader: useAppSelector((state) => state.formHeader),
    customer: useAppSelector((state) => state.customer),
    practitioner: useAppSelector((state) => state.practitioner),
    reasonForDischarge: useAppSelector((state) => state.reasonForDischarge),
    outcomeMeasureUsed: useAppSelector((state) => state.outcomeMeasureUsed),
    outcome: useAppSelector((state) => state.outcomeCode),
    programResults: useAppSelector((state) => state.programResults),
    returnToNormalActivityRestrictions: useAppSelector(
      (state) => state.specialRestrictionsReturnToNormalActivity
    ),
    returnToNormalActivityIssues: useAppSelector(
      (state) => state.issuesReturnToNormalActivity
    ),
    furtherTreatment: useAppSelector((state) => state.furtherTreatmentComments),
    userId: reduxLogin.userId,
    organizationId: reduxLogin.organizationId,
    formId: formId,
    formFooter: useAppSelector(state => state.formFooter)
  };

  useEffect(() => {
    if (props.organizationId && props.claimNumber && props.formId) {
      
      const getForm = async () => {
        
        try {
          
          const form = await getDischargeForm(
            props.formId!,
            props.organizationId!,
            props.claimNumber!
          );

          setData(form.data);
          setFormId(form.data.id);
        } catch (e) {
          if (appInsights !== undefined) {
            appInsights.trackTrace({
              message: "Failed to load form",
              severityLevel: SeverityLevel.Error,
            });
          }


          dispatch(
            setToastMessages({
              toastMessages: [
                ...reduxToastMessages.toastMessages,
                {
                  toastType: ToastType.Error,
                  message: "Failed to load the Form",
                  title: "Chiro Discharge Form",
                },
              ],
            })
          );
        } 
        finally {
          setIsPageLoading(false);
        }
      };

      getForm();
    } else {
      resetFormComponentState();
      setIsPageLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSave = async () => {
    setIsSaving(true);

    try {
      const response = await saveChiroDischargeForm(dischargeData);

      if(response.Errors !== undefined)
      {
        dispatch(
          setToastMessages({
            toastMessages: [
              ...reduxToastMessages.toastMessages,
              {
                toastType: ToastType.Error,
                message: response.Errors.join('\n'),
                title: "Discharge Form",
              },
            ],
          })
        );
      }
      else{
        setFormId(response.data.id);
        if (appInsights !== undefined) {
          appInsights.trackEvent({
            name: "Discharge Form saved.",
          });
        }

        dispatch(
          setToastMessages({
            toastMessages: [
              ...reduxToastMessages.toastMessages,
              {
                toastType: ToastType.Info,
                message: "Form successfully saved.",
                title: "Chiro Discharge Form",
              },
            ],
          })
        );
      }
      // dispatch the whole object to redux
    }
    catch(e){      
      if (appInsights !== undefined) {
        appInsights.trackTrace({
          message: "Failed to save form",
          severityLevel: SeverityLevel.Error,
        });
      }
    }
    
    setIsSaving(false);
  }

  async function handleOnSubmit(event: any) {
    event.preventDefault();
    setIsSubmitting(true);
    const form = event.currentTarget;
    const isValid: boolean = form.checkValidity();

    setValidated(true);

    if (isValid) {
      let info: any[] = [];
      let errors: any[] = [];

      let claimNumber = "";
      let organizationId = "";
      let formId = "";
      let submittedDate: Date;
      let fileName = "";

      await submitChiroDischargeForm(dischargeData).then((apiResponse) => {
        if (apiResponse?.data) {
          formId = apiResponse?.data.id;
          claimNumber = apiResponse?.data.claimNumber;
          organizationId = apiResponse?.data.organizationId;
          submittedDate = apiResponse?.data.submittedDate;
          let fileSubmittedDate = new Date(submittedDate);
          fileName = `${dischargeData.customer.lastName} ${fileSubmittedDate
            .getDate()
            .toString()
            .padStart(2, "0")}/${(fileSubmittedDate.getMonth() + 1)
            .toString()
            .padStart(2, "0")}/${fileSubmittedDate
            .getFullYear()
            .toString()} Primary Chiropractor Report - Discharge.pdf`;

          info.push("Form submitted successfully");

          //TODO: Handle response
          if (appInsights !== undefined) {
            appInsights.trackEvent({
              name: "Discharge Form submitted.",
            });
          }
        } else {
          errors.push(
            "An error occurred submitting the form. Please try again."
          );

          //TODO: Handle empty response
          if (appInsights !== undefined) {
            appInsights.trackTrace({
              message: "Failed to submit Discharge Form",
              severityLevel: SeverityLevel.Error,
            });
          }
        }
      });

      if (errors.length === 0) {
        resetFormComponentState();
        history.push("/");

        if (downloadAfterSub) {
          let formToDownload = {
            formType: "ChiroDischarge",
            claimNumber: claimNumber,
            organizationId: organizationId,
            formId: formId,
            fileName: fileName,
          };

          let result = await DownloadFile(formToDownload);
          if (result.toLowerCase() === "success") {
            info.push(messages.DOWNLOAD_SUCCESS);
          } else {
            errors.push(messages.DOWNLOAD_FAILED);
          }
        }
      }

      if (info.length > 0) {
        dispatch(
          setToastMessages({
            toastMessages: [
              ...reduxToastMessages.toastMessages,
              {
                toastType: ToastType.Info,
                message: info.toString(),
                title: "Discharge Form",
              },
            ],
          })
        );
      }

      if (errors.length > 0) {
        dispatch(
          setToastMessages({
            toastMessages: [
              ...reduxToastMessages.toastMessages,
              {
                toastType: ToastType.Error,
                message: errors.toString(),
                title: "Discharge Form",
              },
            ],
          })
        );
      }

      setValidated(false);
    } else {
      setFocusToFirstInvalidElement();
    }
    setIsSubmitting(false);
  }

  function setFocusToFirstInvalidElement() {
    let errorElements = document.querySelectorAll(
      ".form-control:invalid, .form-check-input:invalid"
    );
    if (errorElements !== undefined && errorElements.length > 0) {
      (errorElements[0] as HTMLInputElement).focus();
    }
  }

  function handleChangeDownloadAfterSub(event: any) {
    const { checked } = event.target;
    setDownloadAfterSub(checked);
  }

  function resetFormComponentState() {
    dispatch(clearCustomerData());
    dispatch(clearFormHeader());
    dispatch(clearReasonForDischarge());
    dispatch(clearOutcomeMeasureUsed());
    dispatch(clearOutcomeCode());
    dispatch(clearProgramResults());
    dispatch(clearSpecialRestrictionsReturnToNormalActivity());
    dispatch(clearIssuesReturnToNormalActivity());
    dispatch(clearFurtherTreatmentComments());
    dispatch(clearFormFooter());

  }

  const setData = (data:any) => {
    dispatch(setCustomerData(data.customer));    
    dispatch(setPractitionerData(data.practitioner))    
    const pir = data.formHeader?.pir;
    dispatch(setFormHeader({ ...dischargeData.formHeader, PIR: pir}));
    dispatch(setReasonForDischarge(data.reasonForDischarge));
    dispatch(setOutcomeMeasureUsed(data.outcomeMeasureUsed));
    dispatch(setOutcomeCode(data.outcome));
    dispatch(setProgramResults(data.programResults));
    dispatch(setSpecialRestrictionsReturnToNormalActivity(data.returnToNormalActivityRestrictions));
    dispatch(setIssuesReturnToNormalActivity(data.returnToNormalActivityIssues));
    dispatch(setFurtherTreatmentComments(data.furtherTreatment));
    dispatch(setFormFooter(data.formFooter));
  }


  return isPageLoading ? (
    <PageSpinner />
  ) : (
    <Container className="dischargeFormContainer">
      <Row>
        <div className="formHeader">
          <h3>Primary Chiropractor Report - Discharge</h3>
        </div>
      </Row>
      <Form noValidate validated={validated} onSubmit={handleOnSubmit}>
        <FormHeader />
        <CustomerInformation />
        <PractitionerInformation />
        <ReasonForDischarge />
        <OutcomeMeasureUsed validated={validated} />
        <OutcomeCode />
        <ProgramResults />
        <SpecialRestrictionsReturnToNormalActivity />
        <IssuesReturnToNormalActivity />
        <FurtherTreatmentComments />
        <FormFooter/>        
        <InformationAccuracyDisclaimer />
        <Row>
          <Form.Group id="formEntrySubmitButton">
            <Col className="flexRight">
              <Button
                id="formEntrySubmitButton"
                data-testid="formEntrySubmitButton"
                disabled={isSubmitting}
                variant="outline-primary"
                type="submit"
                className="align-items-center"
              >
                {isSubmitting && (
                  <InlineSpinner
                    for="button"
                    id="submitSpinner"
                    data-testid="submitSpinner"
                  />
                )}
                {!isSubmitting && "Submit Form"}
              </Button>
              <FormCheck
                inline
                type="checkbox"
                label="Download form after submission"
                testId="downloadAfterSub"
                id="downloadAfterSub"
                checked={downloadAfterSub}
                onChange={handleChangeDownloadAfterSub}
                field="downloadAfterSub"
                className="checkboxBeforeButton"
              />
            </Col>
          </Form.Group>
        </Row>
      </Form>
      <FloatSaveButton handler={handleOnSave} loading={isSaving} enabled={flags.saveDraftForm}/>
    </Container>
  );
}

export default ChiroDischargeForm;
