import {createSlice} from '@reduxjs/toolkit'
import { defaultFormHeader } from '../../../types/FormHeader'

export const FormHeaderSlice = createSlice({
    name: "FormHeader",
    initialState: defaultFormHeader,
    reducers:{
        setFormHeader: (state,action) => {
            return {...state,...action.payload};            
        },
        clearFormHeader: state => {
            return {...defaultFormHeader}
        }
    }
})

export const {setFormHeader,clearFormHeader} = FormHeaderSlice.actions;

export default FormHeaderSlice.reducer;