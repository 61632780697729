import { createSlice } from "@reduxjs/toolkit";
import { defaultFlags } from "../../../types/Flags";

export const FlagsSlice = createSlice({
  name: "Flags",
  initialState: defaultFlags,
  reducers: {
    setFlags: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setFlags } = FlagsSlice.actions;

export default FlagsSlice.reducer;
