import { useState } from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import { SortOrder } from "react-bootstrap-table-next";
import {
  ScreenSize,
  useWindowDimensions,
} from "../../../../utils/Formaters/ScreenFormatter";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import CustomTable from "../../../components/CustomTable/CustomTable";
import TableButton from "../../../components/CustomTable/TableButton";
import { faPen } from "@fortawesome/pro-light-svg-icons";
import { defaultPractitionerProfileList } from "../../../../types/PractitionerProfile";
import { getOrganizationProfileList } from "../../../../api/PractitionerProfileApi";
import { practitionerProfileListTransformer } from "../../../../utils/Transformers/PractitionerProfileTransformer";
import { setPractitionerProfileData } from "../../../redux/PractitionerProfile/PractitionerProfileSlice";
import { useHistory } from "react-router-dom";

interface Props {}
function PractitionerProfileList(props: Props) {
  const [practitionerProfileList, setPractitionerProfileList] = useState(
    defaultPractitionerProfileList
  );
  const { screenSize } = useWindowDimensions();
  const history = useHistory();

  const dispatch = useAppDispatch();
  const reduxPermissions = useAppSelector((state) => state.permissions);

  const defaultSorted: [{ dataField: any; order: SortOrder }] = [
    {
      dataField: "PracticeName",
      order: "desc",
    },
  ];

  const openProfile = async (row: any) => {
    dispatch(setPractitionerProfileData(row));

    history.push("/Employee/Practitioners/Profile/Edit");
  };

  const columns = [
    {
      dataField: "practiceName",
      text: "Organization",
      sort: true,
      headerClasses: screenSize < ScreenSize.md ? "width30" : "",
    },
    {
      dataField: "practitionerName",
      text: "Practitioner",
      sort: true,
      headerClasses: screenSize < ScreenSize.md ? "width30" : "",
    },
    {
      dataField: "practiceCity",
      text: "City",
      sort: true,
      headerClasses: screenSize < ScreenSize.md ? "width30" : "",
    },
    {
      dataField: "businessPhoneNumber",
      text: "Phone Number",
      sort: true,
      headerClasses: screenSize < ScreenSize.md ? "width30" : "",
    },
    {
      dataField: "",
      text: "",
      isDummyField: true,
      formatter: buttonsFormatter,
      hidden: screenSize < ScreenSize.md,
      headerStyle: () => {
        return { width: "90px" };
      },
    },
  ];

  function noDataIndication() {
    return "There are no entries to display.";
  }
  function buttonsFormatter(cell: string, row: any, rowIndex: any) {
    return (
      <div className="buttonCellWrapper">
        {reduxPermissions.canSubmitOrgProfiles && (
          <TableButton
            icon={faPen}
            onClick={() => openProfile(row)}
            tooltipText="Edit Profile"
            id={`editProfileIcon${rowIndex}`}
            datatestid={`editProfileIcon${rowIndex}`}
          />
        )}
      </div>
    );
  }
  return (
    <Container className="CustomTableContainer">
      <div className="tableHeader">Practitioner Profiles</div>
      {reduxPermissions.canSubmitOrgProfiles && (
        <Row>
          <Col className="flexRight">
            <Button
              id="addPractitionerProfileButton"
              data-testid="addPractitionerProfileButton"
              variant="primary"
              onClick={() =>
                history.push("/Employee/Practitioners/Profile/Add")
              }
              style={{ marginLeft: "0.5rem" }}
            >
              Add Profile
            </Button>
          </Col>
        </Row>
      )}
      <CustomTable
        noDataIndication={noDataIndication}
        defaultSorted={defaultSorted}
        columns={columns}
        data={practitionerProfileList}
        setData={setPractitionerProfileList}
        defaultData={defaultPractitionerProfileList}
        dataGetter={getOrganizationProfileList}
        dataMapper={practitionerProfileListTransformer}
        keyField="organizationId"
      />
    </Container>
  );
}
export default PractitionerProfileList;
