import {createSlice} from '@reduxjs/toolkit'
import { defaultPermanentScarringOrDisfigurement } from '../../../types/PermanentScarringOrDisfigurement'

export const PermanentScarringOrDisfigurementSlice = createSlice({
    name: "PermanentScarringOrDisfigurement",
    initialState: defaultPermanentScarringOrDisfigurement,
    reducers:{
        setPermanentScarringOrDisfigurement: (state,action) => {
            return {...state,...action.payload};            
        },
        clearPermanentScarringOrDisfigurement: state => {
            return {...defaultPermanentScarringOrDisfigurement}
        }
    }
})

export const {setPermanentScarringOrDisfigurement,clearPermanentScarringOrDisfigurement} = PermanentScarringOrDisfigurementSlice.actions;

export default PermanentScarringOrDisfigurementSlice.reducer;