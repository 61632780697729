import BootstrapTable, { PaginationOptions } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  ScreenSize,
  useWindowDimensions,
} from "../../../utils/Formaters/ScreenFormatter";
import { ScrollArrow } from "./ScrollArrow";
import "./CustomTable.css";
import { useCallback, useEffect, useState } from "react";
import { PageSpinner } from "../../components/Spinners/Spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";

export default function CustomTable(props: any) {
  const { screenSize } = useWindowDimensions();
  const { SearchBar } = Search;
  const [loading, setLoading] = useState(true);

  const noDataIndication = props.noDataIndication;
  const defaultSorted = props.defaultSorted;
  const rowClasses = props.rowClasses;

  const loadData = useCallback(async () => {
    try {
      const result: any = await props.dataGetter();
      if (result.status === 200) {
        var mapData = props.dataMapper(result.data);
        props.setData(mapData);
      } else {
        props.setData(props.defaultData);
      }
    } catch (error) {
      props.setData(props.defaultData);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  function sortCaretFormatter(order: any, column: any) {
    if (!order)
      return (
        <span style={{ color: "dimgray", whiteSpace: "nowrap" }}>
          {" "}
          <FontAwesomeIcon icon={faSort} />
        </span>
      );
    else if (order === "asc")
      return (
        <span style={{ color: "black", whiteSpace: "nowrap" }}>
          {" "}
          <FontAwesomeIcon icon={faSortUp} />
        </span>
      );
    else if (order === "desc")
      return (
        <span style={{ color: "black", whiteSpace: "nowrap" }}>
          {" "}
          <FontAwesomeIcon icon={faSortDown} />
        </span>
      );
    return null;
  }

  const modifiedColumns = props.columns.map((item: any) => ({
    ...item,
    sortCaret: sortCaretFormatter,
  }));

  const expandRow = {
    ...props.expandRow,
    expandColumnRenderer: (row: any) => {
      if (row.expandable) {
        return (
          <>
            {row.expanded ? (
              <FontAwesomeIcon icon={faSortDown} />
            ) : (
              <FontAwesomeIcon
                icon={faSortUp}
                className="fa-rotate-90"
                style={{ color: "dimgray" }}
              />
            )}
          </>
        );
      }
      return "";
    },
    expandHeaderColumnRenderer: () => {
      return <></>;
    },
  };

  const paginationOptions: PaginationOptions = {
    showTotal: true,
    alwaysShowAllBtns: false,
    sizePerPage: 25,
    sizePerPageList: [10, 25, 100, 200],
    hidePageListOnlyOnePage: true,
  };

  const paginationOptionsSmall: PaginationOptions = {
    showTotal: false,
    alwaysShowAllBtns: false,
    sizePerPage: 25,
    hidePageListOnlyOnePage: true,
    hideSizePerPage: true,
  };

  return (
    <>
      <div className="CustomTableWrapper">
        <ToolkitProvider
          bootstrap4={true}
          keyField={props.keyField ? props.keyField : ""}
          data={props.data}
          columns={modifiedColumns}
          search={{
            searchFormatted: true,
          }}
        >
          {(tableProps) => (
            <>
              {loading ? (
                <PageSpinner />
              ) : (
                <>
                  {" "}
                  <div className="searchBar">
                    <SearchBar
                      {...tableProps.searchProps}
                      className="SearchText"
                    />
                  </div>
                  {screenSize >= ScreenSize.md && (
                    <BootstrapTable
                      {...tableProps.baseProps}
                      bordered={false}
                      striped={false}
                      hover={true}
                      noDataIndication={noDataIndication}
                      defaultSorted={defaultSorted}
                      condensed={true}
                      pagination={paginationFactory(paginationOptions)}
                      defaultSortDirection="asc"
                      rowClasses={rowClasses}
                      classes="cellStyle stickyHeader"
                      expandRow={expandRow}
                    ></BootstrapTable>
                  )}
                  {screenSize < ScreenSize.md && (
                    <BootstrapTable
                      {...tableProps.baseProps}
                      bordered={false}
                      striped={false}
                      hover={true}
                      noDataIndication={noDataIndication}
                      defaultSorted={defaultSorted}
                      condensed={true}
                      pagination={paginationFactory(paginationOptionsSmall)}
                      defaultSortDirection="asc"
                      rowClasses={rowClasses}
                      classes="cellStyle stickyHeader"
                      expandRow={expandRow}
                    ></BootstrapTable>
                  )}
                </>
              )}
            </>
          )}
        </ToolkitProvider>
      </div>
      <ScrollArrow />
    </>
  );
}
