import { useEffect, useState, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getPractitionerProfile } from "../../../api/PractitionerProfileApi";
import { Practitioner, defaultPractitioner } from "../../../types/Practitioner";
import { setPractitionerData } from "../../redux/Practitioner/PractitionerSlice";
import { practitionerProfileTransformer } from "../../../utils/Transformers/PractitionerProfileTransformer";
import { PractitionerProfile } from "../../../types/PractitionerProfile";

export function PractionerProfileLoader() {
  const orgId = useAppSelector((state) => state.login.organizationId);
  const flagPopulatePractitionerData = useAppSelector(
    (state) => state.flags.populatePractitionerDataFromOrgProfile
  );
  const profileData: Practitioner = useAppSelector(
    (state) => state.practitioner
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    const retrieveProfile = async () => {
      const result = await getPractitionerProfile(orgId);
      if (result?.data) {
        const practitioner: Practitioner = practitionerProfileTransformer(
          result.data
        );
        dispatch(setPractitionerData({ ...practitioner }));
      }
    };

    if (flagPopulatePractitionerData) {
      if (orgId == "00000000-0000-0000-0000-000000000000" || !orgId) {
        dispatch(setPractitionerData(defaultPractitioner));
        return;
      } else {
        retrieveProfile();
      }
    }
  }, [orgId, flagPopulatePractitionerData]);

  return <></>;
}

export default PractionerProfileLoader;
