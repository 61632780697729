import React from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import "./Header.css";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import { useDispatch } from "react-redux";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { logout } from "../../redux/Login/LoginSlice";

export function HeaderDropDownButton() {
  const history = useHistory();
  const login = useAppSelector((state) => state.login);
  const userPermissions = useAppSelector((state) => state.permissions);
  const dispatch = useDispatch();

  const { instance } = useMsal();

  const isAADAuthenticated = useIsAuthenticated();

  const handleSelect = (event: any) => {
    switch (event) {
      case "1":
        dispatch(logout());

        if (isAADAuthenticated) {
          instance.logout();
        }
        break;
      case "3":
        history.push("/Employee/FeedbackList");
        break;
      case "5":
        history.push("/Employee/Practitioners/ProfileList");
        break;
      case "10":
        history.push("/Employee/TransferFileSummary");
        break;
      default:
        break;
    }
  };

  const canSeeFeedbackItem = () => {
    return userPermissions.canViewFeedback;
  };

  const canSeeTransferFileSummary = () => {
    return userPermissions.canViewTransferFileSummary;
  };

  const canSeePractitionerProfiles = () => {
    return userPermissions.canViewOrgProfiles;
  };

  return (
    <>
      <DropdownButton
        className="header-btn"
        data-testid="headerLoginDropDown"
        title={
          login.username.length > 15
            ? login.username.slice(0, 12) + "..."
            : login.username
        }
        variant="outline-primary"
        onSelect={handleSelect}
      >
        {canSeePractitionerProfiles() && (
          <Dropdown.Item
            eventKey="5"
            data-testid="EmployeePractitionerProfilesOption"
          >
            Practitioner Profiles
          </Dropdown.Item>
        )}
        {canSeeFeedbackItem() && (
          <Dropdown.Item eventKey="3" data-testid="EmployeeFeedbackListOption">
            Feedback List
          </Dropdown.Item>
        )}
        {canSeeTransferFileSummary() && (
          <Dropdown.Item eventKey="10" data-testid="TransferFileSummaryOption">
            File Transfer Summary
          </Dropdown.Item>
        )}
        <Dropdown.Item eventKey="1" data-testid="LogoutOption">
          Logout
        </Dropdown.Item>
      </DropdownButton>
    </>
  );
}
