import { ITransferFile } from "../../types/TransferFileTypes";

export function transferFileSummaryTransformer(data: any): ITransferFile[] {
  var returnData: ITransferFile[] = [];

  data.forEach((item: any) => {
    var fileSummaryObject: ITransferFile = {
      authUserId: item.authUserId,
      organizationId: item.organizationId,
      organizationName: item.organizationName,
      sgiPayeeNumber: item.sgiPayeeNumber,
      claimNumber: item.claimNumber,
      fileName: item.fileName,
      formId: item.formId,
      attachmentId: item.attachmentId,
      formType: item.formType,
      contentType: item.contentType,
      errorHttpCode: item.errorHttpCode,
      errorDetails: item.errorDetails,
      details: item.details,
      status: item.status,
      submittedDate: item.submittedDate,
      submittedBy: item.submittedBy,
    };
    returnData.push(fileSummaryObject);
  });
  
  return returnData;
}
