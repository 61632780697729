import React from "react";
import { Form } from "react-bootstrap";
import FormLabel from "./FormLabel";

const MAX_CHARACTERS = 5000;

interface Props {
  testId?: string;
  className?: string;
  label?: string;
  field: string;
  value: any;
  placeholder?: string;
  required?: boolean;
  pattern?: string;
  invalidMessage?: string;
  onChange?: any;
  onBlur?: any;
  isValid?: boolean;
  disabled?: boolean;
  rows: number;
  feedbackLabel?: string;
  maxLength?: number;
}

function FormTextArea({
  testId,
  label = "",
  className = "",
  placeholder,
  field,
  required = false,
  pattern = ".*",
  invalidMessage = "",
  value = "",
  isValid = true,
  onChange,
  onBlur,
  disabled = false,
  rows = 1,
  feedbackLabel = label,
  maxLength = MAX_CHARACTERS,
}: Props) {
  return (
    <>
      {label && (
        <FormLabel
          testId={testId ? testId + "-label" : undefined}
          text={label}
          required={required}
          phoneNumber={field.toLowerCase().includes("phonenumber") ? value : ""}
        />
      )}
      <Form.Control
        as="textarea"
        data-testid={testId}
        className={className}
        name={field}
        id={field}
        value={value}
        onChange={onChange}
        onBlurCapture={onBlur}
        required={required}
        isInvalid={!isValid}
        disabled={disabled}
        rows={rows}
        maxLength={maxLength}
      />
      <Form.Control.Feedback
        type="invalid"
        data-testid={testId ? testId + "-error" : undefined}
      >
        {!disabled &&
          (feedbackLabel || invalidMessage) &&
          feedbackLabel +
            (value === "" && required ? " is required" : invalidMessage)}
      </Form.Control.Feedback>
    </>
  );
}

export default FormTextArea;
