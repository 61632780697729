import {createSlice} from '@reduxjs/toolkit'
import { defaultCustomer } from '../../../types/Customer'

export const CustomerSlice = createSlice({
    name: "Customer",
    initialState: defaultCustomer,
    reducers:{
        setCustomerData: (state,action) => {
            return {...state,...action.payload};            
        },
        clearCustomerData: state => {
            return {...defaultCustomer}
        }
    }
})

export const {setCustomerData,clearCustomerData} = CustomerSlice.actions;

export default CustomerSlice.reducer;