export interface ClientReportedCurrentFunction {
  functionLevel: string;
  currentFunctionWork: string;
  currentFunctionHomeYard: string;
  currentFunctionSelfCare: string;
  lessThanFullFunctionDescription: string;
}

export const defaultClientReportedCurrentFunction: ClientReportedCurrentFunction =
  {
    functionLevel: "",
    currentFunctionWork: "",
    currentFunctionHomeYard: "",
    currentFunctionSelfCare: "",
    lessThanFullFunctionDescription: "",
  };
