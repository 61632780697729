import { createSlice } from "@reduxjs/toolkit";
import { defaultUserPermissions } from "../../../types/UserPermissions";

export const PermissionsSlice = createSlice({
  name: "Permissions",
  initialState: defaultUserPermissions,
  reducers: {
    setUserPermissions: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearUserPermissions: (state) => {
      return { ...defaultUserPermissions };
    },
  },
});

export const { setUserPermissions, clearUserPermissions } =
  PermissionsSlice.actions;

export default PermissionsSlice.reducer;
