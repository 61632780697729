import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useAppDispatch } from "../../redux/hooks";
import { useHistory } from "react-router-dom";
import { logout } from "../../redux/Login/LoginSlice";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { ModalIdleTimeout } from "../Modals/ModalIdleTimeout";

/*
  'timeToPrompt' 
    - value in milliseconds before displaying prompt
  'timeToIdle' 
    - value in milliseconds for the prompt to be displayed before going idle
*/
interface TimerProps {
  timeToPrompt: number;
  timeToIdleTimeout: number;
  onAction?: any;
  onActive?: any;
  onIdle?: any;
  onPrompt?: any;
  startOnMount?: boolean;
  children: any;
}

const defaultRemainingTime = {
  seconds: "00",
  minutes: "00",
};

function IdleTimerComponent(props: TimerProps) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isAADAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  const [showModal, setShowModal] = useState(false);
  const [remainingTimeBeforeIdle, setRemainingTimeBeforeIdle] =
    useState(defaultRemainingTime);

  const onLogout = () => {
    dispatch(logout());
    if (isAADAuthenticated) {
      instance.logout();
    }
  };

  const onIdle = () => {
    if (process.env.NODE_ENV === "development") {
      console.log("last active: ", getLastActiveTime());
    }

    setShowModal(false);
    setRemainingTimeBeforeIdle(defaultRemainingTime);
    onLogout();
    history.push("/");
  };

  const onActive = () => {
    if (process.env.NODE_ENV === "development") {
      console.log("time remaining: ", getRemainingTime());
    }
    setShowModal(false);
  };

  const onPrompt = () => {
    if (process.env.NODE_ENV === "development") {
      console.log("Triggering onPrompt");
    }
    updateRemainingTime(props.timeToIdleTimeout); //set initial state to full timeout value
    setShowModal(true);
  };

  const onAction = () => {};

  const stayActive = () => {
    setShowModal(false);
    reset();
  };

  const { getRemainingTime, getLastActiveTime, isPrompted, reset } =
    useIdleTimer({
      timeout: props.timeToPrompt,
      promptTimeout: props.timeToIdleTimeout,
      onIdle: props.onIdle ?? onIdle,
      onPrompt: props.onPrompt ?? onPrompt,
      onActive: props.onActive ?? onActive,
      onAction: props.onAction ?? onAction,
      startOnMount: props.startOnMount ?? props.startOnMount,
      debounce: 500,
    });

  function updateRemainingTime(remainingTime: number) {
    const seconds = Math.floor((remainingTime / 1000) % 60);
    const minutes = Math.floor((remainingTime / 1000 / 60) % 60);
    setRemainingTimeBeforeIdle({
      minutes: minutes.toString().padStart(2, "0"),
      seconds: seconds.toString().padStart(2, "0"),
    });
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        updateRemainingTime(getRemainingTime());
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {props.children}

      <ModalIdleTimeout
        show={showModal}
        onHide={stayActive}
        onLogout={onIdle}
        onStayAlive={stayActive}
        timeBeforeLogout={remainingTimeBeforeIdle}
      />
    </>
  );
}

export const IdleTimer = IdleTimerComponent;
