import { createSlice } from "@reduxjs/toolkit";
import { defaultPractitionerProfile } from "../../../types/PractitionerProfile";

export const PractitionerProfileSlice = createSlice({
  name: "PractitionerProfile",
  initialState: defaultPractitionerProfile,
  reducers: {
    setPractitionerProfileData: (state, action) => {
      return { ...state, ...action.payload };
    },
    setPractitionerProfileAddress: (state, action) => {
      state.practiceAddress = action.payload;
    },
    clearPractitionerProfileData: (state) => {
      return { ...defaultPractitionerProfile };
    },
  },
});

export const {
  setPractitionerProfileData,
  setPractitionerProfileAddress,
  clearPractitionerProfileData,
} = PractitionerProfileSlice.actions;

export default PractitionerProfileSlice.reducer;
