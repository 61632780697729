export interface PractitionerReportedCurrentFunction {
  practitionerCurrentFunctionWork: string;
  workDutiesRestrictions: string;
  homeYardSelfCareRestrictions: string;
}

export const defaultPractitionerReportedCurrentFunction: PractitionerReportedCurrentFunction =
  {
    practitionerCurrentFunctionWork: "",
    workDutiesRestrictions: "",
    homeYardSelfCareRestrictions: "",
  };
