import { createSlice } from '@reduxjs/toolkit'
import { defaultFurtherTreatmentComments } from "../../../types/FurtherTreatmentComments";

export const FurtherTreatmentCommentsSlice = createSlice({
    name: "ProgramResults",
    initialState: defaultFurtherTreatmentComments,
    reducers: {
        setFurtherTreatmentComments: (state, action) => {
            return {...state, ...action.payload};
        },
        clearFurtherTreatmentComments: state => {
            return {...defaultFurtherTreatmentComments}
        }
    }
})

export const {setFurtherTreatmentComments, clearFurtherTreatmentComments} = FurtherTreatmentCommentsSlice.actions;

export default FurtherTreatmentCommentsSlice.reducer;