import { faDownload } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { Alert, Container, Row } from "react-bootstrap";
import { SortOrder } from "react-bootstrap-table-next";
import { getTransferSummaryByOrganization } from "../../../api/TransferSummaryApi";
import { defaultTransferFileSummary } from "../../../types/TransferFileTypes";
import { convertDateToDisplayFormatWithTime } from "../../../utils/Formaters/DisplayFormatter";
import {
  ScreenSize,
  useWindowDimensions,
} from "../../../utils/Formaters/ScreenFormatter";
import { transferFileSummaryTransformer } from "../../../utils/Transformers/TransferFileSummaryTransformer";
import CustomTable from "../../components/CustomTable/CustomTable";
import TableButton from "../../components/CustomTable/TableButton";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import * as messages from "../../../constants/Messages";
import { setToastMessages } from "../../redux/ToastMessages/ToastMessagesSlice";
import "./SubmissionHistory.css";
import { DownloadFile } from "../../../utils/Download/DownloadFile";
import { ToastType } from "../../../types/ToastMessages";

export default function SubmissionHistory() {
  const [transferFileSummary, setTransferFileSummary] = useState(
    defaultTransferFileSummary
  );
  const { screenSize } = useWindowDimensions();
  const dispatch = useAppDispatch();
  const reduxToastMessages = useAppSelector((state) => state.toastMessages);
  const userPermissions = useAppSelector((state) => state.permissions);

  function noDataIndication() {
    return "No files have been received by SGI in the last 90 days";
  }

  async function getTransferSummaryData() {
    return await getTransferSummaryByOrganization(reduxLogin.organizationId);
  }

  function buttonsFormatter(cell: string, row: any, rowIndex: any) {
    return (
      <div className="buttonCellWrapper">
        {(((row.formType === "ChiroIntake" ||
          row.formType === "ChiroProgress" ||
          row.formType === "ChiroDischarge") &&
          userPermissions.canViewForm) ||
          (row.formType === "Attachment" &&
            userPermissions.canViewAttachment)) &&
          !row?.errorDetails?.includes("virus") && (
            <>
              <TableButton
                icon={faDownload}
                onClick={() => downloadFile(row)}
                tooltipText="Download File"
                id={`downloadIcon${rowIndex}`}
                datatestid={`downloadIcon${rowIndex}`}
              />
            </>
          )}
      </div>
    );
  }

  const downloadFile = async (row: any) => {
    var result = await DownloadFile(row);

    if (result === "success") {
      dispatch(
        setToastMessages({
          toastMessages: [
            ...reduxToastMessages.toastMessages,
            {
              toastType: ToastType.Info,
              message: messages.DOWNLOAD_SUCCESS,
              title: "File Download",
            },
          ],
        })
      );
    } else {
      dispatch(
        setToastMessages({
          toastMessages: [
            ...reduxToastMessages.toastMessages,
            {
              toastType: ToastType.Error,
              message: messages.DOWNLOAD_FAILED,
              title: "File Download",
            },
          ],
        })
      );
    }
  };

  const reduxLogin = useAppSelector((state) => state.login);

  const defaultSorted: [{ dataField: any; order: SortOrder }] = [
    {
      dataField: "submittedDate",
      order: "desc",
    },
  ];

  const columns = [
    {
      dataField: "submittedDate",
      text: "Date Submitted",
      sort: true,
      formatter: (cellContent: any) => {
        return convertDateToDisplayFormatWithTime(cellContent);
      },
      hidden: screenSize < ScreenSize.md,
      headerStyle: () => {
        return { width: "160px" };
      },
    },
    {
      dataField: "claimNumber",
      text: "Claim Number",
      sort: true,
      headerStyle: () => {
        return { width: "80px" };
      },
    },
    {
      dataField: "sgiPayeeNumber",
      text: "Payee Number",
      sort: true,
      headerStyle: () => {
        return { width: "120px" };
      },
    },
    {
      dataField: "formType",
      text: "Type",
      sort: true,
      headerStyle: () => {
        return { width: "120px" };
      },
    },
    {
      dataField: "fileName",
      text: "File Name",
      sort: true,
      headerStyle: () => {
        return { width: "280px" };
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerStyle: () => {
        return { width: "140px" };
      },
    },
    {
      dataField: "",
      text: "",
      isDummyField: true,
      formatter: buttonsFormatter,
      hidden: screenSize < ScreenSize.md,
      headerStyle: () => {
        return { width: "90px" };
      },
    },
  ];

  return (
    <>
      <Container className="CustomTableContainer">
        <Row>
          <div className="formHeader">
            <h3>Document Submission History</h3>
          </div>
        </Row>
        {transferFileSummary.length > 0 && (
          <Row className="mb-2">
            <Alert key="transferSummaryInfo" variant="info">
              Files listed have been received by SGI in the last 90 days.
            </Alert>
          </Row>
        )}
        <CustomTable
          noDataIndication={noDataIndication}
          defaultSorted={defaultSorted}
          columns={columns}
          data={transferFileSummary}
          setData={setTransferFileSummary}
          defaultData={defaultTransferFileSummary}
          dataGetter={getTransferSummaryData}
          dataMapper={transferFileSummaryTransformer}
          keyField="submittedDate"
        />
      </Container>
    </>
  );
}
