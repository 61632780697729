import React from "react";
import { convertDateToDisplayFormatWithTime } from "../../../utils/Formaters/DisplayFormatter";

export default function FeedbackListLineItem(props: any) {
  return (
    <tr data-testid="FeedbackListLineItem" className="table-default">
      <td data-testid="test-feedbackType">{props.lineItem.feedbackType}</td>
      <td>
        <div data-testid="test-content" className="feedbackContent">
          {props.lineItem.content}
        </div>
      </td>
      <td data-testid="test-name">{props.lineItem.name}</td>
      <td data-testid="test-email">{props.lineItem.email}</td>
      <td data-testid="test-submittedDate">
        {convertDateToDisplayFormatWithTime(props.lineItem.submittedDate)}
      </td>
    </tr>
  );
}
