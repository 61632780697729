import { Form, Col, Row } from "react-bootstrap";
import { setRemarks } from "../../redux/Remarks/RemarksSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import FormTextArea from "../FormElements/FormTextArea";
interface Props {}

function Remarks(props: Props) {
  const dispatch = useAppDispatch();
  const reduxRemarks = useAppSelector((state) => state.remarks);

  function handleChange(event: any) {
    const { name, value } = event.target;
    dispatch(
      setRemarks({
        ...reduxRemarks,
        [name]: value,
      })
    );
  }

  return (
    <Form.Group id="formRemarks">
      <Row>
        <Col>
          <div className="formSectionHeader">
            <h6>Remarks</h6>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormTextArea
            label=""
            testId={"formRemarks"}
            field="remark"
            className="formTextArea"
            value={reduxRemarks.remark}
            onChange={handleChange}
            rows={4}
            feedbackLabel="Remarks"
          />
        </Col>
      </Row>
    </Form.Group>
  );
}

export default Remarks;
