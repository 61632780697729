import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import { getAccessToken } from "../utils/auth/authApiUtils";
import { getBaseApiUrl } from "./apiHelper";

export async function updateOrganizationDetails(orgId: string) {
  const baseUrl =
    getBaseApiUrl() +
    `v1/securedocui/secdocorganization/InsertUpdateOrganization`;
  const token = await getAccessToken();

  const organizationDetails = {
    organizationId: orgId,
    name: "temp workaround", //Org details are retrieved by the BFF, temporary workaround to bypass the required field validation
    sgiPayeeNumber: "",
  };

  return axios
    .post(baseUrl, organizationDetails, {
      headers: {
        Accept: "application/json",
        ContentType: "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function getOrganizationList() {
  const baseUrl = getBaseApiUrl();
  const token = await getAccessToken();
  return axios
    .get(baseUrl + "v1/securedocui/OrgProfile/GetOrganizationList", {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}
