import { createSlice} from '@reduxjs/toolkit'
import { OutcomeCodes } from '../../../types/ListTypes';
import {defaultOutcomeCode} from "../../../types/OutcomeCode"

export const OutcomeCodeSlice = createSlice({
    name:"OutcomeCode",
    initialState: defaultOutcomeCode,
    reducers:{
        setOutcomeCode: (state,action) => {
            var code = action.payload?.outcomeCode ?? state.code;
            var outcomeDesc = OutcomeCodes.find(o => o.value === code)?.description ?? ""
            return {...state,...action.payload, outcomeDescription: outcomeDesc};            
        },
        clearOutcomeCode: state => {
            return {...defaultOutcomeCode}
        }
    }
})

export const {setOutcomeCode, clearOutcomeCode} = OutcomeCodeSlice.actions;
export default OutcomeCodeSlice.reducer;