import { Route } from "react-router-dom";
import Authorized from "../components/Common/Authorized";

export function PrivateRoute(props: any) {
  return (
    <Authorized
      requireAll={props?.requireAll}
      requiredPermissions={props.requiredPermissions}
      redirectOnUnAuthorized={props.redirectOnUnAuthorized}
    >
      <Route {...props} />
    </Authorized>
  );
}

export default PrivateRoute;
