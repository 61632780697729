import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import { getBaseApiUrl } from "./apiHelper";
import { getAccessToken } from "../utils/auth/authApiUtils";

const transferStatusPath = `v1/securedocui/legacyTransferStatus`;

export async function getTransferSummaryByOrganization(organizationId: string) {
  const baseUrl = getBaseApiUrl();
  const token = await getAccessToken();

  return axios
    .get(`${baseUrl}${transferStatusPath}/GetTransferSummary/` + organizationId, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}
