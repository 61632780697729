import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useAppSelector } from "../../../redux/hooks";
import { getFeedback } from "../../../../api/apiFeedback";
import { defaultFeedbackList } from "../../../../types/Feedback";
import { feedbackListTransformer } from "../../../../utils/Transformers/FeedbackListTransformer";
import {
  sortByDate,
  sortByStringThenDate,
} from "../../../../utils/Sorters/SortingUtils";
import FeedbackListLineItem from "../../../components/FeedbackListLineItem/FeedbackListLineItem";
import { PageSpinner } from "../../../components/Spinners/Spinners";
import { CSVLink } from "react-csv";
import { getNewSortOrderForFeedbackList } from "../../../../utils/Sorters/FeedbackSorter";
import "./FeedbackList.css";
import { convertDateToDisplayFormatWithTime } from "../../../../utils/Formaters/DisplayFormatter";

export default function FeedbackList() {
  const login = useAppSelector((state) => state.login);

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [feedbackList, setFeedbackList] = useState(defaultFeedbackList);
  const [currentSortColumn, setCurrentSortColumn] = useState("");
  const [sortClassName, setSortClassName] = useState("th-sort-asc");

  const loadFeedbacks = useCallback(async () => {
    var loadSortColumn = "submittedDate";
    var loadSortClass = "dsc";

    if (loadSortColumn !== "") {
      await getFeedback()
        .then((result) => {
          if (result !== undefined) {
            if (Array.isArray(result?.data)) {
              var feedbackData = feedbackListTransformer(result.data);

              if (loadSortColumn === "submittedDate") {
                feedbackData.sort(sortByDate(loadSortColumn, loadSortClass));
              } else {
                feedbackData.sort(
                  sortByStringThenDate(loadSortColumn, loadSortClass)
                );
              }
              setCurrentSortColumn(loadSortColumn);
              setSortClassName("th-sort-" + loadSortClass);

              setFeedbackList(feedbackData);
            } else {
              setFeedbackList(defaultFeedbackList);
            }
          } else {
            setFeedbackList(defaultFeedbackList);
          }

          setIsPageLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    if (login.username !== "") {
      loadFeedbacks();
    } else {
      setFeedbackList(defaultFeedbackList);
    }
  }, [loadFeedbacks, login]);

  function sortFeedbackList(sortByColumn: any) {
    var { newArray, sortAttribute } = getNewSortOrderForFeedbackList(
      sortByColumn,
      sortClassName,
      feedbackList,
      currentSortColumn,
      setSortClassName
    );
    setFeedbackList(newArray);
    setCurrentSortColumn(sortAttribute);
  }

  function handleTableHeaderClick(e: any) {
    sortFeedbackList(e.target.id);
  }

  return (
    <>
      {isPageLoading ? (
        <PageSpinner />
      ) : (
        <Container className="feedbackListContainer">
          <Row>
            <Col lg="10">
              <div className="Title">
                <h3>Feedback List </h3>
              </div>
            </Col>
            <Col lg="2">
              <CSVLink
                data={feedbackList.map((line: any, key: any) => {
                  line.submittedDate = convertDateToDisplayFormatWithTime(
                    line.submittedDate
                  );
                  return line;
                })}
                filename={"feedback.csv"}
                uFEFF={false}
                className="btn btn-outline-primary"
                target="_blank"
                data-testid="ExportCSV"
              >
                Export CSV
              </CSVLink>
            </Col>
          </Row>
          <Table className="table-sortable" striped bordered hover>
            <thead data-testid="FeedbackListLineHeader">
              <tr>
                <th
                  className={
                    "feedbackType" === currentSortColumn
                      ? sortClassName
                      : "th-sort-none"
                  }
                  scope="col"
                  id="sort-feedbackType"
                  data-testid="test-feedbackTypeHeader"
                  onClick={handleTableHeaderClick}
                >
                  Feedback Type
                </th>
                <th
                  className={
                    "content" === currentSortColumn
                      ? sortClassName
                      : "th-sort-none"
                  }
                  scope="col"
                  id="sort-content"
                  data-testid="test-contentHeader"
                  onClick={handleTableHeaderClick}
                >
                  Content
                </th>
                <th
                  className={
                    "name" === currentSortColumn
                      ? sortClassName
                      : "th-sort-none"
                  }
                  scope="col"
                  id="sort-name"
                  data-testid="test-nameHeader"
                  onClick={handleTableHeaderClick}
                >
                  Name
                </th>
                <th
                  className={
                    "email" === currentSortColumn
                      ? sortClassName
                      : "th-sort-none"
                  }
                  scope="col"
                  id="sort-email"
                  data-testid="test-emailHeader"
                  onClick={handleTableHeaderClick}
                >
                  Email
                </th>
                <th
                  className={
                    "submittedDate" === currentSortColumn
                      ? sortClassName
                      : "th-sort-none"
                  }
                  scope="col"
                  id="sort-submittedDate"
                  data-testid="test-submittedDateHeader"
                  onClick={handleTableHeaderClick}
                >
                  Date Submitted
                </th>
              </tr>
            </thead>
            <tbody>
              {feedbackList.map((line: any, key: any) => {
                return <FeedbackListLineItem lineItem={line} key={key} />;
              })}
            </tbody>
          </Table>
        </Container>
      )}
    </>
  );
}
