import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { ModalFeedback } from "../Modals/ModalFeedback";
import { useAppSelector } from "../../redux/hooks";
import "./Footer.css";

function isAuthenticated(login: any): boolean {
  return login.signedIn;
}

export function Footer() {
  const [modalShow, setModalShow] = useState(false);
  const reduxLogin = useAppSelector((state) => state.login);
  const userPermissions = useAppSelector((state) => state.permissions);
  const [loggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const isFeedbackEnabled = false;

  useEffect(() => {
    async function updateLoginStatus() {
      setEmail(reduxLogin.email);
      setName(reduxLogin.fullName);
      if (isAuthenticated(reduxLogin) === true) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    }
    updateLoginStatus();
  }, [reduxLogin, reduxLogin.username]);

  const canViewFeedbackButton = () => {
    return loggedIn && userPermissions.canSubmitFeedback;
  };

  return (
    <>
      <Container id="footer-container">
        <div className="footer-copyright">© 2021 - SGI CANADA</div>
          {isFeedbackEnabled && canViewFeedbackButton() && (
            <div
              className="footer-feedback"
              data-testid="footer-feedback"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                setModalShow(true);
              }}
            >
              Feedback
            </div>
          )}
          {isFeedbackEnabled && loggedIn && (
            <ModalFeedback
              show={modalShow}
              onHide={setModalShow}
              email={email}
              name={name}
              userId={reduxLogin.userId}
              organizationId={reduxLogin.organizationId}
            />
          )}
        <a
          href="https://www.sgi.sk.ca/privacy"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-privacy"
        >
          Privacy Policy
        </a>
      </Container>
    </>
  );
}
