export interface ReasonForDischarge {
  dischargeReason: string;
  dischargeReasonDescription: string;
  dischargeDate?: Date;
}

export const defaultReasonForDischarge: ReasonForDischarge = {
  dischargeReason: "",
  dischargeReasonDescription: "",
  dischargeDate: undefined
};
