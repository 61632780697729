import { IFeedback } from "../../types/Feedback";
import { sortByDate, sortByStringThenDate } from "./SortingUtils";

export function getNewSortOrderForFeedbackList(
  sortByColumn: any,
  sortClassName: string,
  ifeedbackList: IFeedback[],
  currentSortColumn: string,
  setSortClassName: React.Dispatch<React.SetStateAction<string>>
) {
  let sortAttribute = sortByColumn.split("sort-")[1];
  var storeSortColumn = sortAttribute;
  var storeSortOrder;

  const newArray = ifeedbackList.map((c) => c);
  if (sortAttribute === currentSortColumn) {
    storeSortOrder = "asc";
    if (sortClassName.slice(sortClassName.length - 3) === "asc") {
      storeSortOrder = "dsc";
    }

    if (storeSortColumn === "submittedDate") {
      newArray.sort(sortByDate(sortAttribute, storeSortOrder));
    } else {
      newArray.sort(sortByStringThenDate(sortAttribute, storeSortOrder));
    }

    setSortClassName("th-sort-" + storeSortOrder);
  } else {
    if (sortAttribute === "submittedDate") {
      newArray.sort(sortByDate(sortAttribute, "dsc"));
      setSortClassName("th-sort-dsc");
      storeSortColumn = sortAttribute;
      storeSortOrder = "dsc";
    } else {
      newArray.sort(sortByStringThenDate(sortAttribute, "asc"));
      setSortClassName("th-sort-asc");
      storeSortColumn = sortAttribute;
      storeSortOrder = "asc";
    }
  }
  return { storeSortColumn, storeSortOrder, newArray, sortAttribute };
}
