import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { InlineSpinner } from "../../components/Spinners/Spinners";
import './FloatSaveButton.css';


interface FloatSaveButtonProps {
    enabled: boolean,
    title?: string,
    handler: () => Promise<void>,
    loading: boolean
}

const FloatSaveButton = (props: FloatSaveButtonProps) => {
  
  return (
    <>
      {props.enabled && 
        <div
          id="saveButtonContainer"
          className="float-right float-save-container"
          data-testid="saveButtonContainer"
          onClick={props.handler}
        >
          {props.loading && (
            <InlineSpinner
              for="button"
              id="saveSpinner"
              data-testid="saveSpinner"
            />
          )}
          {!props.loading && (
            <FontAwesomeIcon
              data-testid="saveButton"
              icon={faSave}
            />
          )}
        </div>

      }
    </>
  );
}

export default FloatSaveButton