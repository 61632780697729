import React from "react";
import { Spinner } from "react-bootstrap";
import "./Spinners.css";

interface PageSpinnerProps {
  id?: string;
  "data-testid"?: string;
}

interface InlineSpinnerProps {
  id?: string;
  "data-testid"?: string;
  for?: string;
  className?: string;
}

const defaultPageProps: PageSpinnerProps = {
  id: "pageLoadingSpinner",
  "data-testid": "pageLoadingSpinner",
};

const defaultInlineProps: InlineSpinnerProps = {
  id: "spinner",
  "data-testid": "spinner",
};

const PageSpinner: React.FC<PageSpinnerProps> = (props) => (
  <Spinner {...props} animation="border" role="status" className="pageSpinner">
    <span className="sr-only">Loading...</span>
  </Spinner>
);

const InlineSpinner: React.FC<InlineSpinnerProps> = (props) => (
  <Spinner
    id={props.id}
    data-testid={props["data-testid"]}
    as={props.for && props.for === "button" ? "span" : undefined}
    animation="border"
    role="status"
    className={props.className + " spinner"}
  >
    <span className="sr-only">Loading...</span>
  </Spinner>
);

PageSpinner.defaultProps = defaultPageProps;
InlineSpinner.defaultProps = defaultInlineProps;

export { InlineSpinner, PageSpinner };
