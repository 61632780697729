import React from "react";
import { Alert, Button, Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";

export function HomePageButtonList() {
  const history = useHistory();
  const userPermissions = useAppSelector((state) => state.permissions);
  const flags = useAppSelector(state => state.flags);

  const canViewIntakeFormButton = () => {
    return userPermissions.canSubmitForm;
  };

  const canViewProgressFormButton = () => {
    return userPermissions.canSubmitForm;
  };

  const canViewDischargeFormButton = () => {
    return userPermissions.canSubmitForm;
  };

  const canViewTransferFileButton = () => {
    return userPermissions.canSubmitAttachment && !flags.hideGisTransferFile;
  };

  const canViewFeedBackButton = () => {
    return userPermissions.canViewFeedback;
  };

  const canViewSubmissionHistoryButton = () => {
    return (
      !userPermissions.canViewTransferFileSummary &&
      (userPermissions.canViewAttachment || userPermissions.canViewForm)
    );
  };

  const canSeeTransferFileSummary = () => {
    return userPermissions.canViewTransferFileSummary;
  };

  const canSeePractitionerProfiles = () => {
    return userPermissions.canViewOrgProfiles;
  };

  const canViewFormDraftList = (): boolean => {
    return userPermissions.canSubmitForm && flags.saveDraftForm;
  }

  const canSeeAuditList = () => {
    return userPermissions.canViewAudit;
  }

  return (
    <Container id="homePageButtonList">
      {flags.hideGisTransferFile &&
      <Alert key="gis-disable-alert" variant="warning">
        Note: File Transfer Service is temporarily unavailable!
      </Alert>
      }
      {canViewFormDraftList() && (
        <Button
          variant="outline-primary"
          className="HomepageButton"
          data-testid="DraftListButton"
          id="DraftListButton"
          onClick={(e) => {
            history.push("/Forms/Chiro/Draft");
          }}
        >
          View Saved Forms
        </Button>
      )}
      {canViewIntakeFormButton() && (
        <Button
          variant="outline-primary"
          className="HomepageButton"
          data-testid="IntakeFormButton"
          id="IntakeFormButton"
          onClick={(e) => {
            history.push("/Forms/Chiro/Intake");
          }}
        >
          Start Intake Form
        </Button>
      )}
      {canViewProgressFormButton() && (
        <Button
          variant="outline-primary"
          className="HomepageButton"
          data-testid="ProgressFormButton"
          id="ProgressFormButton"
          onClick={(e) => {
            history.push("/Forms/Chiro/Progress");
          }}
        >
          Start Progress Form
        </Button>
      )}
      {canViewDischargeFormButton() && (
        <Button
          variant="outline-primary"
          className="HomepageButton"
          data-testid="DischargeFormButton"
          id="DischargeFormButton"
          onClick={(e) => {
            history.push("/Forms/Chiro/Discharge");
          }}
        >
          Start Discharge Form
        </Button>
      )}
      {canViewTransferFileButton() && (
        <Button
          variant="outline-primary"
          className="HomepageButton"
          data-testid="TransferFileButton"
          id="TransferFileButton"
          onClick={(e) => {
            history.push("/Forms/Chiro/TransferFile");
          }}
        >
          Transfer File to SGI
        </Button>
      )}
      {canViewSubmissionHistoryButton() && (
        <Button
          variant="outline-primary"
          className="HomepageButton"
          data-testid="SubmissionHistoryButton"
          id="SubmissionHistoryButton"
          onClick={(e) => {
            history.push("/Forms/Chiro/SubmissionHistory");
          }}
        >
          Submission History List
        </Button>
      )}
      {canSeePractitionerProfiles() && (
        <Button
          variant="outline-primary"
          className="HomepageButton"
          data-testid="PractitionerProfilesButton"
          onClick={(e) => {
            history.push("/Employee/Practitioners/ProfileList");
          }}
        >
          Practitioner Profiles
        </Button>
      )}
      {canViewFeedBackButton() && (
        <Button
          variant="outline-primary"
          className="HomepageButton"
          data-testid="FeedbackButton"
          onClick={(e) => {
            history.push("/Employee/FeedbackList");
          }}
        >
          Feedback List
        </Button>
      )}
      {canSeeTransferFileSummary() && (
        <Button
          variant="outline-primary"
          className="HomepageButton"
          data-testid="FeedbackButton"
          onClick={(e) => {
            history.push("/Employee/TransferFileSummary");
          }}
        >
          File Transfer Summary
        </Button>
      )}
      
      {canSeeAuditList() && (
        <Button
          variant="outline-primary"
          className="HomepageButton"
          data-testid="AuditListButton"
          onClick={(e) => {
            history.push("/Employee/AuditList");
          }}
        >
          Audit List
        </Button>
      )}
      
    </Container>
  );
}
