import { Form, Col, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setReasonForDischarge } from "../../redux/ReasonForDischarge/ReasonForDischargeSlice";
import FormSelect from "../FormElements/FormSelect";
import FormTextArea from "../FormElements/FormTextArea";
import { DischargeReasons, ListType } from "../../../types/ListTypes";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";
import * as messages from "../../../constants/Messages";

interface Props {}

function ReasonForDischarge(props: Props) {
	const dispatch = useAppDispatch();
	const reduxReasonForDischarge = useAppSelector(
		(state) => state.reasonForDischarge
	);

	function handleChange(event: any) {
		const { name, value } = event.target;
		dispatch(
			setReasonForDischarge({ ...reduxReasonForDischarge, [name]: value })
		);
	}

	return (
		<Form.Group id="formReasonForDischarge">
			<Row>
				<Col>
					<div className="formSectionHeader">
						<h6>Reason for Discharge</h6>
					</div>
				</Col>
			</Row>
			<Row>
				<Col className="maxWidth400">
					<CustomDatePicker
						testId="formDischargeDate"
						field="dischargeDate"
						label="Date Treatment Ended"
						value={reduxReasonForDischarge.dischargeDate ?? undefined}
						onChange={handleChange}
						required
						max={new Date()}
						invalidMessage={messages.MUST_BE_VALID_DATE}
					/>
				</Col>
			</Row>
			<Row>
				<div>
					<Col className="maxWidth400">
						<FormSelect
							testId="formDischargeReason"
							field="dischargeReason"
							label="Reason for Discharge"
							value={reduxReasonForDischarge.dischargeReason}
							onChange={handleChange}
							data={DischargeReasons}
							mapping={(g: ListType) => {
								return { value: g.value, text: g.description };
							}}
							defaultOption={{ value: "", text: "" }}
							required
						/>
					</Col>
				</div>
			</Row>
			<Row>
				<Col>
					<FormTextArea
						label="Other Description"
						testId="formDischargeReasonDescription"
						field="dischargeReasonDescription"
						className="formTextArea"
						value={reduxReasonForDischarge.dischargeReasonDescription}
						onChange={handleChange}
						rows={3}
						feedbackLabel="Discharge Reason Other Description"
						required={reduxReasonForDischarge.dischargeReason === "Other"}
					/>
				</Col>
			</Row>
		</Form.Group>
	);
}

export default ReasonForDischarge;
