import { createSlice } from '@reduxjs/toolkit'
import { defaultIssuesReturnToNormalActivity } from "../../../types/IssuesReturnToNormalActivity";

export const IssuesReturnToNormalActivitySlice = createSlice({
    name: "IssuesReturnToNormalActivity",
    initialState: defaultIssuesReturnToNormalActivity,
    reducers: {
        setIssuesReturnToNormalActivity: (state, action) => {
            return {...state, ...action.payload};
        },
        clearIssuesReturnToNormalActivity: state => {
            return {...defaultIssuesReturnToNormalActivity}
        }
    }
})

export const {setIssuesReturnToNormalActivity, clearIssuesReturnToNormalActivity} = IssuesReturnToNormalActivitySlice.actions;

export default IssuesReturnToNormalActivitySlice.reducer;