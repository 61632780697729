import React from "react";
import { Form } from "react-bootstrap";
import InfoTooltip from "./InfoTooltip/InfoTooltip";
import { Placement } from "react-bootstrap/esm/types";

interface Props {
  text: string;
  required?: boolean;
  phoneNumber?: string;
  testId?: string;
  tooltipText?: string;
  tooltipPosition?: Placement;
}

function FormLabel({
  testId,
  text,
  required = false,
  phoneNumber = "",
  tooltipText = "",
  tooltipPosition = "right",
}: Props) {
  function handleOnClick(event: any) {
    event.preventDefault();
  }
  return (
    <Form.Label
      className="formLabelText"
      data-testid={testId}
      onClick={handleOnClick}
    >
      {text}

      {tooltipText !== "" && (
        <>
          &nbsp;
          <InfoTooltip
            id={text + "-tooltip"}
            text={tooltipText}
            position={tooltipPosition}
          />
        </>
      )}
    </Form.Label>
  );
}

export default FormLabel;
