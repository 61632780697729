const API_CONSTANTS = {
    PROGRESS_FORM: {
        GET: "v1/securedocui/ChiroProgressForm/GetProgressForm" 
    },
    INTAKE_FORM: {
        GET: "v1/securedocui/ChiroIntakeForm/GetIntakeForm" 
    },
    DISCHARGE_FORM:{
        GET: "v1/securedocui/ChiroDischargeForm/GetDischargeForm" 
    }
}

export default API_CONSTANTS;