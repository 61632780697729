import { Form, Col, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  setPractitionerProfileData,
  setPractitionerProfileAddress,
} from "../../redux/PractitionerProfile/PractitionerProfileSlice";
import FormText from "../FormElements/FormText";
import FormSelect from "../FormElements/FormSelect";
import { formatPostalCode } from "../../../utils/Formaters/DisplayFormatter";
import { patterns } from "../../../utils/regexPatterns";
import * as messages from "../../../constants/Messages";
import { Organization } from "../../../types/Organization";
import * as validationRegex from "../../../constants/ValidationRegex";
import { useEffect, useState } from "react";
import FormAutocomplete from "../FormElements/FormAutocomplete";

interface Props {
  organizationList: Organization[];
  validated: boolean;
}

function PractitionerProfileDetails(props: Props) {
  const dispatch = useAppDispatch();
  const reduxPractitionerProfile = useAppSelector(
    (state) => state.practitionerProfile
  );

  const [orgValid, setOrgValid] = useState<boolean | undefined>();

  useEffect(() => {
    if(props.validated){
        setOrgValid(!(reduxPractitionerProfile.organizationId === ""));
    }

  }, [props.validated])

  const getOrganizations = (orgs: Organization[]): Organization[] => {
    orgs.sort((a, b) =>
      a.name.toLowerCase() >= b.name.toLowerCase() ? 1 : -1
    );

    return orgs;
  };

  const [organizations] = useState(getOrganizations(props.organizationList));

  function handleChange(event: any) {
    const { name, value } = event.target;
    let valueToSave = value;
    const addressFields: string[] = [
      "street",
      "city",
      "province",
      "postalCode",
    ];

    if (name === "businessPhoneNumber" || name === "faxNumber") {
      valueToSave = value.replace(/\D/g, "");
    }

    if (addressFields.includes(name)) {
      dispatch(
        setPractitionerProfileAddress({
          ...reduxPractitionerProfile.practiceAddress,
          [name]: valueToSave,
        })
      );
    } else {
      dispatch(
        setPractitionerProfileData({
          ...reduxPractitionerProfile,
          [name]: valueToSave,
        })
      );
    }
  }

  const handleOrganizationChange = (item: any) => {  
    dispatch(
      setPractitionerProfileData({
        ...reduxPractitionerProfile,
        organizationId: item?.value ?? ""
      })
    );
  };

  return (
    <Form.Group id="formPractitionerProfile">
      <Row>
        <Col>
          <div className="formSectionHeader">
            <h6>Practitioner Profile</h6>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="maxWidth500">
          <FormAutocomplete
            label="Organization"
            field="organizationId"
            data={organizations}
            mapping={(g: Organization) => {
              return { value: g.organizationId, text: g.name };
            }}
            onChange={handleOrganizationChange}
            value={reduxPractitionerProfile.organizationId}
            isValid={orgValid}
            placeholder="Select an organization"
            testId="formOrganizationList"
          />
        </Col>
      </Row>
      <Row>
        <Col className="maxWidth300">
          <FormText
            type="text"
            testId="formSGIPayeeNumber"
            field="sgiPayeeNumber"
            label="SGI Payee Number"
            value={reduxPractitionerProfile.sgiPayeeNumber}
            onChange={handleChange}
            required
            pattern="^(?=.*[1-9])[0-9]{1,9}$"
            invalidMessage={
              reduxPractitionerProfile.sgiPayeeNumber.length < 1 ||
              reduxPractitionerProfile.sgiPayeeNumber.length > 9
                ? messages.MUST_BE_BETWEEN_1_TO_9_CHARACTERS
                : messages.NUMBERS_ONLY_NOTZERO
            }
          />
        </Col>
        <Col className="maxWidth400">
          <FormText
            required
            label="Practice Name"
            testId={"formPracticeName"}
            field="practiceName"
            pattern={validationRegex.PractitionerPractice}
            invalidMessage={messages.NAME_AND_SPECIAL_CHARACTERS}
            value={reduxPractitionerProfile.practiceName}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col className="maxWidth300">
          <FormText
            required
            label="First Name"
            testId={"formPractitionerFirstName"}
            field="practitionerFirstName"
            id="practitionerFirstName"
            pattern="^[a-zA-Z \-']*$"
            invalidMessage={messages.NAME_CHARACTERS_ONLY}
            value={reduxPractitionerProfile.practitionerFirstName}
            onChange={handleChange}
          />
        </Col>
        <Col className="maxWidth300">
          <FormText
            required
            label="Last Name"
            testId={"formPractitionerLastName"}
            field="practitionerLastName"
            id="practitionerLastName"
            pattern="^[a-zA-Z \-']*$"
            invalidMessage={messages.NAME_CHARACTERS_ONLY}
            value={reduxPractitionerProfile.practitionerLastName}
            onChange={handleChange}
          />
        </Col>
        <Col className="maxWidth150">
          <FormText
            required
            label="Title"
            testId={"formPractitionerTitle"}
            field="title"
            id="practitionerTitle"
            pattern="^[a-zA-Z \-']*$"
            invalidMessage={messages.NAME_CHARACTERS_ONLY}
            value={reduxPractitionerProfile.title}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col className="maxWidth400">
          <FormText
            required
            label="Address"
            testId={"formPractitionerAddress"}
            field="street"
            pattern={validationRegex.PractitionerAddress}
            invalidMessage={messages.STREET_ADDRESS}
            value={reduxPractitionerProfile.practiceAddress.street}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col className="maxWidth300">
          <FormText
            required
            label="City"
            testId={"formPractitionerCity"}
            field="city"
            pattern="^[a-zA-Z \-']*$"
            invalidMessage={messages.NAME_CHARACTERS_ONLY}
            value={reduxPractitionerProfile.practiceAddress.city}
            onChange={handleChange}
          />
        </Col>
        <Col className="maxWidth150">
          <FormText
            required
            label="Province"
            testId={"formPractitionerProvince"}
            field="province"
            pattern="^[a-zA-Z]{2}$"
            invalidMessage={
              reduxPractitionerProfile.practiceAddress.province.length > 2 ||
              reduxPractitionerProfile.practiceAddress.province.length < 2
                ? messages.MUST_BE_2_CHARACTERS
                : messages.LETTERS_ONLY
            }
            value={reduxPractitionerProfile.practiceAddress.province}
            onChange={handleChange}
          />
        </Col>
        <Col className="maxWidth300">
          <FormText
            required
            label="Postal Code"
            testId={"formPractitionerPostalCode"}
            field="postalCode"
            pattern={patterns.postalCode}
            invalidMessage={messages.POSTAL_CODE_FORMAT}
            value={formatPostalCode(
              reduxPractitionerProfile.practiceAddress.postalCode
            )}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col className="maxWidth300">
          <FormText
            required
            label="Telephone Number"
            testId={"formPractitionerPhoneNumber"}
            field="businessPhoneNumber"
            pattern={patterns.phoneNumber}
            invalidMessage={messages.PHONE_NUMBER}
            value={reduxPractitionerProfile.businessPhoneNumber}
            onChange={handleChange}
          />
        </Col>
        <Col className="maxWidth300">
          <FormText
            label="Fax Number"
            testId={"formPractitionerFaxNumber"}
            field="faxNumber"
            pattern={patterns.phoneNumber}
            invalidMessage={messages.PHONE_NUMBER}
            value={reduxPractitionerProfile.faxNumber}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col className="maxWidth400">
          <FormText
            required
            type="email"
            label="Email Address"
            testId={"formPractitionerEmailAddress"}
            field="businessEmailAddress"
            pattern={patterns.email}
            invalidMessage={messages.INVALID_EMAIL_FORMAT}
            value={reduxPractitionerProfile.businessEmailAddress}
            onChange={handleChange}
          />
        </Col>
      </Row>
    </Form.Group>
  );
}

export default PractitionerProfileDetails;
