import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import { getBaseApiUrl } from "./apiHelper";
import { getAccessToken } from "../utils/auth/authApiUtils";

export async function postFeedback(feedback: any) {
  var baseUrl = getBaseApiUrl();
  const token = await getAccessToken();

  return axios
    .post(baseUrl + "v1/securedocui/feedback/SubmitFeedback", feedback, {
      headers: {
        Accept: "application/json",
        ContentType: "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function getFeedback() {
  const baseUrl = getBaseApiUrl();
  const token = await getAccessToken();
  return axios
    .get(baseUrl + "v1/securedocui/feedback/GetAllFeedback", {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}
