import axios from "axios";
import { ChiroProgress } from "../types/Forms";
import { handleResponse, handleError } from "./apiUtils";
import { getAccessToken } from "../utils/auth/authApiUtils";
import { getBaseApiUrl } from "./apiHelper";
import API_CONSTANTS from "./apiConstants";

export async function submitChiroProgressForm(formData: ChiroProgress) {
  const baseUrl = getBaseApiUrl();
  const token = await getAccessToken();

  return axios
    .post(
      baseUrl + "v1/securedocui/ChiroProgressForm/SubmitProgressForm",
      formData,
      {
        headers: {
          Accept: "application/json",
          ContentType: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(handleResponse)
    .catch(handleError);
}

export async function saveChiroProgressForm(formData: ChiroProgress) {
  const baseUrl = getBaseApiUrl();
  const token = await getAccessToken();

  return axios
    .post(
      baseUrl + "v1/securedocui/ChiroProgressForm/SaveProgressForm",
      formData,
      {
        headers: {
          Accept: "application/json",
          ContentType: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(handleResponse)
    .catch(handleError);
}

export const getProgressForm = async (
  formId: string,
  organizationId: string,
  claimNumber: string
) => {
  const baseUrl = getBaseApiUrl();
  const url = `${baseUrl}${API_CONSTANTS.PROGRESS_FORM.GET}?FormId=${formId}&OrganizationId=${organizationId}&ClaimNumber=${claimNumber}`;
  const token = await getAccessToken();

  return axios
    .get(url, {
      responseType: "json",
      headers: {
        Accept: "application/json",
        ContentType: "application/json",
        Authorization: "Bearer " + token,
      }
    })
    .then(handleResponse)
    .catch(handleError);
};
