import React from "react";
import { Container, Button } from "react-bootstrap";
import "./HomePage.css";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../utils/auth/authConfig";
import { loadingUser } from "../../redux/Login/LoginSlice";
import { PageSpinner } from "../../components/Spinners/Spinners";
import { HomePageButtonList } from "./HomePageButtonList";

export function HomePage() {
  const dispatch = useAppDispatch();
  // auth declares
  const { instance } = useMsal();
  const login = useAppSelector((state) => state.login);
  const handleLogin = async () => {
    dispatch(loadingUser({ loading: true }));
    try {
      await instance
        .loginRedirect(loginRequest)
        .catch((e) => {
          console.log("Error with authentication: " + e);
        })
        .then((response) => {
          if (response === undefined) {
            return;
          }
          console.log("Login successful " + response);
        });
    } catch (err) {
      console.error(err);
    }
  };

  if (login.loading) {
    return <PageSpinner id="homePageSpinner" data-testid="homePageSpinner" />;
  }

  return (
    <Container id="HomePageContainer">
      <div id="WelcomeMessage">Welcome to SGI Secure Document Submission</div>
      <div className="ButtonList">
        {!login.signedIn ? (
          <Button
            variant="outline-primary"
            className="HomepageButton"
            data-testid="LoginButton"
            onClick={(e) => {
              handleLogin();
            }}
          >
            Login
          </Button>
        ) : (
          <HomePageButtonList />
        )}
      </div>
    </Container>
  );
}
