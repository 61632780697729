import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import { getBaseApiUrl } from "./apiHelper";
import { getAccessToken } from "../utils/auth/authApiUtils";

export async function getPermissions(organizationId: string) {
  const baseUrl = getBaseApiUrl();
  const token = await getAccessToken();

  return axios
    .get(
      baseUrl + "v1/securedocui/permissions/ByOrganization/" + organizationId,
      {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(handleResponse)
    .catch(handleError);
}
