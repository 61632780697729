import { Form, Col, Row } from "react-bootstrap";
import FormLabel from "../FormElements/FormLabel";
import FormText from "../FormElements/FormText";
import FormTextArea from "../FormElements/FormTextArea";
import FormCheck from "../FormElements/FormCheck";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setManagementPlan } from "../../redux/ManagementPlan/ManagementPlanSlice";
interface Props {
  isProgressForm: boolean;
}

function ManagementPlan(props: Props) {
  const dispatch = useAppDispatch();
  const reduxMP = useAppSelector((state) => state.managementPlan);

  const checkboxFields = [
    ["No Treatment", "noTreatment"],
    ["Multi-disciplinary Assessment", "multiDisciplinaryAssessment"],
    ["Specialist", "specialist"],
    ["Investigation (X-ray or other)", "investigation"],
  ];

  const checkboxTreatment1 = [
    ["Chiropractor", "chiropractor"],
    ["Physical Therapist", "physicalTherapist"],
    ["Massage", "massage"],
    ["Education", "education"],
    ["Electrophysical", "electrophysical"],
    ["Transitional RTW", "transitionalRTW"],
  ];
  const checkboxTreatment2 = [
    ["Supervised Global Conditioning", "supervisedGlobalConditioning"],
  ];
  const checkboxTreatment3 = [
    ["Regional Conditioning (Home)", "homeRegionalConditioning"],
    ["Regional Conditioning (Supervised)", "supervisedRegionalConditioning"],
  ];

  function handleChange(event: any) {
    const { name, value, checked, type } = event.target;

    let fieldVal = type === "checkbox" ? checked : value;
    dispatch(setManagementPlan({ ...reduxMP, [name]: fieldVal }));
  }

  return (
    <Form.Group id="formManagementPlan">
      <Row>
        <Col>
          <div className="formSectionHeader">
            <h6>
              Management Plan (include duration and frequency of treatment)
            </h6>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="maxWidth800">
          <FormLabel text="Please provide a time frame in weeks months or years or frequency of visits e.g., 3x/week, or 6x/month or 6x/year." />
        </Col>
      </Row>
      <Row xs={1} sm={2} md={3} lg="auto">
        {checkboxFields.map((type, idx) => (
          <Col key={idx}>
            <FormCheck
              key={idx}
              label={type[0]}
              testId={`form${
                type[1].charAt(0).toUpperCase() + type[1].slice(1)
              }`}
              field={type[1]}
              checked={
                type[0] === "No Treatment"
                  ? reduxMP["noTreatment"]
                  : type[0] === "Multi-disciplinary Assessment"
                  ? reduxMP["multiDisciplinaryAssessment"]
                  : type[0] === "Specialist"
                  ? reduxMP["specialist"]
                  : type[0] === "Investigation (X-ray or other)"
                  ? reduxMP["investigation"]
                  : false
              }
              onChange={handleChange}
            />
          </Col>
        ))}
      </Row>
      {!props.isProgressForm && (
        <Row>
          <Col>
            <FormTextArea
              label="Initial management plan (describe below):"
              testId="formInitialManagementPlan"
              field="initialManagementPlan"
              className="formTextArea"
              value={reduxMP.initialManagementPlan}
              onChange={handleChange}
              rows={4}
            />
          </Col>
        </Row>
      )}
      {props.isProgressForm && (
        <Row>
          <Col>
            <FormTextArea
              label="Updated management plan (describe below):"
              testId="formUpdatedManagementPlan"
              field="updatedManagementPlan"
              className="formTextArea"
              value={reduxMP.updatedManagementPlan}
              onChange={handleChange}
              rows={4}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <FormTextArea
            label="List the education provided and self-management strategies (ex. HEP, Etc...) to be implemented by the customer:"
            testId="formEducationAndSelfManagement"
            field="educationAndSelfManagement"
            className="formTextArea"
            value={reduxMP.educationAndSelfManagement}
            onChange={handleChange}
            rows={4}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormLabel
            testId="formTreatmentPlanLabel"
            text="1. Treatment Plan:"
          />
        </Col>
      </Row>
      <Row xs={1} sm={2} lg={3}>
        {checkboxTreatment1.map((type, idx) => (
          <Col key={idx}>
            <FormCheck
              key={idx}
              label={type[0]}
              testId={`form${
                type[1].charAt(0).toUpperCase() + type[1].slice(1)
              }`}
              field={type[1]}
              checked={
                type[0] === "Physical Therapist"
                  ? reduxMP["physicalTherapist"]
                  : type[0] === "Massage"
                  ? reduxMP["massage"]
                  : type[0] === "Education"
                  ? reduxMP["education"]
                  : type[0] === "Electrophysical"
                  ? reduxMP["electrophysical"]
                  : type[0] === "Transitional RTW"
                  ? reduxMP["transitionalRTW"]
                  : type[0] === "Chiropractor"
                  ? reduxMP["chiropractor"]
                  : false
              }
              onChange={handleChange}
            />
          </Col>
        ))}
        {checkboxTreatment2.map((type, idx) => (
          <Col key={idx}>
            <FormCheck
              key={idx}
              label={type[0]}
              testId={`form${
                type[1].charAt(0).toUpperCase() + type[1].slice(1)
              }`}
              field={type[1]}
              checked={
                type[0] === "Supervised Global Conditioning"
                  ? reduxMP["supervisedGlobalConditioning"]
                  : false
              }
              onChange={handleChange}
            />
          </Col>
        ))}
        {checkboxTreatment3.map((type, idx) => (
          <Col key={idx}>
            <FormCheck
              key={idx}
              label={type[0]}
              testId={`form${
                type[1].charAt(0).toUpperCase() + type[1].slice(1)
              }`}
              field={type[1]}
              checked={
                type[0] === "Regional Conditioning (Home)"
                  ? reduxMP["homeRegionalConditioning"]
                  : type[0] === "Regional Conditioning (Supervised)"
                  ? reduxMP["supervisedRegionalConditioning"]
                  : false
              }
              onChange={handleChange}
            />
          </Col>
        ))}
      </Row>
      <Row>
        <Col>
          <FormTextArea
            label="Other (describe)"
            testId="formOtherTreatment"
            field="otherTreatment"
            className="formTextArea"
            value={reduxMP.otherTreatment}
            onChange={handleChange}
            rows={2}
          />
        </Col>
      </Row>
      <Row>
        <Col className="maxWidth500">
          <FormLabel text="2. Frequency of Treatment:" />
        </Col>
        <Col className="maxWidth200">
          <FormText
            type="text"
            testId="formFrequencyOfTreatment"
            field="frequencyOfTreatment"
            value={reduxMP.frequencyOfTreatment}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col className="maxWidth500">
          <FormLabel text="3. Total Number of Treatments:" />
        </Col>
        <Col className="maxWidth200">
          <FormText
            type="text"
            testId="formTotalNumberOfTreatments"
            field="totalNumberOfTreatments"
            value={reduxMP.totalNumberOfTreatments}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col className="maxWidth500">
          <FormLabel text="4. Expected Number of Weeks to Discharge:" />
        </Col>
        <Col className="maxWidth200">
          <FormText
            type="text"
            testId="formExpectedNumberOfWeeksToDischarge"
            field="expectedNumberOfWeeksToDischarge"
            value={reduxMP.expectedNumberOfWeeksToDischarge}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col className="maxWidth500">
          <FormLabel text="Has the employer been contacted?" />
        </Col>
        <Col className="form-check managementPlanCheck maxWidth200">
          <FormCheck
            testId="formHasEmployerContacted"
            field="hasEmployerContacted"
            checked={reduxMP.hasEmployerContacted}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col className="maxWidth500">
          <FormLabel text="Has a RTW plan been arranged?" />
        </Col>
        <Col className="form-check managementPlanCheck maxWidth200">
          <FormCheck
            testId="formHasRTWPlanArranged"
            field="hasRTWPlanArranged"
            checked={reduxMP.hasRTWPlanArranged}
            onChange={handleChange}
          />
        </Col>
      </Row>
    </Form.Group>
  );
}

export default ManagementPlan;
