export interface ManagementPlan {
  noTreatment: boolean;
  multiDisciplinaryAssessment: boolean;
  specialist: boolean;
  investigation: boolean;
  physicalTherapist: boolean;
  massage: boolean;
  education: boolean;
  electrophysical: boolean;
  transitionalRTW: boolean;
  supervisedGlobalConditioning: boolean;
  regionalConditioning: boolean;
  homeRegionalConditioning: boolean;
  supervisedRegionalConditioning: boolean;
  chiropractor: boolean;
  otherTreatment: string;
  initialManagementPlan: string;
  updatedManagementPlan: string;
  educationAndSelfManagement: string;
  frequencyOfTreatment: string;
  totalNumberOfTreatments: string;
  expectedNumberOfWeeksToDischarge: string;
  hasEmployerContacted: boolean;
  hasRTWPlanArranged: boolean;
}

export const defaultManagementPlan: ManagementPlan = {
  noTreatment: false,
  multiDisciplinaryAssessment:  false,
  specialist:  false,
  investigation:  false,
  physicalTherapist:  false,
  massage: false,
  education: false,
  electrophysical: false,
  transitionalRTW: false,
  supervisedGlobalConditioning: false,
  regionalConditioning: false,
  homeRegionalConditioning: false,
  supervisedRegionalConditioning: false,
  chiropractor: false,
  otherTreatment: "",
  initialManagementPlan: "",
  updatedManagementPlan: "",
  educationAndSelfManagement: "",
  frequencyOfTreatment: "",
  totalNumberOfTreatments: "",
  expectedNumberOfWeeksToDischarge: "",
  hasEmployerContacted: false,
  hasRTWPlanArranged: false
}
