import {createSlice} from '@reduxjs/toolkit'

import {defaultRemarks} from "../../../types/Remarks"

export const RemarksSlice = createSlice({
    name:"Remarks",
    initialState:defaultRemarks,
    reducers:{
        setRemarks: (state,action) => {
            return{...state,...action.payload};            
        },
        clearRemarks: state => {
            return {...defaultRemarks}
        }
    }
})

export const {setRemarks,clearRemarks} = RemarksSlice.actions;
export default RemarksSlice.reducer;