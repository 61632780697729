import FormLabel from "../../app/components/FormElements/FormLabel";
import NumberFormat from "react-number-format";
import { Form } from "react-bootstrap";

export function HealthCardInput(props: any) {
  const inputProps = { "data-testid": props.testId, ...props };
  delete inputProps.label;
  delete inputProps.invalidMessage;
  delete inputProps.testId;

  return (
    <>
      <FormLabel testId={props.testId + "-label"} text={props.label} />
      <NumberFormat
        {...inputProps}
        className="form-control"
        displayType="input"
        format="### ### ###"
        onKeyDown={(e: any) => {
          e.key === "Enter" && e.preventDefault();
        }}
      />
      <Form.Control.Feedback
        type="invalid"
        data-testid={props.testId + "-error"}
      >
        {(props.label || props.invalidMessage) &&
          props.label + props.invalidMessage}
      </Form.Control.Feedback>
    </>
  );
}
