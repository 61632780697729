import {createSlice} from '@reduxjs/toolkit'
import { defaultMedicalHistory } from '../../../types/MedicalHistory'

export const MedicalHistorySlice = createSlice({
    name: "MedicalHistory",
    initialState: defaultMedicalHistory,
    reducers:{
        setMedicalHistoryData: (state, action) => {
            return {...state,...action.payload};
        },
        clearMedicalHistoryData: state => {
            return {...defaultMedicalHistory}
        }
    }
})

export const {setMedicalHistoryData, clearMedicalHistoryData} = MedicalHistorySlice.actions;

export default MedicalHistorySlice.reducer;