import { useEffect, useState } from "react";
import { Error } from "../Alerts/Error";
import * as healthCheckApi from "../../../api/HealthCheckApi";
import { useAppSelector } from "../../redux/hooks";
import { SERVICE_UNAVAILABLE } from "../../../constants/Messages";

export function BannerMessage() {
  const [message, setMessage] = useState("");
  const reduxPermissions = useAppSelector((state) => state.permissions);

  const checkAvailability = async () => {
    try {
      await healthCheckApi.checkAvailability().then((result: any) => {
        if (result?.data) {
          if (Object.values(result.data)?.filter((x) => !x).length > 0) {
            setMessage(SERVICE_UNAVAILABLE);
          } else {
            setMessage("");
          }
        } else {
          setMessage(SERVICE_UNAVAILABLE);
        }
      });
    } catch (err) {
      setMessage(SERVICE_UNAVAILABLE);
      console.log(err);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (Object.values(reduxPermissions)?.filter((x) => x).length > 0) {
        checkAvailability();
      }
    }, 300000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxPermissions]);

  return (
    <>
      {message.length > 0 && (
        <div data-testid="BannerMessage" className="BannerMessage">
          <Error message={message} />
        </div>
      )}
    </>
  );
}
