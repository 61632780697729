import { Form, Col, Row } from "react-bootstrap";
import { setSpecialRestrictionsReturnToNormalActivity } from "../../redux/SpecialRestrictionsReturnToNormalActivity/SpecialRestrictionsReturnToNormalActivitySlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import FormTextArea from "../FormElements/FormTextArea";
import FormSelect from "../FormElements/FormSelect";
import { ListType, SpecialRestrictions } from "../../../types/ListTypes";

interface Props {}

function SpecialRestrictionsReturnToNormalActivity(props: Props) {
  const dispatch = useAppDispatch();
  const reduxSpecialRestrictionsReturnToNormalActivity = useAppSelector(
    (state) => state.specialRestrictionsReturnToNormalActivity
  );

  function handleChange(event: any) {
    const { name, value } = event.target;

    let fieldVal = null;
    if (name === "hasSpecialRestrictions") {
      fieldVal = value === "true" ? true : false;
    } else {
      fieldVal = value;
    }

    dispatch(
      setSpecialRestrictionsReturnToNormalActivity({
        ...reduxSpecialRestrictionsReturnToNormalActivity,
        [name]: fieldVal,
      })
    );
  }

  return (
    <Form.Group id="formSpecialRestrictionsReturnToNormalActivity">
      <Row>
        <Col>
          <div className="formSectionHeader">
            <h6>
              Special Restrictions/Conditions on Return to Normal Activity
            </h6>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={"auto"} sm={"auto"}>
          <FormSelect
            testId="formSpecialRestrictionsHasSpecialRestrictions"
            field="hasSpecialRestrictions"
            label="Special Restrictions / Conditions"
            value={reduxSpecialRestrictionsReturnToNormalActivity.hasSpecialRestrictions.toString()}
            onChange={handleChange}
            data={SpecialRestrictions}
            mapping={(g: ListType) => {
              return { value: g.value, text: g.description };
            }}
          />
        </Col>
      </Row>
      <div>&nbsp;</div>
      <Row>
        <Col>
          <FormTextArea
            label=""
            testId="formSpecialRestrictionsDetails"
            field="specialRestrictionsDetails"
            className="formTextArea"
            value={
              reduxSpecialRestrictionsReturnToNormalActivity.specialRestrictionsDetails
            }
            onChange={handleChange}
            rows={4}
            feedbackLabel="Special Restrictions/Conditions on Return to Normal Activity"
            required={
              reduxSpecialRestrictionsReturnToNormalActivity.hasSpecialRestrictions ===
              true
            }
          />
        </Col>
      </Row>
    </Form.Group>
  );
}

export default SpecialRestrictionsReturnToNormalActivity;
