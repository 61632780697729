import { createSlice } from "@reduxjs/toolkit";
import { defaultTreatmentGoals } from "../../../types/TreatmentGoals";

export const TreatmentGoalsSlice = createSlice({
  name: "TreatmentGoals",
  initialState: defaultTreatmentGoals,
  reducers: {
    setTreatmentGoals: (state, action) => {
      return {...state,...action.payload};
    },
    clearTreatmentGoals: (state) => {
      return { ...defaultTreatmentGoals };
    },
  },
});

export const { setTreatmentGoals, clearTreatmentGoals } =
  TreatmentGoalsSlice.actions;

export default TreatmentGoalsSlice.reducer;
