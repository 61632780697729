export interface Gender {
	value: string;
	description: string;
}

export const GenderList: Gender[] = [
	{ value: "Male", description: "Male" },
	{ value: "Female", description: "Female" },
	{ value: "Unspecified", description: "Unspecified" },
];
