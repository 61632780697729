import { Button, Modal } from "react-bootstrap";
import "./Modal.css";

interface ModalProps {
  show: boolean;
  onHide: any;
  onLogout: any;
  onStayAlive: any;
  timeBeforeLogout: any;
}

export function ModalIdleTimeout(props: ModalProps) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      aria-labelledby="contained-modal-title-vcenter"
      data-testid="idleTimeoutModal"
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <h4>Are you still there?</h4>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          Due to inactivity, you will be logged out in:{" "}
          {props.timeBeforeLogout.minutes}:{props.timeBeforeLogout.seconds}.
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="secondary"
          data-testid="confirmLogoutModalButton"
          onClick={props.onLogout}
        >
          Log out
        </Button>
        <Button
          variant="primary"
          data-testid="confirmStayLoggedInModalButton"
          onClick={props.onStayAlive}
        >
          Keep me signed in
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
