import { createSlice } from "@reduxjs/toolkit";
import { defaultToastMessages } from "../../../types/ToastMessages";

export const ToastMessagesSlice = createSlice({
  name: "ToastMessages",
  initialState: defaultToastMessages,
  reducers: {
    setToastMessages: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearToastMessages: (state) => {
      state.toastMessages = defaultToastMessages.toastMessages;
      return state;
    },
  },
});

export const { setToastMessages, clearToastMessages } =
  ToastMessagesSlice.actions;

export default ToastMessagesSlice.reducer;
