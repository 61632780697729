import { Practitioner } from "../../types/Practitioner";
import { PractitionerProfile } from "../../types/PractitionerProfile";

export function practitionerProfileTransformer(
  practitonerProfile: PractitionerProfile
): Practitioner {
  const practitioner: Practitioner = {
    id: practitonerProfile.organizationId,
    sgiPayeeNumber: practitonerProfile.sgiPayeeNumber,
    firstName: practitonerProfile.practitionerFirstName,
    lastName: practitonerProfile.practitionerLastName,
    facilityName: practitonerProfile.practiceName,
    address: {
      street: practitonerProfile.practiceAddress.street,
      city: practitonerProfile.practiceAddress.city,
      postalCode: practitonerProfile.practiceAddress.postalCode,
      province: practitonerProfile.practiceAddress.province,
      country: practitonerProfile.practiceAddress.country,
    },
    phoneNumber: practitonerProfile.businessPhoneNumber,
  };

  return practitioner;
}

export function practitionerProfileListTransformer(
  data: any
): PractitionerProfile[] {
  var returnProfileData: PractitionerProfile[] = [];

  data.forEach((item: any) => {
    var profileObject: PractitionerProfile = {
      organizationId: item.organizationId,
      sgiPayeeNumber: item.sgiPayeeNumber,
      practitionerFirstName: item.practitionerFirstName,
      practitionerLastName: item.practitionerLastName,
      title: item.title,
      practiceName: item.practiceName,
      practiceAddress: item.practiceAddress,
      businessPhoneNumber: item.businessPhoneNumber,
      faxNumber: item.faxNumber,
      businessEmailAddress: item.businessEmailAddress,
      practitionerName: item.title + " " + item.practitionerLastName,
      practiceCity: item.practiceAddress?.city,
    };
    returnProfileData.push(profileObject);
  });
  return returnProfileData;
}
