import { createSlice } from "@reduxjs/toolkit";
import { defaultUser } from "../../../types/UserTypes";

export const LoginSlice = createSlice({
  name: "Login",
  initialState: defaultUser,
  reducers: {
    login: (state, action) => {
      return { ...state, ...action.payload, signedIn: true };
    },
    loadingUser: (state, action) => {
      return { ...state, ...action.payload };
    },
    logout: (state) => {
      return { ...defaultUser };
    },
  },
});

export const { login, logout, loadingUser } = LoginSlice.actions;

export default LoginSlice.reducer;
