import { Form, Col, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  setPractitionerData,
  setPractitionerAddress,
} from "../../redux/Practitioner/PractitionerSlice";
import FormText from "../FormElements/FormText";
import { formatPostalCode } from "../../../utils/Formaters/DisplayFormatter";
import { patterns } from "../../../utils/regexPatterns";
import * as messages from "../../../constants/Messages";
import * as validationRegex from "../../../constants/ValidationRegex";

interface Props {}

function PractitionerInformation(props: Props) {
  const dispatch = useAppDispatch();
  const reduxPractitioner = useAppSelector((state) => state.practitioner);

  function handleChange(event: any) {
    const { name, value } = event.target;
    let valueToSave = value;
    const addressFields: string[] = [
      "street",
      "city",
      "province",
      "postalCode",
    ];

    if (name === "phoneNumber") {
      valueToSave = value.replace(/\D/g, "");
    }
    if (addressFields.includes(name)) {
      dispatch(
        setPractitionerAddress({
          ...reduxPractitioner.address,
          [name]: valueToSave,
        })
      );
    } else {
      dispatch(
        setPractitionerData({ ...reduxPractitioner, [name]: valueToSave })
      );
    }
  }

  return (
    <Form.Group id="formPractitionerInformation">
      <Row>
        <Col>
          <div className="formSectionHeader">
            <h6>Practitioner Information</h6>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="maxWidth300">
          <FormText
            type="text"
            testId="formSGIPayeeNumber"
            field="sgiPayeeNumber"
            label="SGI Payee Number"
            value={reduxPractitioner.sgiPayeeNumber}
            onChange={handleChange}
            required
            pattern="^(?=.*[1-9])[0-9]{1,9}$"
            invalidMessage={
              reduxPractitioner.sgiPayeeNumber.length < 1 ||
              reduxPractitioner.sgiPayeeNumber.length > 9
                ? messages.MUST_BE_BETWEEN_1_TO_9_CHARACTERS
                : messages.NUMBERS_ONLY_NOTZERO
            }
          />
        </Col>
        <Col className="maxWidth400">
          <FormText
            required
            label="Clinic/Facility Name"
            testId={"formPractitionerFacilityName"}
            field="facilityName"
            pattern={validationRegex.PractitionerPractice}
            invalidMessage={messages.NAME_AND_SPECIAL_CHARACTERS}
            value={reduxPractitioner.facilityName}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col className="maxWidth300">
          <FormText
            required
            label="First Name"
            testId={"formPractitionerFirstName"}
            field="firstName"
            id="practitionerFirstName"
            pattern="^[a-zA-z \-']*$"
            invalidMessage={messages.NAME_CHARACTERS_ONLY}
            value={reduxPractitioner.firstName}
            onChange={handleChange}
          />
        </Col>
        <Col className="maxWidth300">
          <FormText
            required
            label="Last Name"
            testId={"formPractitionerLastName"}
            field="lastName"
            id="practitionerLastName"
            pattern="^[a-zA-z \-']*$"
            invalidMessage={messages.NAME_CHARACTERS_ONLY}
            value={reduxPractitioner.lastName}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col className="maxWidth400">
          <FormText
            required
            label="Address"
            testId={"formPractitionerAddress"}
            field="street"
            pattern={validationRegex.PractitionerAddress}
            invalidMessage={messages.STREET_ADDRESS}
            value={reduxPractitioner.address.street}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col className="maxWidth300">
          <FormText
            required
            label="City"
            testId={"formPractitionerCity"}
            field="city"
            pattern="^[a-zA-z \-']*$"
            invalidMessage={messages.NAME_CHARACTERS_ONLY}
            value={reduxPractitioner.address.city}
            onChange={handleChange}
          />
        </Col>
        <Col className="maxWidth150">
          <FormText
            required
            label="Province"
            testId={"formPractitionerProvince"}
            field="province"
            pattern="^[a-zA-z]{2}$"
            invalidMessage={
              reduxPractitioner.address.province.length > 2 ||
              reduxPractitioner.address.province.length < 2
                ? messages.MUST_BE_2_CHARACTERS
                : messages.LETTERS_ONLY
            }
            value={reduxPractitioner.address.province}
            onChange={handleChange}
          />
        </Col>
        <Col className="maxWidth300">
          <FormText
            required
            label="Postal Code"
            testId={"formPractitionerPostalCode"}
            field="postalCode"
            pattern={patterns.postalCode}
            invalidMessage={messages.POSTAL_CODE_FORMAT}
            value={formatPostalCode(reduxPractitioner.address.postalCode)}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col className="maxWidth300">
          <FormText
            required
            label="Telephone Number"
            testId={"formPractitionerPhoneNumber"}
            field="phoneNumber"
            pattern={patterns.phoneNumber}
            invalidMessage={messages.PHONE_NUMBER}
            value={reduxPractitioner.phoneNumber}
            onChange={handleChange}
          />
        </Col>
      </Row>
    </Form.Group>
  );
}

export default PractitionerInformation;
