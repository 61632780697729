import {createSlice} from '@reduxjs/toolkit'
import { defaultManagementPlan } from '../../../types/ManagementPlan'

export const ManagementPlanSlice = createSlice({
    name: "ManagementPlan",
    initialState: defaultManagementPlan,
    reducers:{
        setManagementPlan: (state,action) => {
            return {...state,...action.payload};            
        },
        clearManagementPlan: state => {
            return {...defaultManagementPlan}
        }
    }
})

export const {setManagementPlan,clearManagementPlan} = ManagementPlanSlice.actions;

export default ManagementPlanSlice.reducer;