import { Form, Col, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setClientReportedCurrentFunction } from "../../redux/ClientReportedCurrentFunction/ClientReportedCurrentFunctionSlice";
import FormTextArea from "../FormElements/FormTextArea";
import FormSelect from "../FormElements/FormSelect";
import {
  ClientReportedFunctionHomeYardList,
  ClientReportedFunctionList,
  ClientReportedFunctionSelfCareList,
  ClientReportedFunctionWorkList,
  ListType,
} from "../../../types/ListTypes";
import { useEffect } from "react";
import { defaultClientReportedCurrentFunction } from "../../../types/ClientReportedCurrentFunction";

interface Props {
  validated?: boolean;
}

function ClientReportedCurrentFunction(props: Props) {
  const dispatch = useAppDispatch();
  const reduxClientReportedCurrentFunction = useAppSelector(
    (state) => state.clientReportedCurrentFunction
  );

  function handleChange(event: any) {
    const { name, value, checked, type } = event.target;

    let fieldVal = type === "checkbox" ? checked : value;

    dispatch(
      setClientReportedCurrentFunction({
        ...reduxClientReportedCurrentFunction,
        [name]: fieldVal,
      })
    );
  }
  const disableRestrictedFunction: boolean =
    reduxClientReportedCurrentFunction.functionLevel !== "restrictedFunctions";

  function resetRestritedFunctionFields(functionLevel: string) {
    dispatch(
      setClientReportedCurrentFunction({
        ...defaultClientReportedCurrentFunction,
        ["functionLevel"]: functionLevel,
      })
    );
  }

  const isCurrentFunctionSelectionRequirementSatified = () =>
    reduxClientReportedCurrentFunction.currentFunctionHomeYard.trim() !== "" ||
    reduxClientReportedCurrentFunction.currentFunctionSelfCare.trim() !== "" ||
    reduxClientReportedCurrentFunction.currentFunctionWork.trim() !== "";

  useEffect(() => {
    if (
      reduxClientReportedCurrentFunction.functionLevel !== "restrictedFunctions"
    ) {
      resetRestritedFunctionFields(
        reduxClientReportedCurrentFunction.functionLevel
      );
    }
  }, [reduxClientReportedCurrentFunction.functionLevel]);

  return (
    <Form.Group id="formClientReportedCurrentFunction">
      <Row>
        <Col>
          <div className="formSectionHeader">
            <h6>Client Reported Current Function</h6>
          </div>
        </Col>
      </Row>
      <Row>
        <div>
          <Col className="maxWidth300">
            <FormSelect
              testId="formFunctionLevel"
              field="functionLevel"
              label="Function Level"
              value={reduxClientReportedCurrentFunction.functionLevel}
              onChange={handleChange}
              data={ClientReportedFunctionList}
              mapping={(g: ListType) => {
                return { value: g.value, text: g.description };
              }}
              defaultOption={{ value: "", text: "" }}
              required
            />
          </Col>
        </div>
      </Row>
      <Form.Group as={Row}>
        <Col className="maxWidth300">
          <FormSelect
            testId="currentFunctionWork"
            field="currentFunctionWork"
            label="Work: "
            feedbackLabel=""
            value={reduxClientReportedCurrentFunction.currentFunctionWork}
            onChange={handleChange}
            data={ClientReportedFunctionWorkList}
            mapping={(g: ListType) => {
              return { value: g.value, text: g.description };
            }}
            defaultOption={{ value: "", text: "" }}
            disabled={disableRestrictedFunction}
            required={!isCurrentFunctionSelectionRequirementSatified()}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col className="maxWidth300">
          <FormSelect
            testId="currentFunctionHomeYard"
            field="currentFunctionHomeYard"
            label="Home and Yard ADL's: "
            feedbackLabel=""
            value={reduxClientReportedCurrentFunction.currentFunctionHomeYard}
            onChange={handleChange}
            data={ClientReportedFunctionHomeYardList}
            mapping={(g: ListType) => {
              return { value: g.value, text: g.description };
            }}
            defaultOption={{ value: "", text: "" }}
            disabled={disableRestrictedFunction}
            required={!isCurrentFunctionSelectionRequirementSatified()}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col className="maxWidth300">
          <FormSelect
            testId="currentFunctionSelfCare"
            field="currentFunctionSelfCare"
            label="Self-Care: "
            feedbackLabel=""
            value={reduxClientReportedCurrentFunction.currentFunctionSelfCare}
            onChange={handleChange}
            data={ClientReportedFunctionSelfCareList}
            mapping={(g: ListType) => {
              return { value: g.value, text: g.description };
            }}
            defaultOption={{ value: "", text: "" }}
            disabled={disableRestrictedFunction}
            required={!isCurrentFunctionSelectionRequirementSatified()}
          />
        </Col>
      </Form.Group>
      {props.validated &&
        !disableRestrictedFunction &&
        !isCurrentFunctionSelectionRequirementSatified() && (
          <Row>
            <Col
              data-testid="formCurrentFunctionSelectionInvalid"
              className="invalid"
            >
              At least 1 current function level is required
            </Col>
          </Row>
        )}
      <Row>
        <Col>
          <FormTextArea
            testId="formLessThanFullFunctionDescription"
            field="lessThanFullFunctionDescription"
            label="Less than full function (Explain):"
            feedbackLabel="Less than full function description"
            rows={4}
            value={
              reduxClientReportedCurrentFunction.lessThanFullFunctionDescription
            }
            onChange={handleChange}
            disabled={disableRestrictedFunction}
            required={!disableRestrictedFunction}
          />
        </Col>
      </Row>
    </Form.Group>
  );
}

export default ClientReportedCurrentFunction;
