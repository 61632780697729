// export enum Role {
//   Chiropractor = "Chiropractor",
//   Employee = "Employee",
//   None = "None",
// }

export interface User {
  signedIn: boolean;
  loading: boolean;
  username: string;
  fullName: string;
  givenName: string;
  surName: string;
  payeeNumber: string;
  email: string;
  companyName: string;
  userId: string;
  availableOrganizations: string[];
  organizationId: string;
}

export const defaultUser: User = {
  signedIn: false,
  username: "",
  loading: false,
  fullName: "",
  givenName: "",
  surName: "",
  payeeNumber: "",
  email: "",
  companyName: "",
  userId: "00000000-0000-0000-0000-000000000000",
  availableOrganizations: [],
  organizationId: "00000000-0000-0000-0000-000000000000",
};
