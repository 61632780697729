import {createSlice} from '@reduxjs/toolkit'
import { defaultPractitionerReportedCurrentFunction } from '../../../types/PractitionerReportedCurrentFunction'

export const PractitionerReportedCurrentFunctionSlice = createSlice({
    name: "PractitionerReportedCurrentFunction",
    initialState: defaultPractitionerReportedCurrentFunction,
    reducers:{
        setPractitionerReportedCurrentFunction: (state,action) => {
            return {...state,...action.payload};            
        },
        clearPractitionerReportedCurrentFunction: state => {
            return {...defaultPractitionerReportedCurrentFunction}
        }
    }
})

export const {setPractitionerReportedCurrentFunction,clearPractitionerReportedCurrentFunction} = PractitionerReportedCurrentFunctionSlice.actions;

export default PractitionerReportedCurrentFunctionSlice.reducer;