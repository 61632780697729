import "./Alert.css";
import { Alert, Col, Container, Row } from "react-bootstrap";
interface Props {
  message: string;
}
export function Error(props: Props) {
  return (
    <Alert variant="danger">
      <Container className="alertContainer">
        <Row className="alertContainerRow">
          <Col
            className="alertMessage"
            id="alertError"
            data-testid="alertError"
          >
            {props.message}
          </Col>
        </Row>
      </Container>
    </Alert>
  );
}
