import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import { getAccessToken } from "../utils/auth/authApiUtils";
import { getBaseApiUrl } from "./apiHelper";
import { User } from "../types/UserTypes";

export async function updateUserDetails(user: User) {
  const baseUrl = getBaseApiUrl();
  const token = await getAccessToken();

  const userDetails = {
    authUserId: user.userId,
    organizationId: user.organizationId,
    givenName: user.givenName,
    surName: user.surName,
    emailAddress: user.email,
  };

  return axios
    .post(baseUrl + "v1/securedocui/secdocuser/InsertUpdateUser", userDetails, {
      headers: {
        Accept: "application/json",
        ContentType: "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}
