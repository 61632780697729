export interface ITransferFile {
  authUserId: string;
  organizationId: string;
  organizationName: string;
  sgiPayeeNumber: string;  
  claimNumber: string;
	fileName: string;
  formId: string;
  attachmentId: string;
  formType: string;
	contentType: string;
  errorHttpCode: string;
  errorDetails: string;
	details: string;
	status: string;
  submittedDate: string;
  submittedBy: string;
}

export const defaultTransferFileSummary: ITransferFile[] = [];
