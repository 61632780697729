export interface ListType {
  value: string;
  description: string;
}

export const ClientReportedFunctionList: ListType[] = [
  {
    value: "fullFunctionWithoutSymptoms",
    description: "Full function without symptoms",
  },
  {
    value: "fullFunctionWithSymptoms",
    description: "Full function with symptoms",
  },
  {
    value: "restrictedFunctions",
    description: "Restricted functions",
  },
];

export const PractitionerReportedCurrentFunctionList: ListType[] = [
  {
    value: "WorkFullDuties",
    description: "Work Full Duties",
  },
  {
    value: "WorkModifiedDuties",
    description: "Work Modified Duties",
  },
  {
    value: "UnableToWork",
    description: "Unable to Work",
  },
  {
    value: "NonWorker",
    description: "Non-Worker",
  },
];

export const DischargeReasons: ListType[] = [
  {
    value: "ClientTransferredToMultidisciplinaryCare",
    description: "Client transferred to multidiscipinary care",
  },
  { value: "ConditionResolved", description: "Condition Resolved" },
  { value: "PlateauedFunction", description: "Plateaued Function" },
  { value: "Other", description: "Other" },
];

export const OutcomeCodes: ListType[] = [
  {
    value: "1",
    description: "Discharged without restrictions - returned to work/ADL",
  },
  {
    value: "2",
    description: "Discharged without restrictions - did not return to work/ADL",
  },
  {
    value: "4",
    description: "Discharged with restrictions - returned to work/ADL",
  },
  {
    value: "5",
    description: "Discharged with restrictions - did not return to work/ADL",
  },
  { value: "6", description: "Did not complete program" },
];

export const CurrentAssessmentFindingsList: ListType[] = [
  {
    value: "none",
    description: "None",
  },
  {
    value: "gradeI",
    description: "Grade I",
  },
  {
    value: "gradeII",
    description: "Grade II",
  },
  {
    value: "gradeIII",
    description: "Grade III",
  },
  {
    value: "gradeIV",
    description: "Grade IV",
  },
];

export const ClientReportedFunctionWorkList: ListType[] = [
  {
    value: "Full",
    description: "Full",
  },
  {
    value: "ModifiedDuties",
    description: "Modified Duties",
  },
  {
    value: "UnableToWork",
    description: "Unable to Work",
  },
  {
    value: "NonWorker",
    description: "Non-worker",
  },
];
export const ClientReportedFunctionHomeYardList: ListType[] = [
  {
    value: "Full",
    description: "Full",
  },
  {
    value: "AssistanceRequired",
    description: "Assistance Required",
  },
  {
    value: "Dependent",
    description: "Dependent",
  },
];
export const ClientReportedFunctionSelfCareList: ListType[] = [
  {
    value: "Full",
    description: "Full",
  },
  {
    value: "AssistanceRequired",
    description: "Assistance Required",
  },
  {
    value: "Dependent",
    description: "Dependent",
  },
];

export const SpecialRestrictions: ListType[] = [
  {
    value: "false",
    description: "None",
  },
  {
    value: "true",
    description: "Restrictions/Conditions (Explain)",
  },
];

export const ReturnToNormalActivityIssues: ListType[] = [
  {
    value: "false",
    description: "None",
  },
  {
    value: "true",
    description: "Issues (Explain)",
  },
];

export const FurthTreatmentComments: ListType[] = [
  {
    value: "false",
    description: "None",
  },
  {
    value: "true",
    description: "Issues (Explain)",
  },
];
