import {createSlice} from '@reduxjs/toolkit'
import { defaultSubjectiveAndObjectiveFindings } from '../../../types/SubjectiveAndObjectiveFindings'

export const SubjectiveAndObjectiveFindingsSlice = createSlice({
    name: "SubjectiveAndObjectiveFindings",
    initialState: defaultSubjectiveAndObjectiveFindings,
    reducers:{
        setSubjectiveAndObjectiveFindings: (state,action) => {
            return {...state,...action.payload};            
        },
        clearSubjectiveAndObjectiveFindings: state => {
            return {...defaultSubjectiveAndObjectiveFindings}
        }
    }
})

export const {setSubjectiveAndObjectiveFindings,clearSubjectiveAndObjectiveFindings} = SubjectiveAndObjectiveFindingsSlice.actions;

export default SubjectiveAndObjectiveFindingsSlice.reducer;