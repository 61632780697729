import {createSlice} from '@reduxjs/toolkit'
import { defaultCurrentAssessmentFindings } from '../../../types/CurrentAssessmentFindings'

export const CurrentAssessmentFindingsSlice = createSlice({
    name: "CurrentAssessmentFindings",
    initialState: defaultCurrentAssessmentFindings,
    reducers:{
        setCurrentAssessmentFindings: (state,action) => {
            return {...state,...action.payload};            
        },
        clearCurrentAssessmentFindings: state => {
            return {...defaultCurrentAssessmentFindings}
        }
    }
})

export const {setCurrentAssessmentFindings,clearCurrentAssessmentFindings} = CurrentAssessmentFindingsSlice.actions;

export default CurrentAssessmentFindingsSlice.reducer;