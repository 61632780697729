import { useState } from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Table, Col, Container, Row } from "react-bootstrap";
import { SortOrder } from "react-bootstrap-table-next";
import { getFormList } from "../../../api/ChiroListFormApi";
import CustomTable from "../../components/CustomTable/CustomTable";
import { convertDateToDisplayFormatWithTime } from "../../../utils/Formaters/DisplayFormatter";
import { ScreenSize, useWindowDimensions } from "../../../utils/Formaters/ScreenFormatter";
import TableButton from "../../components/CustomTable/TableButton";
import { useAppSelector } from "../../redux/hooks";
import { draftFormListTransformer } from "../../../utils/Transformers/DraftFormListTransformer";
import { ChiroFormType } from "../../../constants/ChiroFormType";
import { useHistory } from "react-router-dom";
import { IDraftFormList } from "../../../types/DraftFormList";

interface Props {}

function handleTableHeaderClick(e: any) {
    //sortChiroDraftList(e.target.id);
}

function ChiroDraftFormList(props: Props) {
    const history = useHistory();
    const defaultDraftList: any[] = [];
    const [chiroDraftList, setChiroDraftList] = useState(defaultDraftList);
    const { screenSize } = useWindowDimensions();
    const userPermissions = useAppSelector((state) => state.permissions);
    const reduxLogin = useAppSelector((state) => state.login);

    function noDataIndication() {
        return "No Draft Forms available";
    }
    
    async function getDraftFormListData() {
        return await getFormList(ChiroFormType.DRAFT, reduxLogin.organizationId);
    }

    function viewChiroForm(row: IDraftFormList) {
        let formType = null;
        if(row) {
            if(row.formType && row.formType.includes("Chiro")) formType = row.formType.replace('Chiro', '');
            if(formType) {
                let url = `/Forms/Chiro/${formType}/${row.organizationId}/${row.claimNumber}/${row.id}`;
                history.push(url);
            }
        }
    }

    function buttonsFormatter(cell: string, row: any, rowIndex: any) {
        return (
            <div className="buttonCellWrapper">
                { userPermissions.canViewForm &&  (
                    <TableButton
                    icon={faSearch}
                    onClick={() => viewChiroForm(row)}
                    tooltipText="View Details"
                    id={`searchIcon${rowIndex}`}
                    datatestid={`searchIcon${rowIndex}`}
                    />
                )}
            </div>
        );
    }

    const defaultSorted: [{ dataField: any; order: SortOrder }] = [
        {
            dataField: "submittedDate",
            order: "desc",
        },
    ];
    
    const columns = [
        {
            dataField: "submittedDate",
            text: "Date Saved",
            sort: true,
            formatter: (cellContent: any) => {
                return convertDateToDisplayFormatWithTime(cellContent);
            },
            hidden: screenSize < ScreenSize.md,
            headerStyle: () => {
                return { width: "200px" };
            },
        },
        {
            dataField: "claimNumber",
            text: "Claim Number",
            sort: true,
            headerStyle: () => {
                return { width: "180px" };
            },
        },
        {
            dataField: "customer.firstName",
            text: "First Name",
            sort: true,
        },
        {
            dataField: "customer.lastName",
            text: "Last Name",
            sort: true
        },
        {
            dataField: "formType",
            text: "Type",
            sort: true,
            headerStyle: () => {
                return { width: "150px" };
            },
        },
        {
            dataField: "",
            text: "",
            isDummyField: true,
            formatter: buttonsFormatter,
            hidden: screenSize < ScreenSize.md,
            headerStyle: () => {
                return { width: "80px" };
            },
        },
    ];
    
    return (
        <Container>
            <Row>
                <Col lg="10">
                    <div className="Title">
                        <h3>In-Process Saved Reports List</h3>
                    </div>
                </Col>
                <Col lg="2"></Col>
            </Row>
            <CustomTable
            noDataIndication={noDataIndication}
            defaultSorted={defaultSorted}
            columns={columns}
            data={chiroDraftList}
            setData={setChiroDraftList}
            defaultData={defaultDraftList}
            dataGetter={getDraftFormListData}
            dataMapper={draftFormListTransformer}
            keyField="submittedDate"
            />
        </Container>
    );
}

export default ChiroDraftFormList;