import { Form, Col, Row } from "react-bootstrap";
import { setFormFooter } from "../../redux/FormFooter/FormFooterSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import FormText from "../FormElements/FormText";

const FormFooter = () => {
  const dispatch = useAppDispatch();
  const reduxFormFooter = useAppSelector((state) => state.formFooter);

  function handleChange(event: any) {
    const { name, value } = event.target;
    dispatch(setFormFooter({ ...reduxFormFooter, [name]: value }));
  }

  return (
    <Form.Group id="formFooter">
      <Row>
        <Col>
          <FormText
            label="CC to:"
            testId="formCCTo"
            field="cc"
            value={reduxFormFooter.cc}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <div>&nbsp;</div>
    </Form.Group>
  );
};

export default FormFooter;
