import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "../../AppInsights";

export function convertDateToStringFormat(date?: Date): string {
  if (date === undefined) {
    return "";
  }
  return date.toLocaleString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
}

export function convertDateToDisplayFormatWithTime(date: string): string {
  try {
    if (!date || date === "") return "";
    return new Date(date).toLocaleString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  } catch (error: any) {
    try {
      if (appInsights !== undefined) {
        appInsights.trackTrace({
          message: "error in date convert func: " + date + " : " + error,
          severityLevel: SeverityLevel.Information,
        });
      }
    } catch (e) {
      console.error("AppInsights error:", e);
    }
    console.error("Error in date convert func :", error);
    return date;
  }
}

export function formatPostalCode(postalCode: string) {
  return postalCode.replace(/ /g, "").toUpperCase();
}

export function formatHealthCardNumberForStorage(healthCardNumber: string) {
  var formattedHealthCardNumber = healthCardNumber.replace(/\D/g, "");
  if (formattedHealthCardNumber.length > 9) {
    formattedHealthCardNumber = formattedHealthCardNumber.substring(0, 9);
  }
  return formattedHealthCardNumber;
}
