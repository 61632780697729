import {createSlice} from '@reduxjs/toolkit'
import { defaultReasonForDischarge } from '../../../types/ReasonForDischarge'

export const ReasonForDischargeSlice = createSlice({
    name: "ReasonForDischarge",
    initialState: defaultReasonForDischarge,
    reducers: {
        setReasonForDischarge: (state,action) => {
            return {...state,...action.payload};            
        },
        clearReasonForDischarge: state => {
            return {...defaultReasonForDischarge}
        }
    }
})

export const {setReasonForDischarge,clearReasonForDischarge} = ReasonForDischargeSlice.actions;

export default ReasonForDischargeSlice.reducer;