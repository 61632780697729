export enum FeedbackType {
  GeneralFeedback = "General Feedback",
  Bug = "Report an Issue",
}
export interface IFeedback {
  accountType: string;
  content: string;
  email: string;
  feedbackId: string;
  feedbackType: FeedbackType;
  name: string;
  submittedDate: string;
}

export const defaultFeedbackList: IFeedback[] = [];
