import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import { getBaseApiUrl } from "./apiHelper";
import { getAccessToken } from "../utils/auth/authApiUtils";
import { ITransferFile } from "../../src/types/TransferFileTypes";

const transferStatusPath = "v1/securedocui/legacyTransferStatus";

export async function getTransferSummary() {
  const baseUrl = getBaseApiUrl();
  const token = await getAccessToken();

  return axios
    .get(`${baseUrl}${transferStatusPath}/GetTransferSummary`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

const updateTransferSummaryStatus = async (payload: ITransferFile) => {
  const baseUrl = getBaseApiUrl();
  const token = await getAccessToken();

  try {
    const response = await axios.put(
      `${baseUrl}${transferStatusPath}/UpdateTransferStatus`,
      payload,
      {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );

    return await handleResponse(response);
  } catch (ex) {
    return await handleError(ex);
  }
};

export { updateTransferSummaryStatus };
