export interface ToastMessages {
  toastMessages: ToastMessage[];
}

export const defaultToastMessages: ToastMessages = {
  toastMessages: [],
};

export interface ToastMessage {
  toastType: ToastType;
  message: string;
  title: string;
}

export enum ToastType {
  Info = "Info",
  Error = "Error",
}
