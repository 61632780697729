import { useState, useEffect, useRef } from "react";
import { Form, Button, Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import FormHeader from "../../components/FormHeader/FormHeader";
import { PageSpinner, InlineSpinner } from "../../components/Spinners/Spinners";
import CustomerInformation from "../../components/CustomerInformation/CustomerInformation";
import PractitionerInformation from "../../components/PractitionerInformation/PractitionerInformation";
import CurrentAssessmentFindings from "../../components/CurrentAssessmentFindings/CurrentAssessmentFindings";
import MedicalHistoryInformation from "../../components/MedicalHistoryInformation/MedicalHistoryInformation";
import PermanentScarringOrDisfigurement from "../../components/PermanentScarringOrDisfigurement/PermanentScarringOrDisfigurement";
import {
  saveChiroIntakeForm,
  submitChiroIntakeForm,
  getIntakeForm,
} from "../../../api/ChiroIntakeFormApi";
import SubjectiveAndObjectiveFindings from "../../components/SubjectiveAndObjectiveFindings/SubjectiveAndObjectiveFindings";
import TreatmentGoals from "../../components/TreatmentGoals/TreatmentGoals";
import ManagementPlan from "../../components/ManagementPlan/ManagementPlan";
import OutcomeMeasureUsed from "../../components/OutcomeMeasureUsed/OutcomeMeasureUsed";
import ClientReportedCurrentFunction from "../../components/ClientReportedCurrentFunction/ClientReportedCurrentFunction";
import PractitionerReportedCurrentFunction from "../../components/PractitionerReportedCurrentFunction/PractitionerReportedCurrentFunction";
import ComplianceAndAttendance from "../../components/ComplianceAndAttendance/ComplianceAndAttendance";
import Remarks from "../../components/Remarks/Remarks";
import InformationAccuracyDisclaimer from "../../components/InformationAccuracyDisclaimer/InformationAccuracyDisclaimer";
import {
  clearCustomerData,
  setCustomerData,
} from "../../redux/Customer/CustomerSlice";
import {
  clearCurrentAssessmentFindings,
  setCurrentAssessmentFindings,
} from "../../redux/CurrentAssessmentFindings/CurrentAssessmentFindingsSlice";
import {
  clearFormHeader,
  setFormHeader,
} from "../../redux/FormHeader/FormHeaderSlice";
import {
  clearMedicalHistoryData,
  setMedicalHistoryData,
} from "../../redux/MedicalHistory/MedicalHistorySlice";
import {
  clearPermanentScarringOrDisfigurement,
  setPermanentScarringOrDisfigurement,
} from "../../redux/PermanentScarringOrDisfigurement/PermanentScarringOrDisfigurementSlice";
import {
  clearPractitionerData,
  setPractitionerData,
} from "../../redux/Practitioner/PractitionerSlice";
import {
  clearSubjectiveAndObjectiveFindings,
  setSubjectiveAndObjectiveFindings,
} from "../../redux/SubjectiveAndObjectiveFindings/SubjectiveAndObjectiveFindingsSlice";
import {
  clearClientReportedCurrentFunction,
  setClientReportedCurrentFunction,
} from "../../redux/ClientReportedCurrentFunction/ClientReportedCurrentFunctionSlice";
import {
  clearPractitionerReportedCurrentFunction,
  setPractitionerReportedCurrentFunction,
} from "../../redux/PractitionerReportedCurrentFunction/PractitionerReportedCurrentFunctionSlice";
import {
  clearTreatmentGoals,
  setTreatmentGoals,
} from "../../redux/TreatmentGoals/TreatmentGoalsSlice";
import {
  clearManagementPlan,
  setManagementPlan,
} from "../../redux/ManagementPlan/ManagementPlanSlice";
import {
  clearOutcomeMeasureUsed,
  setOutcomeMeasureUsed,
} from "../../redux/OutcomeMeasureUsed/OutcomeMeasureUsedSlice";
import {
  clearComplianceAndAttendance,
  setComplianceAndAttendance,
} from "../../redux/ComplianceAndAttendance/ComplianceAndAttendanceSlice";
import { clearRemarks, setRemarks } from "../../redux/Remarks/RemarksSlice";
import { setToastMessages } from "../../redux/ToastMessages/ToastMessagesSlice";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { appInsights } from "../../../AppInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { ChiroIntake } from "../../../types/Forms";
import { IntakeFormValues } from "../../../constants/IntakeTestValues";
import FormCheck from "../../components/FormElements/FormCheck";

import { DownloadFile } from "../../../../src/utils/Download/DownloadFile";
import * as messages from "../../../constants/Messages";
import "./ChiroIntakeForm.css";
import { ToastType } from "../../../types/ToastMessages";
import FloatSaveButton from "../../components/FloatSaveButton/FloatSaveButton";
import FormFooter from "../../components/FormFooter/FormFooter";
import { clearFormFooter, setFormFooter } from "../../redux/FormFooter/FormFooterSlice";

interface Props {
	organizationId?: string,
	claimNumber?: string,
	formId?: string
}

function ChiroIntakeForm(props: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [validated, setValidated] = useState(false);
  const [downloadAfterSub, setDownloadAfterSub] = useState(false);
  const dispatch = useAppDispatch();
  const reduxToastMessages = useAppSelector((state) => state.toastMessages);
  const reduxLogin = useAppSelector((state) => state.login);

  const [formId, setFormId] = useState<string | undefined>(props.formId);
  const flags = useAppSelector((state) => state.flags);

  const history = useHistory();

  const intakeData: ChiroIntake = {
    formHeader: useAppSelector((state) => state.formHeader),
    customer: useAppSelector((state) => state.customer),
    practitioner: useAppSelector((state) => state.practitioner),
    medicalHistory: useAppSelector((state) => state.medicalHistory),
    currentAssessmentFindings: useAppSelector(
      (state) => state.currentAssessmentFindings
    ),
    permanentScarringOrDisfigurement: useAppSelector(
      (state) => state.permanentScarringOrDisfigurement
    ),
    subjectiveAndObjectiveFindings: useAppSelector(
      (state) => state.subjectiveAndObjectiveFindings
    ),
    clientReportedCurrentFunction: useAppSelector(
      (state) => state.clientReportedCurrentFunction
    ),
    outcomeMeasureUsed: useAppSelector((state) => state.outcomeMeasureUsed),
    practitionerReportedCurrentFunction: useAppSelector(
      (state) => state.practitionerReportedCurrentFunction
    ),
    treatmentGoals: useAppSelector((state) => state.treatmentGoals),
    managementPlan: useAppSelector((state) => state.managementPlan),
    complianceAndAttendance: useAppSelector(
      (state) => state.complianceAndAttendance
    ),
    remarks: useAppSelector((state) => state.remarks),
    userId: reduxLogin.userId,
    organizationId: reduxLogin.organizationId,
    formId: formId,
    formFooter: useAppSelector(state => state.formFooter)
  };

  useEffect(() => {
    if (props.organizationId && props.claimNumber && props.formId) {
      const getForm = async () => {
        try {
          const form = await getIntakeForm(
            props.formId!,
            props.organizationId!,
            props.claimNumber!
          );

          setData(form.data);
          setFormId(form.data.id);
        } catch (e) {
          if (appInsights !== undefined) {
            appInsights.trackTrace({
              message: "Failed to load form",
              severityLevel: SeverityLevel.Error,
            });
          }

          dispatch(
            setToastMessages({
              toastMessages: [
                ...reduxToastMessages.toastMessages,
                {
                  toastType: ToastType.Error,
                  message: "Failed to load the Form",
                  title: "Chiro Progress Form",
                },
              ],
            })
          );
        } finally {
          setIsPageLoading(false);
        }
      };

      getForm();
    } else {
      resetFormComponentState();
      setIsPageLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSave = async () => {
    setIsSaving(true);

    try {
      const response = await saveChiroIntakeForm(intakeData);

      if (response.Errors !== undefined) {
        dispatch(
          setToastMessages({
            toastMessages: [
              ...reduxToastMessages.toastMessages,
              {
                toastType: ToastType.Error,
                message: response.Errors.join('\n'),
                title: "Chiro Intake Form",
              }
          ]})
        );
        if (appInsights !== undefined) {
          appInsights.trackEvent({
            name: "Failed to save for."
          });
        }
      } else {
        setFormId(response.data.id);
        if (appInsights !== undefined) {
          appInsights.trackEvent({
            name: "Progress Form saved.",
          });
        }
        dispatch(
          setToastMessages({
            toastMessages: [
              ...reduxToastMessages.toastMessages,
              {
                toastType: ToastType.Info,
                message: "Form successfully saved.",
                title: "Chiro Intake Form",
              },
            ],
          })
        );
      }
    } catch (e) {
      if (appInsights !== undefined) {
        appInsights.trackTrace({
          message: "Failed to save form",
          severityLevel: SeverityLevel.Error,
        });
      }
    }

    setIsSaving(false);
  };

  async function handleOnSubmit(event: any) {
    event.preventDefault();
    setIsSubmitting(true);
    const form = event.currentTarget;
    const isValid: boolean = form.checkValidity();
    setValidated(true);

    if (isValid) {
      let info: any[] = [];
      let errors: any[] = [];

      let claimNumber = "";
      let organizationId = "";
      let formId = "";
      let submittedDate: Date;
      let fileName = "";

      await submitChiroIntakeForm(intakeData).then((apiResponse) => {
        if (apiResponse?.data) {
          formId = apiResponse?.data.id;
          claimNumber = apiResponse?.data.claimNumber;
          organizationId = apiResponse?.data.organizationId;
          submittedDate = apiResponse?.data.submittedDate;
          let fileSubmittedDate = new Date(submittedDate);
          fileName = `${intakeData.customer.lastName} ${fileSubmittedDate
            .getDate()
            .toString()
            .padStart(2, "0")}/${(fileSubmittedDate.getMonth() + 1)
            .toString()
            .padStart(2, "0")}/${fileSubmittedDate
            .getFullYear()
            .toString()} Primary Chiropractor Report - Intake.pdf`;

          info.push("Form submitted successfully");

          if (appInsights !== undefined) {
            appInsights.trackEvent({
              name: "Intake Form submitted.",
            });
          }
        } else {
          errors.push(
            "An error occurred submitting the form. Please try again."
          );

          if (appInsights !== undefined) {
            appInsights.trackTrace({
              message: "Failed to submit form",
              severityLevel: SeverityLevel.Error,
            });
          }
        }
      });

      if (errors.length === 0) {
        resetFormComponentState();
        history.push("/");

        if (downloadAfterSub) {
          let formToDownload = {
            formType: "ChiroIntake",
            claimNumber: claimNumber,
            organizationId: organizationId,
            formId: formId,
            fileName: fileName,
          };

          let result = await DownloadFile(formToDownload);
          if (result.toLowerCase() === "success") {
            info.push(messages.DOWNLOAD_SUCCESS);
          } else {
            errors.push(messages.DOWNLOAD_FAILED);
          }
        }
      }

      if (info.length > 0) {
        dispatch(
          setToastMessages({
            toastMessages: [
              ...reduxToastMessages.toastMessages,
              {
                toastType: ToastType.Info,
                message: info.toString(),
                title: "Intake Form",
              },
            ],
          })
        );
      }

      if (errors.length > 0) {
        dispatch(
          setToastMessages({
            toastMessages: [
              ...reduxToastMessages.toastMessages,
              {
                toastType: ToastType.Error,
                message: errors.toString(),
                title: "Intake Form",
              },
            ],
          })
        );
      }

      setValidated(false);
    } else {
      setFocusToFirstInvalidElement();
    }
    setIsSubmitting(false);
  }

  function setFocusToFirstInvalidElement() {
    let errorElements = document.querySelectorAll(
      ".form-control:invalid, .form-check-input:invalid"
    );
    if (errorElements !== undefined && errorElements.length > 0) {
      (errorElements[0] as HTMLInputElement).focus();
    }
  }

  function handleChangeDownloadAfterSub(event: any) {
    const { checked } = event.target;
    setDownloadAfterSub(checked);
  }

  function resetFormComponentState() {
    dispatch(clearCustomerData());
    dispatch(clearCurrentAssessmentFindings());
    dispatch(clearMedicalHistoryData());
    dispatch(clearClientReportedCurrentFunction());
    dispatch(clearPractitionerReportedCurrentFunction());
    dispatch(clearPermanentScarringOrDisfigurement());
    dispatch(clearSubjectiveAndObjectiveFindings());
    dispatch(clearTreatmentGoals());
    dispatch(clearManagementPlan());
    dispatch(clearFormHeader());
    dispatch(clearOutcomeMeasureUsed());
    dispatch(clearComplianceAndAttendance());
    dispatch(clearRemarks());
    dispatch(clearFormFooter());
  }

  const setData = (data:any) => {
    dispatch(setCustomerData(data.customer));
    dispatch(setPractitionerData(data.practitioner));
    dispatch(setCurrentAssessmentFindings(data.currentAssessmentFindings));
    dispatch(setMedicalHistoryData(data.medicalHistory));
    dispatch(setClientReportedCurrentFunction(data.clientReportedCurrentFunction));
    dispatch(setPractitionerReportedCurrentFunction(data.practitionerReportedCurrentFunction));
    dispatch(setSubjectiveAndObjectiveFindings(data.subjectiveAndObjectiveFindings));
    dispatch(setTreatmentGoals(data.treatmentGoals));
    dispatch(setManagementPlan(data.managementPlan));
    const pir = data.formHeader?.pir;
    dispatch(setFormHeader({ ...intakeData.formHeader, PIR: pir}));
    dispatch(setOutcomeMeasureUsed(data.outcomeMeasureUsed));
    dispatch(setComplianceAndAttendance(data.complianceAndAttendance));
    dispatch(setRemarks(data.remarks));
	  dispatch(setPermanentScarringOrDisfigurement(data.permanentScarringOrDisfigurement));
    dispatch(setFormFooter(data.formFooter));
  }

  return isPageLoading ? (
    <PageSpinner />
  ) : (
    <Container className="intakeFormContainer">
      {(!process.env.NODE_ENV ||
        process.env.NODE_ENV === "development" ||
        process.env.NODE_ENV === "test") && (
        <div id="TempButtonDiv">
          <Button
            variant="primary"
            id="TempStartNewClaimButton"
            onClick={() => setData(IntakeFormValues)}
          >
            {process.env.NODE_ENV} - Fill claim
          </Button>
        </div>
      )}
      <Row>
        <div className="formHeader">
          <h3>Primary Chiropractor Report - Intake</h3>
        </div>
      </Row>
      <Form noValidate validated={validated} onSubmit={handleOnSubmit}>
        <FormHeader />
        <CustomerInformation />
        <PractitionerInformation />
        <MedicalHistoryInformation />
        <CurrentAssessmentFindings />
        <PermanentScarringOrDisfigurement />
        <SubjectiveAndObjectiveFindings />
        <ClientReportedCurrentFunction validated={validated} />
        <OutcomeMeasureUsed validated={validated} />
        <PractitionerReportedCurrentFunction />
        <TreatmentGoals validated={validated} />
        <ManagementPlan isProgressForm={false} />
        <ComplianceAndAttendance />
        <Remarks />
        <FormFooter/>
        <InformationAccuracyDisclaimer />        
        <Row>
          <Form.Group id="formEntrySubmit" data-testid="formEntrySubmitDiv">
            <Col className="flexRight">
              <Button
                id="formEntrySubmitButton"
                data-testid="formEntrySubmitButton"
                disabled={isSubmitting}
                variant="outline-primary"
                type="submit"
                className="align-items-center"
              >
                {isSubmitting && (
                  <InlineSpinner
                    for="button"
                    id="submitSpinner"
                    data-testid="submitSpinner"
                  />
                )}
                {!isSubmitting && "Submit Form"}
              </Button>
              <FormCheck
                type="checkbox"
                label="Download form after submission"
                testId="downloadAfterSub"
                id="downloadAfterSub"
                checked={downloadAfterSub}
                onChange={handleChangeDownloadAfterSub}
                field="downloadAfterSub"
                className="checkboxBeforeButton"
                inline
              />
            </Col>
          </Form.Group>
        </Row>
      </Form>
      <FloatSaveButton
        enabled={flags.saveDraftForm}
        handler={handleOnSave}
        loading={isSaving}
      />
    </Container>
  );
}

export default ChiroIntakeForm;
