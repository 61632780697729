import FormLabel from "./FormLabel";
import { Form, Placeholder } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

interface Props {
  label: string;
  field: string;
  data: any[];
  required?: boolean;
  testId?: string;
  mapping(item: any): { value: string | number; text: string };
  feedbackLabel?: string;
  invalidMessage?: string;
  disabled?: boolean;
  defaultValue?: string;
  defaultOption?: {
    value: string | number;
    text: string;
  };
  value?: string | number;
  onChange?: any;
  isValid?: boolean;
  placeholder?: string;
}

const FormAutocomplete = (props: Props) => {
  const options = props.data.map((item: any, i: number) => {
    let { value, text } = props.mapping(item);
    return { label: text, value: value };
  });

  const onInputChange = (value: string, event: React.ChangeEvent<HTMLInputElement>) => {

    const option = options.find(x=>x.label === value);
    if(option !== undefined)
      props.onChange(option);
  }

  return (
    <>
      <FormLabel
        testId={props.testId ? props.testId + "-label" : undefined}
        text={props.label}
        required={props.required}
      />
      <Typeahead
        id={props.field}        
        options={options}
        disabled={props.disabled}
        onChange={(option) =>
          props.onChange(option.length > 0 ? option[0] : undefined)
        }
        onInputChange={onInputChange}
        defaultSelected={options.filter((x) => x.value === props.value)}
        inputProps={{ required: true }}
        renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
          <div>
            <input            
              type="text"
              {...inputProps}
              ref={(input) => {
                inputRef(input);
              }}
              data-testId={props.testId}
              className="form-control"
              placeholder={props.placeholder}
            />
            <Form.Control.Feedback
              type="invalid"
              data-testid={props.testId ? props.testId + "-error" : undefined}
            >
              {!props.disabled &&
                (props.label || props.invalidMessage) &&
                props.label +
                  (props.value === ""
                    ? " is required"
                    : props.invalidMessage ?? "")}
            </Form.Control.Feedback>
          </div>
        )}
      />
    </>
  );
};

export default FormAutocomplete;
