import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import { getBaseApiUrl } from "./apiHelper";
import { getAccessToken } from "../utils/auth/authApiUtils";

export async function getFormList(
    status: string,
    organizationId: string
) {
    const baseUrl =
        getBaseApiUrl() +
        `v1/securedocui/chiroListForm/GetChiroFormList?status=` +
        status + 
        `&organizationId=` +
        organizationId;
    
    const token = await getAccessToken();

    return axios
        .get(baseUrl, {
            responseType: "json",
            headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            },
        })
        .then(handleResponse)
        .catch(handleError);
    }