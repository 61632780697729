import { convertDateToStringFormat } from "../utils/Formaters/DisplayFormatter";
import { maxFileSizeMB } from "./FileUploadConstants";

//API UTILS
export const API_CALL_FAILED = "API call failed. ";
export const NOT_FOUND = "Not found";
export const NETWORK_RESPONSE_NOT_OK = "Network response was not ok.";
export const SERVICE_UNAVAILABLE =
  "The service is currently unavailable. Please check back later.";

export const MUST_BE_VALID_DATE =
  " must be a valid date on or before " + convertDateToStringFormat(new Date());
export const SIGNATURE_CONFIRMATION =
  "Please confirm that the provided signature is correct";
//Character Value Restriction
export const NUMBERS_ONLY = " must only contain numbers";
export const NUMBERS_ONLY_NOTZERO = " must only contain valid numbers";
export const LETTERS_ONLY = " must only contain letters";
export const NAME_AND_SPECIAL_CHARACTERS =
  " must only contain the following: letters, numbers, spaces and -@/!.,_'+&";
export const NAME_CHARACTERS_ONLY =
  " must only contain the following: letters, spaces, dashes";
export const NAME_CHARACTERS_AND_NUMBERS_ONLY =
  " must only contain the following: letters, numbers, spaces, dashes";
export const TITLE_CHARACTERS_ONLY =
  " must only contain the following: letters, spaces, periods";
export const STREET_ADDRESS =
  " must only contain the following: numbers, letters, spaces and -@/!.,_'+&";
export const POSTAL_CODE_FORMAT =
  " must alternate letters and numbers ex. S4P0J9";
export const PHONE_NUMBER = " must be a 10 digit phone number";
export const INVALID_EMAIL_FORMAT = " is an invalid format";
//Character Length Restrictions
export const MUST_BE_10_CHARACTERS = " must be 10 characters";
export const MUST_BE_9_CHARACTERS = " must be 9 characters";
export const MUST_BE_2_CHARACTERS = " must be 2 characters";
export const MUST_BE_BETWEEN_1_TO_10_CHARACTERS =
  " must be between 1 to 10 characters";
export const MUST_BE_BETWEEN_1_TO_9_CHARACTERS =
  " must be between 1 to 9 characters";

//TransferFile
export const UPLOAD_SUCCESS = "File uploaded successfully";
export const UPLOAD_FAILED =
  "An error occurred uploading the file. Please try again.";
export const NO_FILE_SELECTED = "A file must be selected";
export const MAX_FILE_SIZE_MB = `File size cannot exceed ${maxFileSizeMB} MB`;
export const INVALID_FILE_TYPE = "Invalid file type";
export const DOWNLOAD_SUCCESS = "File downloaded successfully";
export const DOWNLOAD_FAILED =
  "An error occurred downloading the file. Please try again.";

//File Transfer Summary
export const UPDATE_FILE_TRANSFER_STATUS_FAILED = `The system failed to update the File Transfer status`;
export const UPDATE_FILE_TRANSFER_STATUS_SUCCEDED = `The file has been successfully marked as `;
