import { IDraftFormList } from "../../types/DraftFormList";

export function draftFormListTransformer(data: any): IDraftFormList[] {
    var returnData: IDraftFormList[] = [];

    data.forEach((item: any) => {
        var fileSummaryObject: IDraftFormList = {
            id: item.id,
            organizationId: item.organizationId,
            claimNumber: item.claimNumber,
            customer: item.customer,
            formType: item.formType,
            submittedDate: item.submittedDate,
            };
        returnData.push(fileSummaryObject);
    });
    
    return returnData;
}