export interface Customer {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth?: Date;
  gender: string;
  healthCardNumber: string;
  customerNumber: string;
  claimNumber: string;
  dateOfAccident?: Date;
}

export const defaultCustomer: Customer = {
  id: "00000000-0000-0000-0000-000000000000",
  firstName: "",
  lastName: "",
  dateOfBirth: undefined,
  gender: "",
  healthCardNumber: "",
  customerNumber: "",
  claimNumber: "",
  dateOfAccident: undefined,
};
