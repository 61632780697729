export const RuntimeConfigPath = "/config.json";

export interface ClientConfigurationModel {
  REACT_APP_ENVIRONMENT: AppEnvironment;
  REACT_APP_APPINSIGHTS_INSTR_KEY: string;
  REACT_APP_AD_CLIENT_ID: string;
  REACT_APP_AD_AUTHORITY: string;
  REACT_APP_AD_REDIRECT_URL: string;
  REACT_APP_AD_POST_LOGOUT_REDIRECT_URL: string;
  LAUNCH_DARKLY_CLIENTID: string;
  REACT_APP_MSAL_SIGNUPSIGNIN_POLICY: string;
  REACT_APP_MSAL_FORGOTPASSWORD_POLICY: string;
  REACT_APP_MSAL_EDITPROFILE_POLICY: string;
  REACT_APP_MSAL_POLICY_AUTHORITY_ROOT: string;
  REACT_APP_MSAL_B2C_AUTHORITYDOMAIN: string;
  REACT_APP_BFF_API_SCOPES: string;
  REACT_APP_MSAL_CLIENT_ID: string;
  REACT_APP_MSAL_TENANT_ID: string;
  REACT_APP_BASEURL: string;
  REACT_APP_API_BASEURL: string;
}

export enum AppEnvironment {
  Development = "Development",
  Production = "Production",
  Int = "INT",
  QA = "QA",
  None = "",
}

export const defaultClientConfigurationModel: ClientConfigurationModel = {
  REACT_APP_API_BASEURL: process.env.REACT_APP_API_BASEURL as string,
  REACT_APP_ENVIRONMENT: AppEnvironment.Development as AppEnvironment,
  REACT_APP_APPINSIGHTS_INSTR_KEY: process.env
    .REACT_APP_APPINSIGHTS_INSTR_KEY as string,
  REACT_APP_AD_CLIENT_ID: process.env.REACT_APP_AD_CLIENT_ID as string,
  REACT_APP_AD_AUTHORITY:
    "https://login.microsoftonline.com/c45224ff-39b6-464a-83ee-2dd82e098116",
  REACT_APP_AD_REDIRECT_URL: process.env.REACT_APP_AD_REDIRECT_URL as string,
  REACT_APP_AD_POST_LOGOUT_REDIRECT_URL: process.env
    .REACT_APP_AD_POST_LOGOUT_REDIRECT_URL as string,
  LAUNCH_DARKLY_CLIENTID: process.env.LAUNCH_DARKLY_CLIENTID as string,
  REACT_APP_MSAL_SIGNUPSIGNIN_POLICY: process.env
    .REACT_APP_MSAL_SIGNUPSIGNIN_POLICY as string,
  REACT_APP_MSAL_FORGOTPASSWORD_POLICY: process.env
    .REACT_APP_MSAL_FORGOTPASSWORD_POLICY as string,
  REACT_APP_MSAL_EDITPROFILE_POLICY: process.env
    .REACT_APP_MSAL_EDITPROFILE_POLICY as string,
  REACT_APP_MSAL_POLICY_AUTHORITY_ROOT: process.env
    .REACT_APP_MSAL_POLICY_AUTHORITY_ROOT as string,
  REACT_APP_MSAL_B2C_AUTHORITYDOMAIN: process.env
    .REACT_APP_MSAL_B2C_AUTHORITYDOMAIN as string,
  REACT_APP_BFF_API_SCOPES: process.env.REACT_APP_BFF_API_SCOPES as string,
  REACT_APP_MSAL_CLIENT_ID: process.env.REACT_APP_MSAL_CLIENT_ID as string,
  REACT_APP_MSAL_TENANT_ID: process.env.REACT_APP_MSAL_TENANT_ID as string,
  REACT_APP_BASEURL: process.env.REACT_APP_BASEURL as string,
};

export const mockClientConfigurationModel: ClientConfigurationModel = {
  REACT_APP_API_BASEURL: "https://mockBaseURL",
  REACT_APP_ENVIRONMENT: AppEnvironment.Development,
  REACT_APP_APPINSIGHTS_INSTR_KEY: "",
  REACT_APP_AD_CLIENT_ID: process.env.REACT_APP_AD_CLIENT_ID as string,
  REACT_APP_AD_AUTHORITY: process.env.REACT_APP_AD_AUTHORITY as string,
  REACT_APP_AD_REDIRECT_URL: process.env.REACT_APP_AD_REDIRECT_URL as string,
  REACT_APP_AD_POST_LOGOUT_REDIRECT_URL: process.env
    .REACT_APP_AD_POST_LOGOUT_REDIRECT_URL as string,
  LAUNCH_DARKLY_CLIENTID: process.env.LAUNCH_DARKLY_CLIENTID as string,
  REACT_APP_MSAL_SIGNUPSIGNIN_POLICY: process.env
    .REACT_APP_MSAL_SIGNUPSIGNIN_POLICY as string,
  REACT_APP_MSAL_FORGOTPASSWORD_POLICY: process.env
    .REACT_APP_MSAL_FORGOTPASSWORD_POLICY as string,
  REACT_APP_MSAL_EDITPROFILE_POLICY: process.env
    .REACT_APP_MSAL_EDITPROFILE_POLICY as string,
  REACT_APP_MSAL_POLICY_AUTHORITY_ROOT: process.env
    .REACT_APP_MSAL_POLICY_AUTHORITY_ROOT as string,
  REACT_APP_MSAL_B2C_AUTHORITYDOMAIN: process.env
    .REACT_APP_MSAL_B2C_AUTHORITYDOMAIN as string,
  REACT_APP_BFF_API_SCOPES: process.env.REACT_APP_BFF_API_SCOPES as string,
  REACT_APP_MSAL_CLIENT_ID: process.env.REACT_APP_MSAL_CLIENT_ID as string,
  REACT_APP_MSAL_TENANT_ID: process.env.REACT_APP_MSAL_TENANT_ID as string,
  REACT_APP_BASEURL: process.env.REACT_APP_BASEURL as string,
};

// Application Insights
export const APP_INSIGHTS_CLOUD_ROLE = "Secure.Doc.SubmissionUI.UI";
