import { Route } from "react-router-dom";
import { routeProps } from "../../types/RouteProps";
import ChiroIntakeForm from "../pages/ChiroIntakeForm/ChiroIntakeForm";
import ChiroDraftFormList from "../pages/ChiroDraftFormList/ChiroDraftFormList";
import ChiroProgressForm from "../pages/ChiroProgressForm/ChiroProgressForm";
import ChiroDischargeForm from "../pages/ChiroDischargeForm/ChiroDischargeForm";
import NewPractitionerProfileForm from "../pages/Employee/PractitionerProfile/NewPractitionerProfileForm";
import TransferFile from "../pages/TransferFile/TransferFile";
import SubmissionHistory from "../pages/SubmissionHistory/SubmissionHistory";
import { HomePage } from "../pages/HomePage/HomePage";
import FeedbackList from "../pages/Employee/FeedbackList/FeedbackList";
import PrivateRoute from "./PrivateRoute";
import TransferFileSummary from "../pages/Employee/TransferFileSummary/TransferFileSummary";
import PractitionerProfileList from "../pages/Employee/PractitionerProfile/PractitionerProfileList";
import EditPractitionerProfileForm from "../pages/Employee/PractitionerProfile/EditPractitionerProfileForm";
import AuditList from "../pages/Employee/AuditList/AuditList";

function PrivateRoutes(props: routeProps) {
  var returnRoutes: any[] = [];

  returnRoutes.push(
    <Route exact path="/" key="root">
      <HomePage />
    </Route>
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Employee/Practitioners/Profile/Edit"
      component={EditPractitionerProfileForm}
      key="EditPractitionerProfileForm"
      requiredPermissions={["canSubmitOrgProfiles"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Employee/Practitioners/Profile/Add"
      component={NewPractitionerProfileForm}
      key="NewPractitionerProfileForm"
      requiredPermissions={["canSubmitOrgProfiles"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Employee/Practitioners/ProfileList"
      component={PractitionerProfileList}
      key="PractitionerProfileList"
      requiredPermissions={["canViewOrgProfiles"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Employee/FeedbackList"
      component={FeedbackList}
      key="FeedbackList"
      requiredPermissions={["canViewFeedback"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Forms/Chiro/Draft"
      component={ChiroDraftFormList}
      key="ChiroDraft"
      requiredPermissions={["canSubmitForm"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Forms/Chiro/Intake/:organizationId/:claimId/:formId"
      key="ChiroIntakeWithParams"
      requiredPermissions={["canSubmitForm"]}
      redirectOnUnAuthorized
      render={(props: any) => (
        <ChiroIntakeForm
          organizationId={props.match.params.organizationId}
          formId={props.match.params.formId}
          claimNumber={props.match.params.claimId}
        />
      )}
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Forms/Chiro/Intake"
      component={ChiroIntakeForm}
      key="ChiroIntake"
      requiredPermissions={["canSubmitForm"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Forms/Chiro/Progress/:organizationId/:claimId/:formId"
      key="ChiroProgressWithParams"
      requiredPermissions={["canSubmitForm"]}
      redirectOnUnAuthorized
      render={(props: any) => (
        <ChiroProgressForm
          organizationId={props.match.params.organizationId}
          formId={props.match.params.formId}
          claimNumber={props.match.params.claimId}
        />
      )}
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Forms/Chiro/Progress"
      component={ChiroProgressForm}
      key="ChiroProgress"
      requiredPermissions={["canSubmitForm"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Forms/Chiro/Discharge/:organizationId/:claimId/:formId"
      key="ChiroDischargeWithParams"
      requiredPermissions={["canSubmitForm"]}
      redirectOnUnAuthorized
      render={(props: any) => (
        <ChiroDischargeForm
          organizationId={props.match.params.organizationId}
          formId={props.match.params.formId}
          claimNumber={props.match.params.claimId}
        />
      )}
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Forms/Chiro/Discharge"
      component={ChiroDischargeForm}
      key="ChiroDischarge"
      requiredPermissions={["canSubmitForm"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Forms/Chiro/TransferFile"
      component={TransferFile}
      key="TransferFile"
      requiredPermissions={["canSubmitAttachment"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Employee/TransferFileSummary"
      component={TransferFileSummary}
      key="TransferFileSummary"
      requiredPermissions={["canViewTransferFileSummary"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Employee/AuditList"
      component={AuditList}
      key="AuditList"
      requiredPermissions={["canViewTransferFileSummary"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Forms/Chiro/SubmissionHistory"
      requireAll={false}
      component={SubmissionHistory}
      key="SubmissionHistory"
      requiredPermissions={["canViewForm", "canViewAttachment"]}
      redirectOnUnAuthorized
    />
  );
  return returnRoutes;
}

export default PrivateRoutes;
