import React from "react";
import ReactDOM from "react-dom";
// @ts-ignore
import { BrowserRouter as Router } from "react-router-dom";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import {
  AppInsightsContext,
  ReactPlugin,
} from "@microsoft/applicationinsights-react-js";
import "./index.css";
import App from "./app/App";
import { store } from "./app/redux/store";
import { Provider } from "react-redux";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { ai } from "./AppInsights";
import {
  ClientConfigurationModel,
  defaultClientConfigurationModel,
  RuntimeConfigPath,
} from "./types/clientConfigurationModel";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { getConfig } from "./utils/auth/authConfig";

const MOUNT_NODE = document.getElementById("root") as HTMLElement;
var clientSideID: string;

declare global {
  interface Window {
    LAUNCH_DARKLY_CLIENTID: string;
    REACT_APP_API_BASEURL: string;
    REACT_APP_APPINSIGHTS_INSTR_KEY: string;
    REACT_APP_BASEURL: string;
    REACT_APP_BFF_API_SCOPES: string;
    REACT_APP_ENVIRONMENT: string;
    REACT_APP_MSAL_B2C_AUTHORITYDOMAIN: string;
    REACT_APP_MSAL_CLIENT_ID: string;
    REACT_APP_MSAL_EDITPROFILE_POLICY: string;
    REACT_APP_MSAL_FORGOTPASSWORD_POLICY: string;
    REACT_APP_MSAL_POLICY_AUTHORITY_ROOT: string;
    REACT_APP_MSAL_SIGNUPSIGNIN_POLICY: string;
    REACT_APP_MSAL_TENANT_ID: string;
  }
}
//Get runtime environment variables
fetch(RuntimeConfigPath)
  .then((res) => res.json())
  .then(async (config: ClientConfigurationModel) => {
    (window as any)["runConfig"] = config;
    ai.initialize(
      config.REACT_APP_APPINSIGHTS_INSTR_KEY ??
        defaultClientConfigurationModel.REACT_APP_APPINSIGHTS_INSTR_KEY
    )
      .then(({ appInsights, reactPlugin }) => {
        appInsights.trackTrace({
          message: "Spa-config and App Insights loaded.",
          severityLevel: SeverityLevel.Information,
        });

        clientSideID = config.LAUNCH_DARKLY_CLIENTID;
        renderApp(reactPlugin);
      })
      .catch(() => {
        console.log("There was a problem initializing the application: E5200");
        renderApp();
      });
  })
  .catch(() => {
    console.log("There was a problem initializing the application: E5100");
    renderApp();
  });

async function renderApp(reactPlugin?: ReactPlugin) {
  const msalInstance = new PublicClientApplication(getConfig());
  var LDProvider = await asyncWithLDProvider({
    clientSideID:
      clientSideID ?? defaultClientConfigurationModel.LAUNCH_DARKLY_CLIENTID,
  });

  ReactDOM.render(
    <React.StrictMode>
      <LDProvider>
        <AppInsightsContext.Provider value={reactPlugin!}>
          <MsalProvider instance={msalInstance}>
            <Router>
              <Provider store={store}>
                <App />
              </Provider>
            </Router>
          </MsalProvider>
        </AppInsightsContext.Provider>
      </LDProvider>
    </React.StrictMode>,
    MOUNT_NODE
  );
}
