import React from "react";
import { InfoCircle } from "react-bootstrap-icons";
import { OverlayTrigger, Popover } from "react-bootstrap";
import "./InfoTooltip.css";
import { Placement } from "react-bootstrap/esm/types";

interface Props {
  id: string;
  text: string;
  position: Placement;
}

export function InfoTooltip(props: Props) {
  const popover = (
    <Popover data-testid={`${props.id}-test`} id={props.id}>
      <Popover.Body className="tooltipText preserveWhitespace">
        {props.text}
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <OverlayTrigger
        trigger={["click", "hover", "focus"]}
        placement={props.position}
        overlay={popover}
        delay={{ show: 275, hide: 0 }}
      >
        <div
          data-testid={`${props.id}-trigger`}
          className="d-inline-flex bi-container"
        >
          <InfoCircle />
        </div>
      </OverlayTrigger>
    </>
  );
}
export default InfoTooltip;
