import { Form, Col, Row } from "react-bootstrap";
import FormTextArea from "../FormElements/FormTextArea";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setSubjectiveAndObjectiveFindings } from "../../redux/SubjectiveAndObjectiveFindings/SubjectiveAndObjectiveFindingsSlice";

interface Props {
  showSummaryLabel: boolean
}

SubjectiveAndObjectiveFindings.defaultProps = {
  showSummaryLabel: false
}

function SubjectiveAndObjectiveFindings(props: Props) {
  const dispatch = useAppDispatch();
  const reduxSOF = useAppSelector(
    (state) => state.subjectiveAndObjectiveFindings
  );

  function handleChange(event: any) {
    const { name, value } = event.target;
    dispatch(setSubjectiveAndObjectiveFindings({ ...reduxSOF, [name]: value }));
  }

  const summaryLabel = props.showSummaryLabel
    ? "Note: Summarize and provide specific details and measures of CHANGES since last report and include other findings since the initial assessment"
    : "";

  return (
    <Form.Group id="formSubjectiveAndObjectiveFindings">
      <Row>
        <Col>
          <div className="formSectionHeader">
            <h6>
              Summary of Subjective and Objective Findings (ROM, motor power,
              neurologic, etc.)
            </h6>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormTextArea
            required
            label={summaryLabel}
            testId={"formSummarySubObjFindings"}
            field="summarySubObjFindings"
            className="formTextArea"
            value={reduxSOF.summarySubObjFindings}
            onChange={handleChange}
            rows={4}
            feedbackLabel="Summary of Subjective and Objective Findings"
          />
        </Col>
      </Row>
    </Form.Group>
  );
}

export default SubjectiveAndObjectiveFindings;
