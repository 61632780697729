import axios from "axios";
import { PractitionerProfile } from "../types/PractitionerProfile";
import { handleResponse, handleError } from "./apiUtils";
import { getAccessToken } from "../utils/auth/authApiUtils";
import { getBaseApiUrl } from "./apiHelper";

export async function savePractitionerProfileForm(
  formData: PractitionerProfile
) {
  const baseUrl = getBaseApiUrl();
  const token = await getAccessToken();

  return axios
    .post(
      baseUrl + "v1/securedocui/OrgProfile/SubmitOrganizationProfile",
      formData,
      {
        headers: {
          Accept: "application/json",
          ContentType: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(handleResponse)
    .catch(handleError);
}

export async function updatePractitionerProfile(
  profileData: PractitionerProfile
) {
  const baseUrl = getBaseApiUrl();
  const token = await getAccessToken();

  return axios
    .put(
      baseUrl + "v1/securedocui/OrgProfile/UpdateOrganizationProfile",
      profileData,
      {
        headers: {
          Accept: "application/json",
          ContentType: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(handleResponse)
    .catch(handleError);
}

export async function getPractitionerProfile(orgId: string) {
  const baseUrl = getBaseApiUrl();
  const token = await getAccessToken();

  return axios
    .get(
      baseUrl + "v1/securedocui/OrgProfile/GetOrganizationProfile/" + orgId,
      {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(handleResponse)
    .catch(handleError);
}

export async function getOrganizationProfileList() {
  const baseUrl = getBaseApiUrl();
  const token = await getAccessToken();

  return axios
    .get(baseUrl + "v1/securedocui/OrgProfile/GetOrganizationProfileList", {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}
