import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import { getBaseApiUrl } from "./apiHelper";
import { getAccessToken } from "../utils/auth/authApiUtils";


export async function getAttachmentFile(
  organizationId: string,
  claimNumber: string,
  attachmentId: string
) {
  const baseUrl =
    getBaseApiUrl() +
    `v1/securedocui/attachment/Download?organizationId=` +
    organizationId +
    `&claimNumber=` +
    claimNumber +
    `&attachmentId=` +
    attachmentId;

  const token = await getAccessToken();

    return axios
    .get(baseUrl, {
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}
