import { getAttachmentFile } from "../../api/AttachmentApi";
import { getFormSummary } from "../../api/FormSummaryApi";

export async function DownloadFile(row: any) {

  var result: any;

  if (
    row.formType === "ChiroIntake" ||
    row.formType === "ChiroProgress" ||
    row.formType === "ChiroDischarge"
  ) {
    result = await getFormSummary(
      row.formId,
      row.claimNumber,
      row.organizationId
    );
  } else {
    result = await getAttachmentFile(
      row.organizationId,
      row.claimNumber,
      row.attachmentId
    );
  }

  if (result && result.data && result.status < 300) {
    const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));
    var filename = row.fileName;
    if (filename === null || filename === "") {
      filename = row.formType + ".pdf";
    }

    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", filename); //any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();

    return "success";

  } else {

    return "fail";

  }
}
