import { useState, useEffect } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import FormLabel from "../../components/FormElements/FormLabel";
import FormText from "../../components/FormElements/FormText";
import * as messages from "../../../constants/Messages";
import {
  maxFileSizeBytes,
  allowedFileTypes,
} from "../../../constants/FileUploadConstants";
import { Attachment } from "../../../types/Attachment";
import { transferFile } from "../../../api/TransferFile";
import { InlineSpinner } from "../../components/Spinners/Spinners";
import { setToastMessages } from "../../redux/ToastMessages/ToastMessagesSlice";
import { setPractitionerData } from "../../redux/Practitioner/PractitionerSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import "./TransferFile.css";
import { ToastType } from "../../../types/ToastMessages";

interface Props {}

function TransferFile(props: Props) {
  const history = useHistory();
  //const [sgiPayeeNumber, setSgiPayeeNumber] = useState("");
  const [claimNumber, setClaimNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [selectedFile, setSelectedFile] = useState<File>();

  const [sgiPayeeNumberIsValid, setSgiPayeeNumberIsValid] = useState(true);
  const [claimNumberIsValid, setClaimNumberIsValid] = useState(true);
  const [firstNameIsValid, setFirstNameIsValid] = useState(true);
  const [lastNameIsValid, setLastNameIsValid] = useState(true);
  const [selectedFileIsValid, setSelectedFileIsValid] = useState(true);
  const [invalidFileMessage, setInvalidFileMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useAppDispatch();
  const reduxPractitioner = useAppSelector((state) => state.practitioner);
  const reduxToastMessages = useAppSelector((state) => state.toastMessages);
  const reduxLogin = useAppSelector((state) => state.login);

  useEffect(() => {
    resetForm();
  }, []);

  function resetForm() {
    setClaimNumber("");
    setFirstName("");
    setLastName("");
    setSelectedFile(undefined);
    setSelectedFileIsValid(true);
    setInvalidFileMessage("");

    const formFileElement = document.getElementsByName("formFile");
    if (formFileElement[0] !== undefined) {
      (formFileElement[0] as HTMLInputElement).value = "";
    }
    setFocusToFirstElement();
  }

  async function handleOnSubmit(event: any) {
    event.preventDefault();
    setIsSubmitting(true);
    const elementList = document.querySelectorAll("input");

    elementList.forEach((element) => {
      switch (element.name) {
        case "claimNumber":
          setClaimNumberIsValid(element.checkValidity());
          break;
        case "firstName":
          setFirstNameIsValid(element.checkValidity());
          break;
        case "lastName":
          setLastNameIsValid(element.checkValidity());
          break;
        case "sgiPayeeNumber":
          setSgiPayeeNumberIsValid(element.checkValidity());
          break;
      }
    });

    if (!validateSelectedFile()) {
      setSelectedFileIsValid(false);
      setIsSubmitting(false);
      return;
    }

    if (!claimNumberIsValid) {
      document.getElementById("formClaimNumber");
    }

    if (
      !claimNumberIsValid ||
      !firstNameIsValid ||
      !lastNameIsValid ||
      !selectedFileIsValid ||
      !sgiPayeeNumberIsValid
    ) {
      setFocusToFirstInvalidElement();
      setIsSubmitting(false);
      return;
    }

    let formData = new FormData();
    formData.append("file", selectedFile!, selectedFile!.name);

    let attachment: Attachment = {
      claimNumber: claimNumber,
      fileName: selectedFile!.name,
      claimantFirstName: firstName,
      claimantLastName: lastName,
      contentType: "multipart/form-data",
      sgiPayeeNumber: reduxPractitioner.sgiPayeeNumber,
      formId: "5F5C5A46-215E-4AA9-B704-3C332C705221",
      details: "testing",
      userId: reduxLogin.userId,
      organizationId: reduxLogin.organizationId,
    };

    if (selectedFile !== undefined) {
      await upload(attachment, formData);
    }
    setIsSubmitting(false);
  }

  function validateSelectedFile() {
    if (selectedFile === null || selectedFile === undefined) {
      setInvalidFileMessage(messages.NO_FILE_SELECTED);
      return false;
    }

    var fileExtension = selectedFile?.name.split(".").pop() ?? "";
    fileExtension =
      fileExtension.length > 0 ? "." + fileExtension : fileExtension;
    if (!allowedFileTypes.includes(fileExtension)) {
      setInvalidFileMessage(messages.INVALID_FILE_TYPE);
      return false;
    }

    const fileSize = selectedFile?.size ?? 0;
    if (fileSize > maxFileSizeBytes) {
      setInvalidFileMessage(messages.MAX_FILE_SIZE_MB);
      return false;
    }

    setInvalidFileMessage("");
    return true;
  }
  function setFocusToFirstInvalidElement() {
    let errorElements = document.querySelectorAll(
      ".form-control:invalid, .form-check-input:invalid"
    );
    if (errorElements !== undefined && errorElements.length > 0) {
      (errorElements[0] as HTMLInputElement).focus();
    }
  }

  function setFocusToFirstElement() {
    let formInputElements = document.querySelector(".form-control");
    if (formInputElements !== undefined) {
      (formInputElements as HTMLInputElement).focus();
    }
  }

  async function upload(attachment: Attachment, file: FormData) {
    await transferFile(attachment, file).then((apiResponse) => {
      if (apiResponse?.status >= 200 && apiResponse?.status < 300) {
        dispatch(
          setToastMessages({
            toastMessages: [
              ...reduxToastMessages.toastMessages,
              {
                toastType: ToastType.Info,
                message: messages.UPLOAD_SUCCESS,
                title: "File Transfer",
              },
            ],
          })
        );

        resetForm();
      } else {
        dispatch(
          setToastMessages({
            toastMessages: [
              ...reduxToastMessages.toastMessages,
              {
                toastType: ToastType.Error,
                message: messages.UPLOAD_FAILED,
                title: "File Transfer",
              },
            ],
          })
        );
      }
    });
  }

  function handleFileSelection(event: any) {
    setInvalidFileMessage("");
    setSelectedFile(event.target.files[0]);
    if (event.target.files[0] === null || event.target.files[0] === undefined) {
      setSelectedFileIsValid(false);
    } else {
      setSelectedFileIsValid(true);
    }
  }

  function handleChange(event: any) {
    const { name, value } = event.target;

    const form = event.currentTarget;
    const isValid: boolean = form.checkValidity();

    switch (name) {
      case "claimNumber":
        setClaimNumber(value);
        setClaimNumberIsValid(isValid);
        break;
      case "firstName":
        setFirstName(value);
        setFirstNameIsValid(isValid);
        break;
      case "lastName":
        setLastName(value);
        setLastNameIsValid(isValid);
        break;
      case "sgiPayeeNumber":
        dispatch(setPractitionerData({ ...reduxPractitioner, [name]: value }));
        setSgiPayeeNumberIsValid(isValid);
    }
  }

  return (
    <Container className="transferFileContainer">
      <Row>
        <div className="formHeader">
          <h3>Transfer File</h3>
        </div>
      </Row>
      <Row className="mb-2">
        <Alert key="transferFileInfo" variant="info">
          The uploaded file will be associated with the identified claim,
          <i> eg: return to work plan</i>.
        </Alert>
      </Row>
      <Form noValidate onSubmit={handleOnSubmit}>
        <Form.Group id="formTransferFileParameters">
          <Row>
            <Col xs="12" sm="12" lg="4">
              <FormText
                required
                label="SGI Payee Number"
                testId="formSgiPayeeNumber"
                field="sgiPayeeNumber"
                value={reduxPractitioner.sgiPayeeNumber}
                onChange={handleChange}
                pattern="^(?=.*[1-9])[0-9]{1,9}$"
                isValid={sgiPayeeNumberIsValid}
                invalidMessage={
                  reduxPractitioner.sgiPayeeNumber.length < 1 ||
                  reduxPractitioner.sgiPayeeNumber.length > 9
                    ? messages.MUST_BE_BETWEEN_1_TO_9_CHARACTERS
                    : messages.NUMBERS_ONLY_NOTZERO
                }
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs="12" sm="12" lg="4">
              <FormText
                type="text"
                testId="formClaimNumber"
                field="claimNumber"
                label="Claim Number"
                value={claimNumber}
                onChange={handleChange}
                required
                pattern="^(?=.*[1-9])[0-9]{1,9}$"
                isValid={claimNumberIsValid}
                invalidMessage={
                  claimNumber.length < 1 || claimNumber.length > 9
                    ? messages.MUST_BE_BETWEEN_1_TO_9_CHARACTERS
                    : messages.NUMBERS_ONLY_NOTZERO
                }
              />
            </Col>
            <Col xs="12" sm="12" lg="4">
              <FormText
                required
                label="Customer First Name"
                testId={"formCustomerFirstName"}
                field="firstName"
                pattern="^[a-zA-z \-']*$"
                isValid={firstNameIsValid}
                invalidMessage={messages.NAME_CHARACTERS_ONLY}
                value={firstName}
                onChange={handleChange}
              />
            </Col>
            <Col xs="12" sm="12" lg="4">
              <FormText
                required
                label="Customer Last Name"
                testId={"formCustomerLastName"}
                field="lastName"
                pattern="^[a-zA-z \-']*$"
                isValid={lastNameIsValid}
                invalidMessage={messages.NAME_CHARACTERS_ONLY}
                value={lastName}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </Form.Group>
        <Row>
          <Col>
            <Form.Group id="formFile" className="mb-3">
              <FormLabel
                testId="formFileLabel"
                text="Drag and drop a file into the box below or click 'Choose File' to select from your computer"
              />
              <Form.Control
                type="file"
                data-testid="formFileSelection"
                name="formFile"
                accept={allowedFileTypes.toString()}
                size="lg"
                onChange={handleFileSelection}
                isInvalid={!selectedFileIsValid}
              />
              <Form.Text id="fileHelpBlock" muted>
                <i>
                  <small>
                    * Executable files such as X-ray, MRI or other diagnostic
                    imaging programs cannot be uploaded.
                  </small>
                </i>
              </Form.Text>
              <Form.Control.Feedback
                type="invalid"
                data-testid="formFileSelectionFeedback"
              >
                {invalidFileMessage}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group id="formEntrySubmitButton">
              <Button
                id="formEntrySubmitButton"
                data-testid="formEntrySubmitButton"
                variant="primary"
                disabled={isSubmitting}
                type="submit"
              >
                {isSubmitting && (
                  <InlineSpinner
                    for="button"
                    id="uploadSpinner"
                    data-testid="uploadSpinner"
                  />
                )}
                {!isSubmitting && "Upload"}
              </Button>
              <Button
                id="formCancelButton"
                data-testid="formCancelButton"
                variant="outline-secondary"
                onClick={() => history.push("/")}
                style={{ marginLeft: "0.5rem" }}
              >
                Cancel
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

export default TransferFile;
