export function sortByDate(key: any, sortOrder: string) {
  return function (a: any, b: any) {
    var fieldA = new Date(a[key]);
    var fieldB = new Date(b[key]);
    var viewIOSString =
      fieldA.toISOString().localeCompare(fieldB.toISOString()) *
      (sortOrder === "asc" ? 1 : -1);
    return fieldA || fieldB ? (!fieldA ? -1 : !fieldB ? 1 : viewIOSString) : 0;
  };
}

export function sortByStringThenDate(key: any, sortOrder: string) {
  return function (a: any, b: any) {
    var fieldA: string = (a[key] = a[key] ? a[key] : " ");
    var fieldB: string = (b[key] = b[key] ? b[key] : " ");
    var itemOrder: string[] = [];

    var dateA = new Date(a["submittedDate"]).toISOString();
    var dateB = new Date(b["submittedDate"]).toISOString();
    var sortByString =
      itemOrder.length === 0
        ? fieldB.localeCompare(fieldA)
        : itemOrder.indexOf(fieldA) - itemOrder.indexOf(fieldB);

    return (
      sortByString * (sortOrder === "asc" ? 1 : -1) ||
      dateB.localeCompare(dateA)
    );
  };
}

export function sortBy(key: any, sortOrder: string) {
  return function (a: any, b: any) {
    a[key] = a[key] ? a[key] : " ";
    b[key] = b[key] ? b[key] : " ";
    return a[key] || b[key]
      ? !a[key]
        ? -1
        : !b[key]
        ? 1
        : a[key].toString().localeCompare(b[key].toString()) *
          (sortOrder === "asc" ? 1 : -1)
      : 0;
  };
}
