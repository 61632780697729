import { useEffect, useState } from "react";

export enum ScreenSize {
  xs = 0,
  sm = 1,
  md = 2,
  lg = 3,
  xl = 4,
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  var screenSize = ScreenSize.xl;
  if (width < 1200) screenSize = ScreenSize.lg;
  if (width < 992) screenSize = ScreenSize.md;
  if (width < 768) screenSize = ScreenSize.sm;
  if (width < 576) screenSize = ScreenSize.xs;

  return {
    width,
    height,
    screenSize,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
}
