export interface OutcomeCode {
    code: string,
    outcomeDescription?: string
    reasonNotCompleted:string
}

export const defaultOutcomeCode:OutcomeCode = {
    code : "",
    outcomeDescription: "",
    reasonNotCompleted: ""
}