import { IFeedback } from "../../types/Feedback";

export function feedbackListTransformer(data: any): IFeedback[] {
  var returnFeedbackData: IFeedback[] = [];

  data.forEach((item: any) => {
    var feedbackObject: IFeedback = {
      feedbackId: item.feedbackId,
      content: item.content,
      email: item.email,
      feedbackType: item.feedbackType,
      name: item.name,
      submittedDate: item.submittedDate,
      accountType: item.accountType,
    };
    returnFeedbackData.push(feedbackObject);
  });

  return returnFeedbackData;
}
