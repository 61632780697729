import { IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function TableButton(props: {
  id: string;
  tooltipText: string;
  onClick: any;
  icon: IconDefinition;
  datatestid: string;
}) {
  return (
    <div
      className="buttonWrapper"
      id={`${props.id}Wrapper`}
      data-testid={`${props.datatestid}Wrapper`}
    >
      <OverlayTrigger
        overlay={<Tooltip id="listButton">{props.tooltipText}</Tooltip>}
      >
        <div>
          <FontAwesomeIcon
            id={props.id}
            icon={props.icon}
            onClick={props.onClick}
            data-testid={props.datatestid}
          />
        </div>
      </OverlayTrigger>
    </div>
  );
}
