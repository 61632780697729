export interface OutcomeMeasureUsed {
  rolandMorris: string;
  quickDashWorkModule: string;
  neckDisabilityIndex: string;
  lowerExtremityFunctionalScale: string;
  visualAnalogueScale: string;
  oswestry: string;
  clinicalOutcomeVariablesScale: string;
  timedUpAndGoTest: string;
  other: string;
}

export const defaultOutcomeMeasureUsed: OutcomeMeasureUsed = {
  rolandMorris: "",
  quickDashWorkModule: "",
  neckDisabilityIndex: "",
  lowerExtremityFunctionalScale: "",
  visualAnalogueScale: "",
  oswestry: "",
  clinicalOutcomeVariablesScale: "",
  timedUpAndGoTest: "",
  other: "",
};
