import { createSlice } from '@reduxjs/toolkit';
import { defaultFormFooter } from '../../../types/FormFooter';

export const FormFooterSlice = createSlice({
    name: "FormFooter",
    initialState: defaultFormFooter,
    reducers: {
        setFormFooter: (state, action) => {
            return { ... state, ...action.payload};
        },
        clearFormFooter: state => {
            return { ...defaultFormFooter };
        }
    }
});

export const {setFormFooter, clearFormFooter} = FormFooterSlice.actions;
export default FormFooterSlice.reducer;