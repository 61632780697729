import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import { getBaseApiUrl } from "./apiHelper";
import { getAccessToken } from "../utils/auth/authApiUtils";
import { Attachment } from "../types/Attachment";

export async function transferFile(attachment: Attachment, file: FormData) {
  var baseUrl = getBaseApiUrl();
  const token = await getAccessToken();

  return await axios
    .post(
      baseUrl +
        "v1/securedocui/Attachment?attachmentParms=" +
        encodeURIComponent(JSON.stringify(attachment)),
      file,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(handleResponse)
    .catch(handleError);
}


