import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import PageSpinner from "../../../components/PageSpinner/PageSpinner";
import PractitionerProfileDetails from "../../../components/PractionerProfile/PractitionerProfileDetails";
import { InlineSpinner } from "../../../components/Spinners/Spinners";
import { ToastType } from "../../../../types/ToastMessages";
import { setToastMessages } from "../../../redux/ToastMessages/ToastMessagesSlice";
import { clearPractitionerProfileData } from "../../../redux/PractitionerProfile/PractitionerProfileSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { PractitionerProfile } from "../../../../types/PractitionerProfile";
import { appInsights } from "../../../../AppInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { savePractitionerProfileForm } from "../../../../api/PractitionerProfileApi";
import { getOrganizationList } from "../../../../api/OrganizationApi";
import "./PractitionerProfileForm.css";
import { Organization } from "../../../../types/Organization";

interface Props {}

function NewPractitionerProfileForm(props: Props) {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validated, setValidated] = useState(false);
  const [organizationList, setOrganizationList] = useState<Organization[]>([]);

  const history = useHistory();
  const dispatch = useAppDispatch();
  const reduxToastMessages = useAppSelector((state) => state.toastMessages);
  const profileData: PractitionerProfile = useAppSelector(
    (state) => state.practitionerProfile
  );

  useEffect(() => {
    resetFormComponentState();
    loadOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadOrganizations() {
    await getOrganizationList()
      .then((response) => {
        if (response?.data) {
          setOrganizationList(response.data);
        } else {
          setOrganizationList([]);
        }
      })
      .finally(() => {
        setIsPageLoading(false);
      });
  }

  async function handleOnSubmit(event: any) {
    
    event.preventDefault();
    setIsSubmitting(true);
    const form = event.currentTarget;
    const isValid: boolean = form.checkValidity();

    setValidated(true);

    if (isValid) {
      let info: any[] = [];
      let errors: any[] = [];

      await savePractitionerProfileForm(profileData).then((apiResponse) => {
        if (apiResponse?.data) {
          if (typeof apiResponse.data === "string") {
            errors.push(apiResponse.data);
          } else {
            info.push("Practitioner profile saved successfully");

            if (appInsights !== undefined) {
              appInsights.trackEvent({
                name: "New Practitioner Profile Saved",
              });
            }
          }
        } else {
          errors.push(
            "An error occurred saving the practitioner profile. Please try again."
          );

          if (appInsights !== undefined) {
            appInsights.trackTrace({
              message: "Failed to save practitioner profile",
              severityLevel: SeverityLevel.Error,
            });
          }
        }
      });

      if (errors.length === 0) {
        resetFormComponentState();
        history.push("/Employee/Practitioners/ProfileList");
      }

      if (info.length > 0) {
        dispatch(
          setToastMessages({
            toastMessages: [
              ...reduxToastMessages.toastMessages,
              {
                toastType: ToastType.Info,
                message: info.toString(),
                title: "New Practitioner Profile Form",
              },
            ],
          })
        );
      }

      if (errors.length > 0) {
        dispatch(
          setToastMessages({
            toastMessages: [
              ...reduxToastMessages.toastMessages,
              {
                toastType: ToastType.Error,
                message: errors.toString(),
                title: "New Practitioner Profile Form",
              },
            ],
          })
        );
      }

      setValidated(false);
    } else {
      setFocusToFirstInvalidElement();
    }
    setIsSubmitting(false);
  }

  function resetFormComponentState() {
    dispatch(clearPractitionerProfileData());
  }

  function setFocusToFirstInvalidElement() {
    let errorElements = document.querySelectorAll(
      ".form-control:invalid, .form-check-input:invalid"
    );
    if (errorElements !== undefined && errorElements.length > 0) {
      (errorElements[0] as HTMLInputElement).focus();
    }
  }

  return isPageLoading ? (
    <PageSpinner />
  ) : (
    <Container className="practitionerProfileContainer">
      <Form noValidate validated={validated} onSubmit={handleOnSubmit}>
        <PractitionerProfileDetails organizationList={organizationList} validated={validated} />
        <Row>
          <Form.Group id="formEntrySubmit">
            <Col className="flexRight">
              <Button
                id="formCancelButton"
                data-testid="formCancelButton"
                variant="outline-secondary"
                onClick={() =>
                  history.push("/Employee/Practitioners/ProfileList")
                }
                style={{ marginLeft: "0.5rem" }}
              >
                Cancel
              </Button>
              <Button
                id="formEntrySubmitButton"
                data-testid="formEntrySubmitButton"
                disabled={isSubmitting}
                variant="primary"
                type="submit"
                className=" align-items-center"
              >
                {isSubmitting && (
                  <InlineSpinner
                    for="button"
                    id="submitSpinner"
                    data-testid="submitSpinner"
                  />
                )}
                {!isSubmitting && "Save Profile"}
              </Button>
            </Col>
          </Form.Group>
        </Row>
      </Form>
    </Container>
  );
}

export default NewPractitionerProfileForm;
