export interface UserPermissions {
  canViewForm: boolean;
  canSubmitForm: boolean;
  canViewAttachment: boolean;
  canSubmitAttachment: boolean;
  canViewFeedback: boolean;
  canSubmitFeedback: boolean;
  canViewTransferFileSummary: boolean;
  canMarkFileAsProcessed: boolean;
  canSubmitOrgProfiles: boolean;
  canViewOrgProfiles: boolean;
  canViewAudit: boolean;
}

export const defaultUserPermissions: UserPermissions = {
  canViewForm: false,
  canSubmitForm: false,
  canViewAttachment: false,
  canSubmitAttachment: false,
  canViewFeedback: false,
  canSubmitFeedback: false,
  canViewTransferFileSummary: false,
  canMarkFileAsProcessed: false,
  canSubmitOrgProfiles: false,
  canViewOrgProfiles: false,
  canViewAudit: false
};
