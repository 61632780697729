import { Audit } from "../../types/Audit";

const auditListTransformer = (data: any):Audit[] =>  {
    var returnData: Audit[] = [];

    data.forEach((item: any) => {
        var fileSummaryObject: Audit = {
            id: item.id,
            userId: item.userId,
            userEmail: item.userEmail,
            ipAddress: item.ipAddress,
            requestBody: item.requestBody,
            auditTimestamp: new Date(item.auditTimestamp),
            };
        returnData.push(fileSummaryObject);
    });
    
    return returnData;
}

export { auditListTransformer};