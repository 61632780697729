export interface Audit {
    id:string,
    userId: string,
    userEmail: string,
    ipAddress: string,
    requestBody: string,
    auditTimestamp: Date
}

export const defaultAudit: Audit = {
    auditTimestamp: new Date(),
    id: '',
    ipAddress: '',
    requestBody: '',
    userEmail: '',
    userId: ''
}

export const defaultAuditList: Audit[] = [];