import { Form, Col, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import FormTextArea from "../FormElements/FormTextArea";
import { setIssuesReturnToNormalActivity } from "../../redux/IssuesReturnToNormalActivity/IssuesReturnToNormalActivitySlice";
import {
  ListType,
  ReturnToNormalActivityIssues,
} from "../../../types/ListTypes";
import FormSelect from "../FormElements/FormSelect";

interface Props {}

function IssuesReturnToNormalActivity(props: Props) {
  const dispatch = useAppDispatch();
  const reduxIssuesReturnToNormalActivity = useAppSelector(
    (state) => state.issuesReturnToNormalActivity
  );

  function handleChange(event: any) {
    const { name, value } = event.target;

    let fieldVal = null;
    if (name === "hasIssuesPreventingReturn") {
      fieldVal = value === "true" ? true : false;
    } else {
      fieldVal = value;
    }

    dispatch(
      setIssuesReturnToNormalActivity({
        ...reduxIssuesReturnToNormalActivity,
        [name]: fieldVal,
      })
    );
  }

  return (
    <Form.Group id="formIssuesReturnToNormalActivity">
      <Row>
        <Col>
          <div className="formSectionHeader">
            <h6>Issues that may affect full Return to Normal Activity</h6>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={3} sm={3} md={2}>
          <FormSelect
            testId="formIssuesReturnToNormalActivityHasIssues"
            field="hasIssuesPreventingReturn"
            // feedbackLabel=""
            label="Issues"
            value={reduxIssuesReturnToNormalActivity.hasIssuesPreventingReturn.toString()}
            onChange={handleChange}
            data={ReturnToNormalActivityIssues}
            mapping={(g: ListType) => {
              return { value: g.value, text: g.description };
            }}
          />
        </Col>
      </Row>
      <div>&nbsp;</div>
      <Row>
        <Col>
          <FormTextArea
            label=""
            testId="formIssuesReturnToNormalActivityDetails"
            field="issuesDetails"
            className="formTextArea"
            value={reduxIssuesReturnToNormalActivity.issuesDetails}
            onChange={handleChange}
            rows={4}
            feedbackLabel="Issues that may affect Return to Normal Activity"
            required={
              reduxIssuesReturnToNormalActivity.hasIssuesPreventingReturn ===
              true
            }
          />
        </Col>
      </Row>
    </Form.Group>
  );
}

export default IssuesReturnToNormalActivity;
