import {createSlice} from '@reduxjs/toolkit'

import {defaultComplianceAndAttendance} from "../../../types/ComplianceAndAttendance"

export const ComplianceAndAttendanceSlice = createSlice({
    name:"ComplianceAndAttendance",
    initialState:defaultComplianceAndAttendance,
    reducers:{
        setComplianceAndAttendance: (state,action) => {
            return{...state,...action.payload};            
        },
        clearComplianceAndAttendance: state => {
            return {...defaultComplianceAndAttendance}
        }
    }
})

export const {setComplianceAndAttendance,clearComplianceAndAttendance} = ComplianceAndAttendanceSlice.actions;
export default ComplianceAndAttendanceSlice.reducer;