import axios from 'axios';
import { getBaseApiUrl } from "./apiHelper";
import { getAccessToken } from "../utils/auth/authApiUtils";
import { handleResponse, handleError } from "./apiUtils";

const getFormDownloads = async (startDate: string | undefined, endDate: string | undefined) => {

    const baseUrl = getBaseApiUrl();
    const token = await getAccessToken();
  
    const start = startDate === undefined || startDate === null ? "" : startDate;
    const end = endDate === undefined || endDate === null ? "" : endDate;

    return axios
      .get(
        baseUrl + `v1/securedocui/audit/GetFormDownloadAudit?startDate=${start}&endDate=${end}`,
        {
          headers: {
            Accept: "application/json",
            ContentType: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(handleResponse)
      .catch(handleError);
}

export { getFormDownloads }