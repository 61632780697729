import {
  faDownload,
  faPen,
  faExclamationTriangle,
} from "@fortawesome/pro-light-svg-icons";
import { useState } from "react";
import {
  Button,
  Container,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { SortOrder } from "react-bootstrap-table-next";
import {
  getTransferSummary,
  updateTransferSummaryStatus,
} from "../../../../api/TransferStatusApi";
import * as messages from "../../../../constants/Messages";
import { ToastType } from "../../../../types/ToastMessages";
import { defaultTransferFileSummary } from "../../../../types/TransferFileTypes";
import { DownloadFile } from "../../../../utils/Download/DownloadFile";
import { convertDateToDisplayFormatWithTime } from "../../../../utils/Formaters/DisplayFormatter";
import {
  ScreenSize,
  useWindowDimensions,
} from "../../../../utils/Formaters/ScreenFormatter";
import { transferFileSummaryTransformer } from "../../../../utils/Transformers/TransferFileSummaryTransformer";
import CustomTable from "../../../components/CustomTable/CustomTable";
import TableButton from "../../../components/CustomTable/TableButton";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setToastMessages } from "../../../redux/ToastMessages/ToastMessagesSlice";
import "./TransferFileSummary.css";

const statusInvalid = "Invalid";
const statusManuallyProcessed = "ManuallyProcessed";

export default function TransferFileSummary() {
  const [transferFileSummary, setTransferFileSummary] = useState(
    defaultTransferFileSummary
  );

  const [selectedRow, setSelectedRow] = useState(Object);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [statusToSet, setStatusToSet] = useState("");

  const { screenSize } = useWindowDimensions();
  const dispatch = useAppDispatch();
  const reduxToastMessages = useAppSelector((state) => state.toastMessages);

  function buttonsFormatter(cell: string, row: any, rowIndex: any) {

    return (
      <div className="buttonCellWrapper">
        {!row?.errorDetails?.includes("virus") && (
          <TableButton
            icon={faDownload}
            onClick={() => downloadFile(row)}
            tooltipText="Download File"
            id={`downloadIcon${rowIndex}`}
            datatestid={`downloadIcon${rowIndex}`}
          />
        )}
        {(row.status !== "Invalid" &&
          row.status !== "ManuallyProcessed" &&
          row.status !== "Success") && (
          <TableButton
            icon={faExclamationTriangle}
            tooltipText="Invalidate record"
            id={`setInvalidIcon${rowIndex}`}
            datatestid={`setInvalidIcon${rowIndex}`}
            onClick={() => setFileToInvalid(row)}
          />
        )}
        {(row.status === "Failed" ||
          row.status === "Pending" ||
          row.status === "PendingRetry" || 
          row.status === "Invalid") && (
          <TableButton
            icon={faPen}
            onClick={() => setFileToManuallyComplete(row)}
            tooltipText="Mark Completed"
            id={`manuallyProcessedIcon${rowIndex}`}
            datatestid={`manuallyProcessedIcon${rowIndex}`}
          />
        )}
      </div>
    );
  }

  function errorFormatter(cell: string, row: any, rowIndex: any) {
    if (cell?.length > 25) {
      return (
        <OverlayTrigger
          overlay={
            <Tooltip id="errorDetailsTooltip">Click for details</Tooltip>
          }
        >
          <div style={{ color: "dimgray" }}>{cell.slice(0, 25)}...</div>
        </OverlayTrigger>
      );
    }
    return <span>{cell}</span>;
  }

  function getNonExpandableRows() {
    return transferFileSummary.map((file) => {
      if (file.errorDetails === "") {
        return file.submittedDate;
      }
    });
  }

  const setFileToManuallyComplete = async (row: any) => {
    setStatusToSet(statusManuallyProcessed);
    setSelectedRow(row);
    setShowConfirmationModal(true);
  };

  const setFileToInvalid = async (row: any) => {
    setStatusToSet(statusInvalid);
    setSelectedRow(row);
    setShowConfirmationModal(true);
  };

  const downloadFile = async (row: any) => {
    var result = await DownloadFile(row);

    if (result === "success") {
      dispatch(
        setToastMessages({
          toastMessages: [
            ...reduxToastMessages.toastMessages,
            {
              toastType: ToastType.Info,
              message: messages.DOWNLOAD_SUCCESS,
              title: "File Download",
            },
          ],
        })
      );
    } else {
      dispatch(
        setToastMessages({
          toastMessages: [
            ...reduxToastMessages.toastMessages,
            {
              toastType: ToastType.Error,
              message: messages.DOWNLOAD_FAILED,
              title: "File Download",
            },
          ],
        })
      );
    }
  };

  function noDataIndication() {
    return "There are no entries to display.";
  }

  const updateStatus = async (row: any, status: string) => {
    const payload = {
      ...selectedRow,
      Status: status,
    };

    const response = await updateTransferSummaryStatus(payload);

    let toastMessage = {
      toastType: ToastType.Error,
      message: messages.UPDATE_FILE_TRANSFER_STATUS_FAILED,
      title: "Status Update",
    };

    if (response !== undefined && response.status === 200) {
      toastMessage.toastType = ToastType.Info;
      toastMessage.message = `${messages.UPDATE_FILE_TRANSFER_STATUS_SUCCEDED} ${status}`

      setTransferFileSummary(
        transferFileSummary.map((x) => {
          if (x.formType === "Attachment" && x.attachmentId == row.attachmentId 
            || x.formType !== "Attachment" && x.formId == row.formId) {
            return { ...x, status: status };
          }

          return x;
        })
      );
    }

    dispatch(
      setToastMessages({
        toastMessages: [...reduxToastMessages.toastMessages, toastMessage],
      })
    );
  };

  const defaultSorted: [{ dataField: any; order: SortOrder }] = [
    {
      dataField: "submittedDate",
      order: "desc",
    },
  ];

  const columns = [
    {
      dataField: "organizationName",
      text: "Organization",
      sort: true,
      headerClasses: screenSize < ScreenSize.md ? "width30" : "",
    },
    {
      dataField: "sgiPayeeNumber",
      text: "Payee Number",
      sort: true,
      headerStyle: () => {
        return { width: "120px" };
      },
    },
    {
      dataField: "claimNumber",
      text: "Claim Number",
      sort: true,
      headerStyle: () => {
        return { width: "120px" };
      },
    },
    {
      dataField: "formType",
      text: "Type",
      sort: true,
    },
    {
      dataField: "fileName",
      text: "File Name",
      sort: true,
      headerStyle: () => {
        return { width: "140px" };
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerStyle: () => {
        return { width: "120px" };
      },
    },
    {
      dataField: "errorHttpCode",
      text: "Http Code",
      sort: true,
      hidden: true,
    },
    {
      dataField: "errorDetails",
      text: "Error",
      sort: true,
      formatter: errorFormatter,
      searchable: true,
    },
    {
      dataField: "submittedDate",
      text: "Date Submitted",
      sort: true,
      formatter: (cellContent: any) => {
        return convertDateToDisplayFormatWithTime(cellContent);
      },
      hidden: screenSize < ScreenSize.md,
      headerStyle: () => {
        return { width: "140px" };
      },
    },
    {
      dataField: "",
      text: "",
      isDummyField: true,
      formatter: buttonsFormatter,
      hidden: screenSize < ScreenSize.md,
      headerStyle: () => {
        return { width: "120px" };
      },
    },
  ];

  const expandRow = {
    renderer: (row: { errorDetails: any }) => (
      <div>
        {row.errorDetails !== "" && (
          <>
            <p data-testid="errorDetails">
              <strong>Error Details</strong>
            </p>
            <p>{row.errorDetails}</p>
          </>
        )}
      </div>
    ),
    showExpandColumn: true,
    expandByColumnOnly: false,
    nonExpandable: getNonExpandableRows(),
  };

  return (
    <>
      <Container className="CustomTableContainer">
        <div className="tableHeader">File Transfer Summary</div>
        <CustomTable
          noDataIndication={noDataIndication}
          defaultSorted={defaultSorted}
          columns={columns}
          data={transferFileSummary}
          setData={setTransferFileSummary}
          defaultData={defaultTransferFileSummary}
          dataGetter={getTransferSummary}
          dataMapper={transferFileSummaryTransformer}
          expandRow={expandRow}
          keyField="submittedDate"
        />
      </Container>
      <Modal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        backdrop="static"
        keyboard={true}
        centered
        dialogClassName="confirmationModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Confirmation</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please confirm that you want to mark this file as {statusToSet}.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowConfirmationModal(false)}
          >
            Close
          </Button>
          <Button
            variant="primary"
            data-testid="confirmationModalConfirmButton"
            onClick={() => {
              setShowConfirmationModal(false);
              updateStatus(selectedRow, statusToSet);
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
