import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export default function NotAuthorized() {
  const [seconds, setSeconds] = useState(5);
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => setSeconds(seconds - 1), 1000);

    if (seconds <= 0) {
      history.push("/");
    }
  }, [history, seconds]);

  return (
    <Col md={{ span: 8, offset: 2 }}>
      <Row>
        <Col>
          <br />
          <h3>Unauthorized</h3>
        </Col>
      </Row>

      <Row>
        <Col>You are not currently authorized to view this page.</Col>
      </Row>
      <Row>
        <Col>Redirecting to main page in {seconds}...</Col>
      </Row>
    </Col>
  );
}
