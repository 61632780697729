import axios from "axios";
import { ChiroDischarge } from "../types/Forms";
import { handleResponse, handleError } from "./apiUtils";
import { getAccessToken } from "../utils/auth/authApiUtils";
import { getBaseApiUrl } from "./apiHelper";
import API_CONSTANTS from "./apiConstants"

export async function submitChiroDischargeForm(formData: ChiroDischarge) {
  const baseUrl = getBaseApiUrl();
  const token = await getAccessToken();

  return axios
    .post(
      baseUrl + "v1/securedocui/chirodischargeform/SubmitDischargeForm",
      formData,
      {
        headers: {
          Accept: "application/json",
          ContentType: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(handleResponse)
    .catch(handleError);
}

export const saveChiroDischargeForm = async (formData: ChiroDischarge) => {
  const baseUrl = getBaseApiUrl();
  const token = await getAccessToken();

  return axios
    .post(
      baseUrl + "v1/securedocui/chirodischargeform/SaveDischargeForm",
      formData,
      {
        headers: {
          Accept: "application/json",
          ContentType: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(handleResponse)
    .catch(handleError);
}

export const getDischargeForm = async (
  formId: string,
  organizationId: string,
  claimNumber: string) => {
    
  const url = `${getBaseApiUrl()}${API_CONSTANTS.DISCHARGE_FORM.GET}?FormId=${formId}&OrganizationId=${organizationId}&ClaimNumber=${claimNumber}`;  
  const token = await getAccessToken();

  return axios.get(url, {
      responseType: "json",
        headers: {
          Accept: "application/json",
          ContentType: "application/json",
          Authorization: "Bearer " + token,
        }
    })
    .then(handleResponse)
    .catch(handleError);
}