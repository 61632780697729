export interface TreatmentGoals {
  painReduction: boolean;
  increaseInRom: boolean;
  increaseInStrength: boolean;
  biomechanicalRestoration: boolean;
  other: boolean;
  otherDescription: string;
  returnToNormalLiving: boolean;
  returnToPreaccidentActivities: boolean;
  returnToModifiedActivities: boolean;
  expectedReturnToNormalLiving: boolean;
  expectedReturnToNormalLivingDate: string;
  expectedReturnToHomemaking: boolean;
  expectedReturnToHomemakingDate: string;
  expectedReturnToPreaccidentActivities: boolean;
  expectedReturnToPreaccidentActivitiesDate: string;
  expectedReturnToModifiedActivities: boolean;
  expectedReturnToModifiedActivitiesDate: string;
  otherFunctional: boolean;
  otherFunctionalDescription: string;
}

export const defaultTreatmentGoals: TreatmentGoals = {
  painReduction: false,
  increaseInRom: false,
  increaseInStrength: false,
  biomechanicalRestoration: false,
  other: false,
  otherDescription: "",
  returnToNormalLiving: false,
  returnToPreaccidentActivities: false,
  returnToModifiedActivities: false,
  expectedReturnToNormalLiving: false,
  expectedReturnToNormalLivingDate: "",
  expectedReturnToHomemaking: false,
  expectedReturnToHomemakingDate: "",
  expectedReturnToPreaccidentActivities: false,
  expectedReturnToPreaccidentActivitiesDate: "",
  expectedReturnToModifiedActivities: false,
  expectedReturnToModifiedActivitiesDate: "",
  otherFunctional: false,
  otherFunctionalDescription: "",
};
